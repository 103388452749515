// @ts-nocheck
import React, { useEffect, useState } from "react";
import CommandCenterService from "../../../service/CommandCenterService";
import { navigateTo } from "../../CommonComponents/CommonFunctions";
import { Dropdown } from "react-bootstrap";
import Dialog from "../../CommonComponents/Dialog";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const TrueTwins = ({
  sat_Id,
  mission_name,
  mission_id,
  sat_name,
  openCreateTrueTwin,
  setLoading,
  get_satellites,
  showBtnGroup,
  setShowBtnGroup
}) => {
  const navigate = useNavigate();
  const [trueTwins, setTrueTwins] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [trueTwinData, setTrueTwinData] = useState({});

  const filter_truetwins = (all_tt) => {
    let filtered_tt = all_tt?.filter((tt) => tt.Category !== "RealSat");
    return filtered_tt;
  };

  const get_truetwins_by_satId = (sat_Id) => {
    CommandCenterService.get_TrueTwins_by_satId(sat_Id)
      ?.then((tt) => {
        if (tt?.data) {
          const filtered_tt = filter_truetwins(tt?.data);
          setTrueTwins(filtered_tt);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!openCreateTrueTwin?.["isCreateTrueTwin"]) {
      get_truetwins_by_satId(sat_Id);
    }
  }, [openCreateTrueTwin?.["isCreateTrueTwin"]]);

  const delete_truetwin = (ttId) => {
    setLoading(true);
    CommandCenterService.delete_TrueTwin(ttId)
      .then((res) => {
        if (res) {
          delete_tt_datastore(ttId)
          delete_session_logs(ttId)
          get_satellites()
          get_truetwins_by_satId(sat_Id);
          toast.success("TrueTwin deleted successfully")
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const delete_tt_datastore = (truetwin_id) => {
    CommandCenterService.deleteTrueTwinDataStore(truetwin_id)?.then((res) => {
      if (res?.data) {
        console.log(res);
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  const delete_session_logs = (truetwin_id) => {
    CommandCenterService.deleteConsoleSessionLogs(truetwin_id)?.then((res) => {
      if (res) {
        console.log(res);
      }
    }).catch((err) => {
      console.error(err);
    })
  }

  return (
    <div className="d-flex gap-3 flex-wrap">
      {trueTwins?.length > 0 &&
        trueTwins?.map((truetwin, i) => (
          <div
            className="mission-card-view gap-3"
            key={i}
            onClick={() => {
              navigateTo(
                `${mission_name}/${mission_id}/${sat_name}/${sat_Id}/truetwin_details/${truetwin?.["Name"]}/${truetwin?.["trueTwinId"]}/Configuration`,
                navigate
              );
            }}
          >
            <div className="mission-card w-430px gap-3 d-flex flex-column">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={
                      require("../../../assets/images/icons/icon-truetwin-vtr.svg")
                        .default
                    }
                    className=""
                    alt="back"
                  />
                  {(truetwin?.['Category'] === "FlatSat" && truetwin?.["isDeployed"] === "Yes") && <span className="truetwin-name" title={truetwin?.["Name"]}> {truetwin?.["Name"]?.length > 12 ? truetwin?.["Name"].substring(0, 12) + " ..." : truetwin?.["Name"]} </span>}
                  {((truetwin?.['Category'] === "FlatSat" && truetwin?.["isDeployed"] === "No") || truetwin?.['Category'] === "TrueTwin") && <span className="truetwin-name" title={truetwin?.["Name"]}> {truetwin?.["Name"]?.length > 20 ? truetwin?.["Name"].substring(0, 20) + " ..." : truetwin?.["Name"]} </span>}
                </div>
                <div
                  className="d-flex align-items-center gap-1"
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowBtnGroup('')
                  }}
                >
                  {truetwin?.['Category'] === "FlatSat" && <span className="flatSat">Flat Sat</span>}
                  {truetwin?.["isDeployed"] === "Yes" && (
                    <span className="color-atmos">
                      Launched
                      <i
                        className={`fa fa-circle ms-2 ${truetwin?.["status"] === "running"
                          ? "tt-active"
                          : "tt-err"
                          } fs-9`}
                      ></i>
                    </span>
                  )}

                  <div className="btn-list more-button">
                    <Dropdown show={showBtnGroup === truetwin?.['trueTwinId']}>
                      <Dropdown.Toggle variant="transparent text-white fs-20" onClick={(e) => {
                        e.stopPropagation()
                        if (showBtnGroup === truetwin?.['trueTwinId']) {
                          setShowBtnGroup('')
                        } else {
                          setShowBtnGroup(truetwin?.['trueTwinId'])
                        }
                      }}>
                        <i className="fe fe-more-vertical"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          eventKey="0"
                          onClick={(e) => {
                            e.preventDefault();
                            if (truetwin?.["isDeployed"] === "Yes" && truetwin?.["status"] === "running") {
                              toast.error("Cannot delete deployed TrueTwin", { toastId: "o" });
                            } else {
                              setTrueTwinData(truetwin);
                              setIsShowModal(true);
                            }
                          }}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-3 mb-3">
                <div className="mission-alpha w-50">
                  <strong>{truetwin?.["truetwincreatestarttime"]}</strong>
                  <span className="">Date Created</span>
                </div>
              </div>

              <div className="mission-card-footer w-100 pt-4">
                <div className="d-flex gap-2 align-items-center fs-12">
                  <span className="color-text">Status:</span>
                  <span className={truetwin?.["status"] === "running" ? 'color-atmos' : 'color-text'}>{truetwin?.["status"] === "running" ? "Running" : truetwin?.["status"] === 'error_in_launch' ? 'Error in launch' : truetwin?.["status"]}</span>
                </div>
                <div className="d-flex gap-1 align-items-center fs-12 card-footer-link">
                  View details
                  <i className="fe fe-chevron-right fs-16"></i>
                </div>
              </div>
            </div>
          </div>
        ))}
      <Dialog
        onClick={(event) => {
          if (event) {
            if (trueTwinData?.["isDeployed"] === "Yes") {
              toast.error("Cannot delete deployed TrueTwin", { toastId: "o" });
            } else {
              delete_truetwin(trueTwinData?.["trueTwinId"]);
            }
          }

          setIsShowModal(false);
        }}
        openModel={isShowModal}
        comment={`Are you sure you want to delete the TrueTwin '${trueTwinData?.["Name"]?.length > 15 ? trueTwinData?.["Name"]?.substring(0, 15) + '...' : trueTwinData?.["Name"]}'?`}
      />
    </div>
  );
};

export default TrueTwins;
