// @ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import { Canvas, useFrame, useLoader, useThree } from "react-three-fiber";
import { OrbitControls, TransformControls, GizmoHelper, GizmoViewcube } from "@react-three/drei";
import * as THREE from "three";
import Loader from "../../Layouts/Loader/Loader";
import CustomModelSideBar from "./CustomModelSideBar";
import CustomModelInfoCard from "./CustomModelInfoCard";
import CustomModelComponentInfoCard from "./CustomModelComponentInfoCard";
import CustomModelBottomBar from "./CustomModelBottomBar";
import SpikeModelInfoCard from "./SpikeModelInfoCard";
import CustomModelControlModeModal from "./CustomModelBottomBarModals/CustomModelControlModeModal";

import SatelliteProfile from "../SatelliteProfile";

import CustomModelReportItemComponent from "./CustomModelReportSection/CustomModelReportItemComponent";

import SatellitePhysicalPropertiesBar from "./SatellitePhysicalPropertiesBar";

import { CancelCrossIcon } from "../SatelliteProfileImgAssets/SatelliteProfileImgAssets";
import Common3DSat from "../../../../CommonComponents/Common3DSat";
import { usageStore } from "../../../../../store/UsageSlice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MissionService from "../../../../../service/MissionService";
import { toast } from "react-toastify";


function CreateMesh(props) {
  const meshRef = useRef();
  const linesRef = useRef();
  const groupRef = useRef();
  const transformControlsRef = useRef();
  useEffect(() => {
    if (groupRef.current) {
      console.log("Group ref is set:", groupRef.current);
      console.log(THREE.MathUtils.degToRad(props.orientation.y));
      // Apply orientation to the group
      groupRef.current.rotation.x = THREE.MathUtils.degToRad(props.orientation.x);
      groupRef.current.rotation.y = THREE.MathUtils.degToRad(props.orientation.y);
      groupRef.current.rotation.z = THREE.MathUtils.degToRad(props.orientation.z);
    }
  }, [props.orientation]);
  useEffect(() => {
    const transformControls = transformControlsRef.current;
    const handleDragStart = () => {
      console.log("Drag started");
      if (props.orbitControlsRef?.current) {
        props.orbitControlsRef.current.enabled = false; // Disable OrbitControls
      }
    };
    const handleDragEnd = () => {
      console.log("Drag ended");
      if (props.orbitControlsRef?.current) {
        props.orbitControlsRef.current.enabled = true; // Enable OrbitControls
      }
    };
    if (transformControls) {
      transformControls.addEventListener("dragging-changed", (event) => {
        if (event.value) {
          handleDragStart(); // When dragging starts
        } else {
          handleDragEnd(); // When dragging ends
        }
      });
    }
    return () => {
      if (transformControls) {
        transformControls.removeEventListener("dragging-changed", handleDragStart);
        transformControls.removeEventListener("dragging-changed", handleDragEnd);
      }
    };
  }, [props.orbitControlsRef]);
  return (
    <>
      <TransformControls ref={transformControlsRef} object={groupRef.current}>
        <group ref={groupRef}>
          <mesh
            ref={meshRef}
            position={[props.position[0], props.position[1], props.position[2]]}
            name={"bus_components"}
          >
            <boxGeometry args={props.args} attach="geometry" />
            <meshPhysicalMaterial
              attach="material"
              color="#DAA520"
              emissive="#FF8C00" // Soft glow
              emissiveIntensity={0.5}
              metalness={0.9}
              roughness={0.2}
            />
          </mesh>
          <lineSegments ref={linesRef}>
            <edgesGeometry attach="geometry" args={[new THREE.BoxGeometry(...props.args)]} />
            <lineBasicMaterial attach="material" color="#FFFFFF" /> {/* White border */}
          </lineSegments>
        </group>
      </TransformControls>
    </>
  );
}

const clearSceneByName = (scene, name) => {
  console.log(`Cleared objects with name "${name}" from the scene.`);
  // const { scene } = useThree();
  scene.children
    .filter((child) => child.name === name)
    .forEach((child) => {
      console.log("chilllldddd --> ", child);

      scene.remove(child); // Remove the child from the scene
      child.geometry?.dispose(); // Dispose of geometry resources
      child.material?.dispose(); // Dispose of material resources
    });
  console.log(`Cleared objects with name "${name}" from the scene.`);
};
const CommonSat = () => {
  const { camera, gl, scene } = useThree();
  return (
    <group>
      <ambientLight intensity={1} />
      <spotLight intensity={5} position={[-10, 10, 10]} angle={0.3} />
    </group>
  );
};


function SceneManager({ setSelectedMesh }) {
  const raycaster = useRef(new THREE.Raycaster());
  const [selectedObject, setSelectedObject] = useState(null);
  const [cubePosition, setCubePosition] = useState(null);
  const controlsRef = useRef();
  const { camera, scene, gl } = useThree();

  useEffect(() => {
    const handleMouseDown = (event) => {
      const mouse = new THREE.Vector2();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      raycaster.current.setFromCamera(mouse, camera);
      const intersects = raycaster.current.intersectObjects(scene.children);
      if (intersects.length > 0) {
        const intersectedObject = intersects[0].object;
        setSelectedObject(intersectedObject);
      }
      for (let i = 0; i < intersects.length; i++) {
        const intersectedMesh = intersects[i].object;
        if (intersectedMesh.name === "bus_components") {
          setSelectedMesh("bus_components");
          break;
        }
      }
    };

    gl.domElement.addEventListener("mousedown", handleMouseDown);

    return () => {
      gl.domElement.removeEventListener("mousedown", handleMouseDown);
    };
  }, [camera, scene, gl]);
}

const getObjectByName = (name, scene) => {
  for (let i = 0; i < scene.children.length; i++) {
    const object = scene.children[i];
    if (object.name === name) {
      return object;
    }
  }
  return null;
};

const Custom3DSat = ({ Satellite, busSize, size, cubeSize }) => {
  const [isReportBarOpen, setIsReportBarOpen] = useState(false);
  const reportProps = { isReportBarOpen, setIsReportBarOpen };

  const [isAddControlModeModal, setIsAddControlModeModal] = useState(false);
  const controlModeModalProps = { isAddControlModeModal, setIsAddControlModeModal };

  const [meshes, setMeshes] = useState([]);
  const params = useParams();
  const { mission_name, sat_name, mission_id, sat_id } = params;

  const orbitControlsRef = useRef(null);
  const [selectedMesh, setSelectedMesh] = useState(null);
  const [orientation, setOrientation] = useState({ x: 0, y: 0, z: 0 });

  const [allSensors, setAllSensors] = useState<any>()
  const [controlModes, setControlmodes] = useState<any>()
  const [selectedSensor, setSelectedSensor] = useState<any>()
  const [allReports, setAllReports] = useState<any>()
  const [loading, setLoading] = useState(false)

  const addMesh = (newMesh) => {
    setMeshes(() => [newMesh]);
    setControlMode(false)
    setSelectedMesh('bus_components')
  };

  const handleOrientationChange = (newOrientation) => {
    setOrientation(newOrientation); // Update the orientation state in Custom3DSat
  };

  const busSizeUsage = useSelector(
    (state: { usage: usageStore }) => state.usage.busSizeUsage
  );
  const payloadSizeUsage = useSelector(
    (state: { usage: usageStore }) => state.usage.payloadSizeUsage
  );

  {/** Loading the default model */ }
  const [controlMode, setControlMode] = useState(true)


  const get_control_modes = () => {
    MissionService.getControlModes(sat_id).then((res) => {
      if (res) {
        setControlmodes(res?.data)
      }
    }).catch((err) => {
      toast.error("Getting Error While Fetching Control Modes")
    })
  }

  const get_all_sensors = () => {
    MissionService.getAllSensors(sat_id).then((res) => {
      if (res) {
        setAllSensors(res?.data)
      }
    }).catch((err) => {
      toast.error("Getting Error While Fetching Sensors", { toastId: 0 })
    })
  }

  const addControlMode = (body) => {
    MissionService.postControlMode(body, sat_id).then((res) => {
      if (res) {
        toast.success("Control Mode Posted Successfully")
        get_control_modes()
        setIsAddControlModeModal(false)
      }
    }).catch((err) => {
      toast.error("Getting error while posting control mode")
    })
  }

  const startSensorAnalysis = (data) => {
    setLoading(true)
    MissionService.sensorAnalysis(data, sat_id)?.then((res) => {
      if (res) {
        setLoading(false)
        toast.success("Successfully Analysis Completed")
        if (!isReportBarOpen) {
          setIsReportBarOpen(!isReportBarOpen)
        }
      }
    }).catch(() => {
      setLoading(false)
      toast.error("Something went wrong while Analysis")
    })
  }

  const getAllReports = () => {
    MissionService.getAllReports(sat_id)?.then((res) => {
      if (res) {
        setAllReports(res?.data)
        if (!isReportBarOpen) {
          setIsReportBarOpen(!isReportBarOpen)
        }
      }
    }).catch(() => {
      toast.error("Something went wrong while fetching the reports", { toastId: 0 })
    })
  }

  useEffect(() => {
    get_control_modes()
    get_all_sensors()
  }, [sat_id])


  return (

    <div style={{ height: window.innerHeight, width: window.innerWidth, overflow: "hidden" }}>
      {!controlMode &&
        <Canvas camera={{ position: [0, 0, 10] }} style={{ background: "#000", width: "100vw", height: "100vh" }}>
          <OrbitControls ref={orbitControlsRef} enableZoom={true} minDistance={5} maxDistance={100} target={[0, 0, 0]} name="Orbit Controls" />
          <CommonSat />
          <group>
            {meshes.map((meshProps) => (
              <CreateMesh
                {...meshProps}
                orbitControlsRef={orbitControlsRef} // Pass orbitControlsRef here
                orientation={orientation}
              />
            ))}
          </group>
          <SceneManager orbitControlsRef={orbitControlsRef} />
        </Canvas>
      }

      <CustomModelSideBar addMesh={addMesh} allSensors={allSensors} setSelectedSensor={setSelectedSensor} />
      <SatellitePhysicalPropertiesBar />
      {selectedMesh === "bus_components" && selectedSensor && (
        <CustomModelComponentInfoCard reportProps={reportProps} orientation={orientation} onOrientationChange={handleOrientationChange} selectedSensor={selectedSensor} controlModes={controlModes} startSensorAnalysis={startSensorAnalysis} loading={loading} setIsReportBarOpen={setIsReportBarOpen} isReportBarOpen={isReportBarOpen} />
      )}

      <button className="reportSwitchOnInfocardBtn cancelCrossIcon">
        <CancelCrossIcon />
      </button>

      <CustomModelBottomBar setControlMode={setControlMode} setSelectedMesh={setSelectedMesh} />

      {selectedMesh != "bus_components" && <SpikeModelInfoCard reportProps={reportProps} controlModeModalProps={controlModeModalProps} controlModes={controlModes} />}

      {isReportBarOpen && <CustomModelReportItemComponent reportProps={reportProps} isReportBarOpen={isReportBarOpen} />}

      {isAddControlModeModal && selectedMesh != "bus_components" && <CustomModelControlModeModal controlModeModalProps={controlModeModalProps} addControlMode={addControlMode} />}

      {/* <Common3DSat busSize={Satellite.Template?.['template-attribute']?.['bus-type']} size={busSizeUsage + payloadSizeUsage} canvas_height="100%" canvas_width="100%" isPayloadChanges="false" cubeSize={isCloneTemplate ? 0 : 1} /> */}
      {controlMode && <Common3DSat busSize={"12U"} size={3 + 8} canvas_height="100%" canvas_width="100%" isPayloadChanges="false" cubeSize={1} />}

    </div>
  );
};

export default Custom3DSat;
