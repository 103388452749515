import { Fragment } from 'react'
import CustomToolTip from '../../../CommonComponents/CustomToolTip'
import useModals from '../../hooks/useModals';
import { ActivitySvg, ConsoleSvg, FilesQueueSvg, GroundScheduleSvg, NotificationSvg, OnboardScheduleSvg, PinnedSvg, TelemetrySvg } from './Svgs';
import useUrlParams from '../../hooks/useUrlParams';
import { ModalType } from '../../providers/ModalProvider';

const RightContainer = () => {
    const { dashboard } = useUrlParams()
    const { open_modal, close_modal, get_modal_details } = useModals()
    const toggle_modal = (modal: ModalType) => {
        const modal_details = get_modal_details(modal)
        if (modal_details.open && modal_details.pinned) return
        else if (modal_details.open && !modal_details.pinned) {
            close_modal(modal)
        } else {
            open_modal(modal)
        }
    }

    return (
        <Fragment>
            <div className={`right__button__container`} onClick={(e: any) => { e.stopPropagation() }}>
                <CustomToolTip title='Onboard Schedule' placement='bottom'>
                    <button className={`right__section__button ${dashboard === 'quick-deploy' && 'hide__btn'} ${dashboard === 'operate-0.1' ? 'left__btn' : 'single__btn'} ${get_modal_details('onboard_schedule').open && 'active'}`} onClick={() => { toggle_modal('onboard_schedule') }}>
                        <OnboardScheduleSvg />
                        <PinnedSvg showPinned={get_modal_details('onboard_schedule').pinned} />
                    </button>
                </CustomToolTip>
                <CustomToolTip title='Ground Schedule' placement='bottom'>
                    <button className={`right__section__button ${dashboard === 'operate-0.1' ? 'right__btn' : 'hide__btn'} ${get_modal_details('ground_schedule').open && 'active'}`} onClick={() => { toggle_modal('ground_schedule') }}>
                        <GroundScheduleSvg />
                        <PinnedSvg showPinned={get_modal_details('ground_schedule').pinned} />
                    </button>
                </CustomToolTip>
            </div>
            <div className='right__button__container' onClick={(e: any) => { e.stopPropagation() }}>
                <CustomToolTip title='Console' placement='bottom'>
                    <button className={`right__section__button left__btn ${get_modal_details('console').open && 'active'}`} onClick={() => { toggle_modal('console') }}>
                        <ConsoleSvg />
                        <PinnedSvg showPinned={get_modal_details('console').pinned} />
                    </button>
                </CustomToolTip>
                <CustomToolTip title='Telemetry' placement='bottom'>
                    <button className={`right__section__button center__btn ${get_modal_details('telemetry').open && 'active'}`} onClick={() => { toggle_modal('telemetry') }}>
                        <TelemetrySvg />
                        <PinnedSvg showPinned={get_modal_details('telemetry').pinned} />
                    </button>
                </CustomToolTip>
                <CustomToolTip title='File Queue' placement='bottom'>
                    <button className={`right__section__button right__btn ${get_modal_details('filequeue').open && 'active'}`} onClick={() => { toggle_modal('filequeue') }}>
                        <FilesQueueSvg />
                        <PinnedSvg showPinned={get_modal_details('filequeue').pinned} />
                    </button>
                </CustomToolTip>
            </div>
            <div className={`right__button__container ${dashboard !== 'operate-0.1' && 'hide__section'}`} onClick={(e: any) => { e.stopPropagation() }}>
                <CustomToolTip title='Activity' placement='bottom'>
                    <button className={`right__section__button left__btn disabled`}>
                        <ActivitySvg />
                    </button>
                </CustomToolTip>
                <CustomToolTip title='Notification' placement='bottom'>
                    <button className={`right__section__button right__btn disabled`}>
                        <NotificationSvg />
                    </button>
                </CustomToolTip>
            </div>
        </Fragment>
    )
}

export default RightContainer