//@ts-nocheck

import { useEffect, useState } from "react";
import "./CustomModelSideBar.css";
import Custom3DSat from "./Custom3DSat";
import { set } from "lodash";
import { ClassNames } from "@emotion/react";

import {
  BackButton,
  SatImage,
  AddItemIconPlus,
  MsImagerIcon,
  AntennaIcon,
  SensorsIcon,
  ADCSIcon,
  GPSIcon,
  ThrusterIcon,
  MiniDropdownIconOpened,
  MiniDropdownIconClosed,
  TrailWithSplit,
  TrailWithoutSplit,
} from "../SatelliteProfileImgAssets/SatelliteProfileImgAssets";
import { useParams } from "react-router-dom";

const CustomModelSideBar = ({ addMesh, allSensors, setSelectedSensor }) => {

  const params = useParams();
  const { mission_name, sat_name, mission_id, sat_id } = params;

  const [isSidebarActive, setIsSidebarActive] = useState<boolean>(true);
  const payloadData = [{ type: "MS Imager" }, { type: "Antenna" }];
  const [busData, setBusData] = useState()

  const addPayloadFunc = () => {
    const props = {
      position: [0, 0, 0],
      args: [1, 1, 1],
    };
    addMesh(props);
  };

  const iconDisplayerFunc = (type) => {
    if (type === "MS Imager") return <MsImagerIcon />;
    else if (type === "Antenna") return <AntennaIcon />;
    else if (type === "Sensors") return <SensorsIcon />;
    else if (type === "ADCS") return <ADCSIcon />;
    else if (type === "GPS") return <GPSIcon />;
    else if (type === "Thrusters") return <ThrusterIcon />;
  };

  const [openedLevels, setOpenedLevels] = useState({
    firstLevel: [],
    secondLevel: [],
    thirdLevel: [],
  });

  const toggleFirstLevel = (index) => {
    setOpenedLevels((prevState) => {
      const updatedFirstLevel = prevState.firstLevel.includes(index)
        ? prevState.firstLevel.filter((i) => i !== index) // Remove if already opened
        : [...prevState.firstLevel, index]; // Add if not opened
      return {
        ...prevState,
        firstLevel: updatedFirstLevel,
      };
    });
  };

  const toggleSecondLevel = (index, parentIndex) => {
    setOpenedLevels((prevState) => {
      const updatedSecondLevel = prevState.secondLevel.includes(index) ? prevState.secondLevel.filter((i) => i !== index) : [...prevState.secondLevel, index];
      return {
        ...prevState,
        secondLevel: updatedSecondLevel,
      };
    });
  };

  const toggleThirdLevel = (index, parentIndex) => {
    setOpenedLevels((prevState) => {
      const updatedThirdLevel = prevState.thirdLevel.includes(index) ? prevState.thirdLevel.filter((i) => i !== index) : [...prevState.thirdLevel, index];
      return {
        ...prevState,
        thirdLevel: updatedThirdLevel,
      };
    });
  };

  useEffect(() => {
    if (allSensors) {
      let sensorsTypes = []
      Object.keys(allSensors)?.map((sensors) => {
        let subSensors = []
        allSensors[sensors]?.map((subSensor) => {
          subSensors.push({ name: subSensor.name, value: subSensor })
        })
        sensorsTypes.push({
          type: sensors,
          sensorItems: subSensors
        })
      })

      const busDatas = [
        {
          type: "Sensors",
          sensorTypes: sensorsTypes
        },
        { type: "ADCS" },
        { type: "GPS" },
        { type: "Thrusters" },
      ];

      setBusData(busDatas)
    }
  }, [allSensors])

  return (
    <div className={`custom-model-sidebar ${isSidebarActive ? "active" : ""}`} id="create_model_sidebar">
      <div className="custom-model-sidebar-container">
        <div className="custom-model-sidebar-header ">
          <div className="custom-model-sidebar-header-back-button scalingButton">
            <BackButton />
          </div>
          <div className="custom-model-sidebar-header-title">
            <div className="custom-model-sidebar-header-satname">
              <div className="custom-model-sidebar-header-back-button">
                <SatImage />
              </div>
              <span className="custom-model-sidebar-header-label">{sat_name}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-model-sat-components-div">
        {/* Payload section of code, to be used later */}
        {/* <div className="custom-model-sat-components-payload-detail">
          <div className="custom-model-sat-components-payload-header">
            <div className="custom-model-sidebar-modal-payload-icon-label">
              <div className="custom-model-sidebar-header-label">Payload</div>
              <div className="custom-model-sidebar-modal-payload-count">{payloadData.length}</div>
            </div>
            <div className="scalingButton">
              <AddItemIconPlus />
            </div>
          </div>
          <div className="custom-model-sat-components-payload-cards-container greyScrollableSectionBar">
            {payloadData.map((item) => (
              <>
                <div className="payload-data-item-level-one">
                  <div className="payload-data-item-level-one-image">{iconDisplayerFunc(item.type)}</div>
                  <div className="payload-data-item-level-one-text">
                    {item.type}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div> */}

        {/* For Bus */}
        {busData && <div className="custom-model-sat-components-payload-detail">
          <div className="custom-model-sat-components-payload-header">
            <div className="custom-model-sidebar-modal-payload-icon-label">
              <div className="custom-model-sidebar-header-label">Bus</div>
              <div className="custom-model-sidebar-modal-payload-count">{busData.length}</div>
            </div>
            <div className="scalingButton">
              <AddItemIconPlus />
            </div>
          </div>
          <div className="custom-model-sat-components-payload-cards-container">
            <div className="custom-model-sat-components-payload-cards-container">
              {busData.map((item, index) => (
                <div key={index}>
                  {/* First Level */}
                  <div className="payload-data-item-level-one">
                    <div className="payload-data-item-level-one-image" onClick={() => toggleFirstLevel(index)}>
                      {item?.sensorTypes && openedLevels.firstLevel.includes(index) && <MiniDropdownIconOpened />}
                      {item?.sensorTypes && !openedLevels.firstLevel.includes(index) && <MiniDropdownIconClosed />}
                      {iconDisplayerFunc(item.type)}
                    </div>
                    <div className="payload-data-item-level-one-text" onClick={() => {
                      // addPayloadFunc
                    }
                    }>
                      {item.type}
                    </div>
                  </div>

                  {/* Second Level */}
                  {item?.sensorTypes &&
                    openedLevels.firstLevel.includes(index) &&
                    item.sensorTypes.map((sensorType, sensorIndex) => (
                      <div key={sensorIndex}>
                        <div className="payload-data-item-level-one">
                          <div className="payload-data-item-level-one-image payload-data-item-level-two-image" onClick={() => toggleSecondLevel(sensorIndex, index)}>
                            {/* <TrailWithSplit /> */}
                            {sensorType?.sensorItems && openedLevels.secondLevel.includes(sensorIndex) && <MiniDropdownIconOpened />}
                            {sensorType?.sensorItems && !openedLevels.secondLevel.includes(sensorIndex) && <MiniDropdownIconClosed />}
                            {iconDisplayerFunc(item.type)}
                          </div>
                          <div className="payload-data-item-level-one-text" onClick={() => {
                            // addPayloadFunc
                          }
                          }>
                            {sensorType.type}
                          </div>
                        </div>

                        {/* Third Level */}
                        {sensorType?.sensorItems &&
                          openedLevels.secondLevel.includes(sensorIndex) &&
                          sensorType.sensorItems.map((sensorItem, itemIndex) => (
                            <div key={itemIndex}>
                              <div className="payload-data-item-level-one">
                                <div className="payload-data-item-level-one-image payload-data-item-level-three-image">
                                  {/* <TrailWithoutSplit />
                                  <TrailWithSplit /> */}
                                  {iconDisplayerFunc(item.type)}
                                </div>
                                <div className="payload-data-item-level-one-text" onClick={() => {
                                  addPayloadFunc()
                                  setSelectedSensor(sensorItem.value)
                                }
                                }>
                                  {sensorItem.name}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default CustomModelSideBar;
