import React from "react";

export const BackButton: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M12.5 15L7.5 10L12.5 5" stroke="#F0F0F0" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const SatImage: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
    <path
      d="M12.8303 4.16999L9.97638 1.31608C9.87501 1.21471 9.70996 1.21424 9.61485 1.3215C8.82774 2.20911 8.47406 3.34668 8.55329 4.46064M12.8303 4.16999L15.6842 7.02389C15.7856 7.12526 15.786 7.29023 15.6797 7.38647C14.7678 8.21256 13.7039 8.57377 12.5396 8.44698M12.8303 4.16999L14.3869 2.61342M8.55329 4.46064C8.62414 5.45665 9.04109 6.43379 9.80378 7.19649C10.5253 7.91804 11.5998 8.34463 12.5396 8.44698M8.55329 4.46064L6.82584 6.18809M12.5396 8.44698L10.8122 10.1744M6.82584 6.18809L4.38915 3.75139C4.28777 3.65002 4.12342 3.65002 4.02205 3.75139L1.09911 6.67433C0.997734 6.7757 0.997734 6.94006 1.09911 7.04143L3.5358 9.47813M6.82584 6.18809L3.5358 9.47813M3.5358 9.47813L2.84418 10.1698C2.16837 10.8456 2.16837 11.9413 2.84418 12.6171L4.3832 14.1561C5.05901 14.8319 6.15471 14.8319 6.83052 14.1561L7.52214 13.4645M10.8122 10.1744L7.52214 13.4645M10.8122 10.1744L13.2489 12.6111C13.3503 12.7125 13.3503 12.8769 13.2489 12.9782L10.3259 15.9012C10.2246 16.0025 10.0602 16.0025 9.95884 15.9012L7.52214 13.4645"
      stroke="#F0F0F0"
      stroke-linecap="round"
    />
  </svg>
);

export const AddItemIconPlus: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M8 4V12M12 8H4" stroke="#B2B2B2" stroke-width="0.888889" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const MsImagerIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
    <path
      d="M14.9224 9.76609L13.5967 1.76669C13.4824 1.07647 12.8854 0.570312 12.1858 0.570312H11.6073H6.16926H4.52986H3.81314C3.11351 0.570312 2.51657 1.07647 2.40219 1.76669L1.91508 4.70605L1.67432 6.15889L1.07654 9.76609C0.93214 10.6374 1.60425 11.4301 2.48749 11.4301H4.51905H13.5114C14.3947 11.4301 15.0668 10.6374 14.9224 9.76609Z"
      stroke="#F0F0F0"
      stroke-width="0.6"
      stroke-linecap="round"
    />
    <path
      d="M4.52937 0.570312C6.1291 1.96059 9.89696 4.7144 8.37826 7.02388C7.01988 9.08957 5.16328 8.10429 3.99718 5.85388C3.33145 4.56912 1.91459 4.70605 1.91459 4.70605M6.16876 0.570312C7.03853 1.23759 9.45178 3.56686 9.82909 4.68363C10.3413 6.19982 9.89478 7.5789 8.853 8.63531C6.40663 11.116 3.94441 8.82093 3.28062 7.02388C2.79431 5.70735 1.67383 6.15889 1.67383 6.15889M11.6068 0.570312C10.3329 1.03876 10.1088 2.35238 11.057 3.28526C12.1118 4.32296 12.3024 5.2261 12.1484 6.439C11.9492 8.00819 10.7152 9.29967 9.56344 10.1525C7.83791 11.4301 4.51855 11.4301 4.51855 11.4301"
      stroke="#F0F0F0"
      stroke-width="0.6"
      stroke-linecap="round"
    />
  </svg>
);

export const AntennaIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M3.26641 12.7323C0.666406 10.1323 0.666406 5.86563 3.26641 3.26562" stroke="#F0F0F0" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.20078 10.7995C3.66745 9.26615 3.66745 6.73281 5.20078 5.13281" stroke="#F0F0F0" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M7.99935 9.33073C8.73573 9.33073 9.33268 8.73378 9.33268 7.9974C9.33268 7.26102 8.73573 6.66406 7.99935 6.66406C7.26297 6.66406 6.66602 7.26102 6.66602 7.9974C6.66602 8.73378 7.26297 9.33073 7.99935 9.33073Z"
      stroke="#F0F0F0"
      stroke-width="0.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M10.8008 5.20312C12.3341 6.73646 12.3341 9.26979 10.8008 10.8698" stroke="#F0F0F0" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.7344 3.26562C15.3344 5.86562 15.3344 10.0656 12.7344 12.6656" stroke="#F0F0F0" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const SensorsIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 8" fill="none">
    <path
      d="M3.40039 3.99844C3.40039 5.98666 5.01217 7.59844 7.00039 7.59844C8.96117 7.59844 10.6004 5.95897 10.6004 3.99844C10.6004 2.01021 8.98862 0.398438 7.00039 0.398438C5.03965 0.398438 3.40039 2.03765 3.40039 3.99844Z"
      stroke="#F0F0F0"
      stroke-width="0.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.75576 1.11719C1.27534 1.97635 1 2.95979 1 3.99725C1 5.0409 1.26647 6.02232 1.73507 6.87719M12.2649 1.11719C12.7335 1.97208 13 2.95354 13 3.99725C13 5.03459 12.7246 6.01801 12.2442 6.87719"
      stroke="#F0F0F0"
      stroke-width="0.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const GPSIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M1.33398 8H3.33398" stroke="#F0F0F0" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.666 8H14.666" stroke="#F0F0F0" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8 1.33594V3.33594" stroke="#F0F0F0" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8 12.6641V14.6641" stroke="#F0F0F0" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M8.00065 12.6693C10.578 12.6693 12.6673 10.5799 12.6673 8.0026C12.6673 5.42528 10.578 3.33594 8.00065 3.33594C5.42332 3.33594 3.33398 5.42528 3.33398 8.0026C3.33398 10.5799 5.42332 12.6693 8.00065 12.6693Z"
      stroke="#F0F0F0"
      stroke-width="0.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      stroke="#F0F0F0"
      stroke-width="0.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ADCSIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
    <path
      d="M11.1809 4.34225L9.23315 2.3945C9.14525 2.3066 9.00198 2.30611 8.92092 2.40034C8.39571 3.01093 8.16025 3.78529 8.21421 4.54385M11.1809 4.34225L13.1286 6.29C13.2165 6.37789 13.217 6.52113 13.1238 6.60338C12.4987 7.15508 11.7726 7.39533 10.9793 7.30894M11.1809 4.34225L12.2606 3.26255M8.21421 4.54385C8.26335 5.23473 8.55256 5.91251 9.0816 6.44155C9.5821 6.94205 10.3274 7.23795 10.9793 7.30894M8.21421 4.54385L7.01598 5.74208M10.9793 7.30894L9.78106 8.50717M7.01598 5.74208L5.35762 4.08372C5.26972 3.99583 5.12722 3.99583 5.03933 4.08372L3.07552 6.04753C2.98762 6.13542 2.98762 6.27793 3.07552 6.36582L4.73388 8.02418M7.01598 5.74208L4.73388 8.02418M4.73388 8.02418L4.46633 8.29173C3.88037 8.87769 3.88037 9.82771 4.46633 10.4137L5.10947 11.0568C5.69543 11.6428 6.64546 11.6428 7.23142 11.0568L7.49896 10.7893M9.78106 8.50717L7.49896 10.7893M9.78106 8.50717L11.4394 10.1655C11.5273 10.2534 11.5273 10.3959 11.4394 10.4838L9.47561 12.4476C9.38772 12.5355 9.24522 12.5355 9.15732 12.4476L7.49896 10.7893"
      stroke="#F0F0F0"
      stroke-width="0.6"
      stroke-linecap="round"
    />
    <path
      d="M1.74219 4.33594L0.408854 5.66927L1.74219 7.0026L0.408854 8.33594L1.74219 9.66927"
      stroke="#F0F0F0"
      stroke-width="0.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M14.2585 4.33594L15.5918 5.66927L14.2585 7.0026L15.5918 8.33594L14.2585 9.66927" stroke="#F0F0F0" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const ThrusterIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      d="M8.7911 11.0128C8.14823 11.6641 8.57711 12.5394 8.9135 13.2211C9.40356 14.2136 9.0159 15.0987 7.99922 16C7.55436 13.7464 4.79922 12.7222 4.79922 10.4421C4.79922 8.69404 6.23857 7.2 7.99922 7.2C9.75987 7.2 11.1992 8.69404 11.1992 10.4421C11.1992 10.9761 11.0013 11.5046 10.7421 11.8316C10.7421 10.8327 9.50221 10.2924 8.7911 11.0128Z"
      stroke="#F0F0F0"
      stroke-width="0.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.13605 7.05967L3.64793 4.41388C4.3601 3.16758 5.68547 2.39844 7.1209 2.39844H8.87832C10.3137 2.39844 11.6391 3.16758 12.3513 4.41388L13.8632 7.05967C14.0746 7.42972 13.7558 7.87773 13.3369 7.79918L12.4225 7.62774C9.49939 7.07965 6.49983 7.07965 3.57668 7.62774L2.66231 7.79918C2.2434 7.87773 1.92459 7.42972 2.13605 7.05967Z"
      stroke="#F0F0F0"
      stroke-width="0.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ScaleIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path d="M1.83398 3.64844V16.6984H14.884" stroke="#F0F0F0" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.83398 16.6984L8.58398 10.3984" stroke="#F0F0F0" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const WeightIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path
      d="M9.18359 6.5C10.4262 6.5 11.4336 5.49264 11.4336 4.25C11.4336 3.00736 10.4262 2 9.18359 2C7.94095 2 6.93359 3.00736 6.93359 4.25C6.93359 5.49264 7.94095 6.5 9.18359 6.5Z"
      stroke="#F0F0F0"
      stroke-width="0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.05863 6.5C4.73407 6.50337 4.41936 6.61192 4.16175 6.80936C3.90413 7.0068 3.7175 7.28247 3.62988 7.595L1.75863 14.375C1.70193 14.5944 1.6957 14.8239 1.7404 15.0461C1.78509 15.2683 1.87956 15.4774 2.01669 15.6579C2.15383 15.8383 2.33008 15.9853 2.53219 16.0879C2.7343 16.1905 2.95703 16.2459 3.18363 16.25H15.1836C15.4151 16.2499 15.6435 16.1962 15.8508 16.0932C16.0581 15.9902 16.2387 15.8405 16.3786 15.656C16.5184 15.4715 16.6136 15.2571 16.6568 15.0297C16.7 14.8023 16.6899 14.5679 16.6274 14.345L14.7336 7.625C14.651 7.30506 14.4651 7.02134 14.2047 6.81791C13.9443 6.61448 13.6241 6.50273 13.2936 6.5H5.05863Z"
      stroke="#F0F0F0"
      stroke-width="0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const RenewableEnergyIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <g clip-path="url(#clip0_5906_24554)">
      <path d="M7.68359 2.75V3.5" stroke="#F0F0F0" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2.43359 4.25L3.18359 5" stroke="#F0F0F0" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M0.933594 9.5H1.68359" stroke="#F0F0F0" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M11.0481 6.51948C10.4423 5.83745 9.64356 5.35562 8.7577 5.13788C7.87185 4.92014 6.94074 4.97677 6.08781 5.30026C5.23487 5.62376 4.50039 6.19883 3.98173 6.94926C3.46307 7.69968 3.18473 8.59001 3.1836 9.50223C3.1831 10.0027 3.26781 10.4995 3.4341 10.9715"
        stroke="#F0F0F0"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.07558 9.69275C6.10807 9.63432 6.15559 9.58565 6.21321 9.55176C6.27084 9.51787 6.33648 9.5 6.40333 9.5H14.2123C14.2792 9.5 14.3448 9.51787 14.4024 9.55176C14.4601 9.58565 14.5076 9.63432 14.5401 9.69275L17.8731 15.6927C17.9048 15.7498 17.9211 15.8142 17.9203 15.8795C17.9195 15.9448 17.9017 16.0088 17.8686 16.0651C17.8355 16.1214 17.7882 16.1681 17.7315 16.2005C17.6748 16.2329 17.6106 16.25 17.5453 16.25H3.07033C3.00501 16.25 2.94083 16.2329 2.88413 16.2005C2.82743 16.1681 2.78019 16.1214 2.74708 16.0651C2.71396 16.0088 2.69613 15.9448 2.69534 15.8795C2.69455 15.8142 2.71083 15.7498 2.74258 15.6927L6.07558 9.69275Z"
        stroke="#F0F0F0"
        stroke-width="0.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M8.80859 9.5L7.30859 16.25" stroke="#F0F0F0" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.8086 9.5L13.3086 16.25" stroke="#F0F0F0" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.93359 11.75H15.6833" stroke="#F0F0F0" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.68359 14H16.9338" stroke="#F0F0F0" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_5906_24554">
        <rect width="18" height="18" fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const CopyIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#clip0_5906_19033)">
      <path
        d="M10 4H5C4.44772 4 4 4.44772 4 5V10C4 10.5523 4.44772 11 5 11H10C10.5523 11 11 10.5523 11 10V5C11 4.44772 10.5523 4 10 4Z"
        stroke="#F0F0F0"
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M2 8C1.45 8 1 7.55 1 7V2C1 1.45 1.45 1 2 1H7C7.55 1 8 1.45 8 2" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_5906_19033">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ReportIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 18 18" fill="none">
    <path d="M2.23077 17L15.7692 17C16.449 17 17 16.449 17 15.7692L17 2.23077C17 1.55103 16.449 1 15.7692 1L2.23077 1C1.55103 1 1 1.55103 1 2.23077L1 15.7692C1 16.449 1.55103 17 2.23077 17Z" stroke="#ccf54e" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.07812 4.07812H6.53966M4.07812 7.15505H9.61659M14.5397 7.15505L10.8474 13.3089L6.53966 10.8474L4.07812 14.5397" stroke="#ccf54e" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const ReplaceIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#clip0_5906_19037)">
      <path
        d="M10 1H8C7.44772 1 7 1.44772 7 2V4C7 4.55228 7.44772 5 8 5H10C10.5523 5 11 4.55228 11 4V2C11 1.44772 10.5523 1 10 1Z"
        stroke="#F0F0F0"
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M1.5 3.5L3 5L4.5 3.5" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M3 5V2.5C3 2.10218 3.15804 1.72064 3.43934 1.43934C3.72064 1.15804 4.10218 1 4.5 1H5"
        stroke="#F0F0F0"
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 7H2C1.44772 7 1 7.44772 1 8V10C1 10.5523 1.44772 11 2 11H4C4.55228 11 5 10.5523 5 10V8C5 7.44772 4.55228 7 4 7Z"
        stroke="#F0F0F0"
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5906_19037">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const EditIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M6 1.5H2.5C2.23478 1.5 1.98043 1.60536 1.79289 1.79289C1.60536 1.98043 1.5 2.23478 1.5 2.5V9.5C1.5 9.76522 1.60536 10.0196 1.79289 10.2071C1.98043 10.3946 2.23478 10.5 2.5 10.5H9.5C9.76522 10.5 10.0196 10.3946 10.2071 10.2071C10.3946 10.0196 10.5 9.76522 10.5 9.5V6"
      stroke="#F0F0F0"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.18755 1.31066C9.38646 1.11175 9.65624 1 9.93755 1C10.2189 1 10.4886 1.11175 10.6875 1.31066C10.8865 1.50957 10.9982 1.77936 10.9982 2.06066C10.9982 2.34196 10.8865 2.61175 10.6875 2.81066L6.18105 7.31766C6.06232 7.43628 5.91565 7.52312 5.75455 7.57016L4.31805 7.99016C4.27502 8.00271 4.22942 8.00346 4.186 7.99234C4.14259 7.98122 4.10296 7.95863 4.07127 7.92694C4.03958 7.89525 4.01699 7.85562 4.00587 7.8122C3.99475 7.76879 3.9955 7.72318 4.00805 7.68016L4.42805 6.24366C4.47531 6.08269 4.56231 5.93619 4.68105 5.81766L9.18755 1.31066Z"
      stroke="#F0F0F0"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const TrashIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M1.5 3H10.5" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.5 3V10C9.5 10.5 9 11 8.5 11H3.5C3 11 2.5 10.5 2.5 10V3" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4 3V2C4 1.5 4.5 1 5 1H7C7.5 1 8 1.5 8 2V3" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const ToggleButtonInactiveIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
    <rect opacity="0.12" x="1" y="1" width="22" height="14" rx="2" fill="#F0F0F0" />
    <rect opacity="0.4" x="3" y="3" width="10" height="10" rx="1" fill="#F0F0F0" />
  </svg>
);

export const ToggleButtonActiveIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
    <rect opacity="0.12" x="1" y="1" width="22" height="14" rx="2" fill="#F0F0F0" />
    <rect opacity="0.8" x="11" y="3" width="10" height="10" rx="1" fill="#CCF54E" />
  </svg>
);

export const ShowReportIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M3 5L9 10L3 15V5Z" stroke="#CCF54E" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M8.5765 4.68741C9.39138 4.46906 10.2456 4.4411 11.073 4.60568C11.9004 4.77027 12.6789 5.12299 13.3482 5.63656C14.0175 6.15013 14.5597 6.81078 14.9328 7.56741C15.3059 8.32404 15.5 9.15637 15.5 10C15.5 10.8436 15.3059 11.676 14.9328 12.4326C14.5597 13.1892 14.0175 13.8499 13.3482 14.3634C12.6789 14.877 11.9004 15.2297 11.073 15.3943C10.2456 15.5589 9.39138 15.5309 8.5765 15.3126"
      stroke="#CCF54E"
      stroke-linecap="round"
    />
  </svg>
);

export const CancelCrossIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M3 4H3.91873C4.22559 4 4.51546 4.14089 4.70504 4.38217L8.63361 9.38211C8.91852 9.74472 8.91853 10.2551 8.63362 10.6178L4.70504 15.6178C4.51546 15.8591 4.22559 16 3.91872 16H3M16 4H15.0813C14.7744 4 14.4845 4.14089 14.295 4.38217L10.3664 9.38211C10.0815 9.74472 10.0815 10.2551 10.3664 10.6178L14.295 15.6178C14.4845 15.8591 14.7744 16 15.0813 16H16"
      stroke="#FF3333"
      stroke-linejoin="round"
    />
  </svg>
);

export const SmallSatelliteIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
    <g opacity="0.6">
      <path
        d="M12.8303 4.16999L9.97638 1.31608C9.87501 1.21471 9.70996 1.21424 9.61485 1.3215C8.82774 2.20911 8.47406 3.34668 8.55329 4.46064M12.8303 4.16999L15.6842 7.02389C15.7856 7.12526 15.786 7.29023 15.6797 7.38647C14.7678 8.21256 13.7039 8.57377 12.5396 8.44698M12.8303 4.16999L14.3869 2.61342M8.55329 4.46064C8.62414 5.45665 9.04109 6.43379 9.80378 7.19649C10.5253 7.91804 11.5998 8.34463 12.5396 8.44698M8.55329 4.46064L6.82584 6.18809M12.5396 8.44698L10.8122 10.1744M6.82584 6.18809L4.38915 3.75139C4.28777 3.65002 4.12342 3.65002 4.02205 3.75139L1.09911 6.67433C0.997734 6.7757 0.997734 6.94006 1.09911 7.04143L3.5358 9.47813M6.82584 6.18809L3.5358 9.47813M3.5358 9.47813L2.84418 10.1698C2.16837 10.8456 2.16837 11.9413 2.84418 12.6171L4.3832 14.1561C5.05901 14.8319 6.15471 14.8319 6.83052 14.1561L7.52214 13.4645M10.8122 10.1744L7.52214 13.4645M10.8122 10.1744L13.2489 12.6111C13.3503 12.7125 13.3503 12.8769 13.2489 12.9782L10.3259 15.9012C10.2246 16.0025 10.0602 16.0025 9.95884 15.9012L7.52214 13.4645"
        stroke="#F0F0F0"
        stroke-linecap="round"
      />
    </g>
  </svg>
);

export const MagnetIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g opacity="0.6">
      <path
        d="M11.6519 4.94531H16.366L16.3778 12.912C16.2863 14.5693 15.5636 16.1286 14.358 17.2695C13.1525 18.4104 11.5557 19.0462 9.89594 19.0462C8.23614 19.0462 6.63939 18.4104 5.43384 17.2695C4.2283 16.1286 3.50555 14.5693 3.41413 12.912L3.40234 4.94531H8.11639L8.09871 12.4583C8.1557 12.8868 8.36636 13.28 8.69153 13.5648C9.0167 13.8496 9.43423 14.0065 9.86648 14.0065C10.2987 14.0065 10.7163 13.8496 11.0414 13.5648C11.3666 13.28 11.5773 12.8868 11.6342 12.4583L11.6519 4.94531Z"
        stroke="#F0F0F0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M16.3664 8.48438H11.6523" stroke="#F0F0F0" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.11639 8.48438H3.40234" stroke="#F0F0F0" stroke-linecap="round" stroke-linejoin="round" />
    </g>
  </svg>
);

export const Rotate3dIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g opacity="0.6">
      <path
        d="M13.7223 6.2474C13.0365 3.52823 11.6273 1.66406 10.0007 1.66406C7.69982 1.66406 5.83398 5.3949 5.83398 9.9974C5.83398 14.5999 7.69982 18.3307 10.0007 18.3307C10.2857 18.3307 10.5648 18.2732 10.834 18.1641"
        stroke="#F0F0F0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M12.6621 11.4219L15.8404 12.9719L14.2904 16.1502" stroke="#F0F0F0" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M15.8327 12.9776C14.3293 13.7151 12.271 14.1693 9.99935 14.1693C5.39685 14.1693 1.66602 12.3034 1.66602 10.0026C1.66602 7.70177 5.39685 5.83594 9.99935 5.83594C14.031 5.83594 17.3935 7.2676 18.166 9.16927"
        stroke="#F0F0F0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export const PaletteIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g opacity="0.6">
      <path
        d="M11.2507 5.83333C11.4808 5.83333 11.6673 5.64679 11.6673 5.41667C11.6673 5.18655 11.4808 5 11.2507 5C11.0205 5 10.834 5.18655 10.834 5.41667C10.834 5.64679 11.0205 5.83333 11.2507 5.83333Z"
        fill="#F0F0F0"
        stroke="#F0F0F0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5827 9.16927C14.8128 9.16927 14.9993 8.98272 14.9993 8.7526C14.9993 8.52249 14.8128 8.33594 14.5827 8.33594C14.3526 8.33594 14.166 8.52249 14.166 8.7526C14.166 8.98272 14.3526 9.16927 14.5827 9.16927Z"
        fill="#F0F0F0"
        stroke="#F0F0F0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.08268 6.66927C7.3128 6.66927 7.49935 6.48272 7.49935 6.2526C7.49935 6.02249 7.3128 5.83594 7.08268 5.83594C6.85256 5.83594 6.66602 6.02249 6.66602 6.2526C6.66602 6.48272 6.85256 6.66927 7.08268 6.66927Z"
        fill="#F0F0F0"
        stroke="#F0F0F0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.41667 10.8333C5.64679 10.8333 5.83333 10.6468 5.83333 10.4167C5.83333 10.1865 5.64679 10 5.41667 10C5.18655 10 5 10.1865 5 10.4167C5 10.6468 5.18655 10.8333 5.41667 10.8333Z"
        fill="#F0F0F0"
        stroke="#F0F0F0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99935 1.66406C5.41602 1.66406 1.66602 5.41406 1.66602 9.9974C1.66602 14.5807 5.41602 18.3307 9.99935 18.3307C10.771 18.3307 11.3727 17.7091 11.3727 16.9241C11.3727 16.5599 11.2227 16.2282 11.0085 15.9866C10.7668 15.7457 10.6435 15.4432 10.6435 15.0491C10.6404 14.8657 10.6742 14.6835 10.7429 14.5134C10.8116 14.3433 10.9139 14.1889 11.0436 14.0591C11.1733 13.9294 11.3278 13.8272 11.4979 13.7584C11.6679 13.6897 11.8501 13.6559 12.0335 13.6591H13.6968C16.2393 13.6591 18.326 11.5732 18.326 9.03073C18.3035 5.0074 14.5502 1.66406 9.99935 1.66406Z"
        stroke="#F0F0F0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export const MinimizeIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M3 13.0001L3 12.6762C3 12.2344 3.35817 11.8762 3.79999 11.8762L8.04979 11.8762L12.2 11.8762C12.6418 11.8762 13 12.2344 13 12.6762L13 13.0001"
      stroke="#F0F0F0"
      stroke-width="0.8"
      stroke-linejoin="round"
    />
  </svg>
);

export const CloseModalIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M2.40039 3.20312H3.13537C3.38086 3.20312 3.61276 3.31583 3.76443 3.50886L6.90728 7.50881C7.13521 7.7989 7.13521 8.20724 6.90729 8.49733L3.76443 12.4974C3.61276 12.6904 3.38086 12.8031 3.13537 12.8031H2.40039M12.8004 3.20312H12.0654C11.8199 3.20312 11.588 3.31583 11.4364 3.50886L8.2935 7.50881C8.06557 7.7989 8.06557 8.20724 8.2935 8.49733L11.4364 12.4974C11.588 12.6904 11.8199 12.8031 12.0654 12.8031H12.8004"
      stroke="#F0F0F0"
      stroke-width="0.8"
      stroke-linejoin="round"
    />
  </svg>
);

export const PlusIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M2.50391 6H9.50391" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6 2.5V9.5" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const WhiteCrossIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M2.40039 3.20312H3.13537C3.38086 3.20312 3.61276 3.31583 3.76443 3.50886L6.90728 7.50881C7.13521 7.7989 7.13521 8.20724 6.90729 8.49733L3.76443 12.4974C3.61276 12.6904 3.38086 12.8031 3.13537 12.8031H2.40039M12.8004 3.20312H12.0654C11.8199 3.20312 11.588 3.31583 11.4364 3.50886L8.2935 7.50881C8.06557 7.7989 8.06557 8.20724 8.2935 8.49733L11.4364 12.4974C11.588 12.6904 11.8199 12.8031 12.0654 12.8031H12.8004"
      stroke="#F0F0F0"
      stroke-width="0.8"
      stroke-linejoin="round"
    />
  </svg>
);

export const MiniDropdownIconOpened: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g filter="url(#filter0_b_6668_9081)">
      <rect width="16" height="15.3333" transform="translate(0 0.335938)" fill="#090909" fill-opacity="0.01" />
      <path
        d="M8.68689 9.31572C8.49162 9.51098 8.17504 9.51098 7.97978 9.31572L5.85355 7.18949C5.53857 6.87451 5.76165 6.33594 6.20711 6.33594L10.4596 6.33594C10.905 6.33594 11.1281 6.87451 10.8131 7.18949L8.68689 9.31572Z"
        fill="#F0F0F0"
      />
    </g>
  </svg>
);

export const MiniDropdownIconClosed: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g filter="url(#filter0_b_6668_7009)">
      <rect width="16" height="16" fill="black" fill-opacity="0.01" />
      <path
        d="M9.31376 7.64384C9.50903 7.8391 9.50903 8.15569 9.31376 8.35095L7.18754 10.4772C6.87256 10.7922 6.33398 10.5691 6.33398 10.1236L6.33398 5.87117C6.33398 5.42572 6.87256 5.20263 7.18754 5.51762L9.31376 7.64384Z"
        fill="#F0F0F0"
      />
    </g>
  </svg>
);

export const TrailWithSplit: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="33" viewBox="0 0 17 33" fill="none">
    <path d="M8 32L8.00001 0L8.00001 12C8.00001 14.2091 9.79087 16 12 16L16 16" stroke="#4F4F4F" stroke-linecap="round" />
  </svg>
);

export const TrailWithoutSplit: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="34" viewBox="0 0 16 34" fill="none">
    <path d="M8 1L8 33" stroke="#4F4F4F" stroke-linecap="round" />
  </svg>
);

export const ThreeDModelIcon: React.FC = () => (<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.81475 1.07226C6.55469 0.67383 7.44531 0.67383 8.18525 1.07226L12.6852 3.49534C13.495 3.93137 14 4.77681 14 5.69651V7.87731C13.6992 7.63619 13.3628 7.43763 13 7.29076V5.69651C13 5.66801 12.9992 5.63963 12.9976 5.61139L7.4753 8.2947V14.1531C7.55597 14.1262 7.63489 14.0922 7.71115 14.0511L8.48839 13.6326C8.72328 13.9011 8.9935 14.1379 9.29179 14.3358L8.18525 14.9316C7.44531 15.33 6.55469 15.33 5.81475 14.9316L1.31475 12.5085C0.504976 12.0725 0 11.227 0 10.3073V5.69651C0 4.77681 0.504975 3.93137 1.31475 3.49534L5.81475 1.07226ZM6.28885 1.95273C6.73281 1.71367 7.26718 1.71367 7.71115 1.95273L12.2111 4.37581C12.3638 4.45802 12.4984 4.56448 12.6113 4.68896L6.97531 7.42848L1.443 4.63216C1.54391 4.53192 1.65995 4.44522 1.78885 4.37581L6.28885 1.95273ZM1 10.3073V5.52786L6.4753 8.29469V14.1357C6.41177 14.1119 6.34947 14.0838 6.28885 14.0511L1.78885 11.628C1.30299 11.3664 1 10.8592 1 10.3073Z" fill="#F0F0F0" />
  <path d="M11.7317 8.60276C11.7021 8.57038 11.6661 8.54452 11.626 8.52683C11.5858 8.50914 11.5425 8.5 11.4986 8.5C11.4547 8.5 11.4113 8.50914 11.3712 8.52683C11.3311 8.54452 11.295 8.57038 11.2654 8.60276L9.58048 10.4459C9.52497 10.508 9.49619 10.5895 9.50041 10.6726C9.50462 10.7558 9.54148 10.8339 9.60298 10.89C9.66448 10.9462 9.74565 10.9757 9.82883 10.9723C9.91202 10.9689 9.9905 10.9328 10.0472 10.8719L11.1829 9.62905V13.7766C11.1829 13.8604 11.2162 13.9407 11.2754 14C11.3347 14.0593 11.415 14.0925 11.4988 14.0925C11.5826 14.0925 11.6629 14.0593 11.7222 14C11.7814 13.9407 11.8147 13.8604 11.8147 13.7766V9.62989L12.9508 10.8727C13.0074 10.9346 13.0862 10.9714 13.17 10.9751C13.2537 10.9788 13.3355 10.9491 13.3973 10.8925C13.4592 10.8359 13.496 10.7571 13.4997 10.6733C13.5034 10.5896 13.4737 10.5078 13.4171 10.4459L11.7317 8.60276Z" fill="#F0F0F0" />
</svg>);
