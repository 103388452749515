//@ts-nocheck
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './groundStationanalysisReport.css'
import { CloseArrow, OpenArrow, ZoomInIcon } from '../../commonMMSvgs/MissionModellingSvgs';
import ModalDropdown from '../../commonComponents/ModalDropdown';

const GroundContactAnalysisReport = ({ mm_viewer }) => {
    const groundStationAnalysisReport = useSelector(state => state.missionModelling.groundStationAnalysisReport)

    const [reportList, setReportList] = useState([])
    const [satList, setSatList] = useState([])
    const [gsList, setGsList] = useState([])


    const [openSection, setOpenSection] = useState({
        input: true,
        output: true
    })


    const handleSatelliteChange = (satellite, index) => {

        const updatedReportList = [...reportList];
        updatedReportList[index] = (satellite.label === 'All' ? satellite.value : satellite.value.allGSAnalysisReport);
        setReportList(updatedReportList);

        let selectedGs = [{ label: 'All', value: satellite.label === 'All' ? satellite.value : satellite.value.allGSAnalysisReport }]

        if (satellite.label === "All") {
            satellite?.value?.perGroundStationReport?.map((groundStation, index) => {
                selectedGs.push({ label: groundStation.groundStationName, value: groundStation })
            })
        }

        satellite?.value?.perGroundStationAnalysisReport?.map((groundStation, index) => {
            selectedGs.push({ label: groundStation.groundStationName, value: groundStation })
        })

        const updatedGsList = [...gsList];
        updatedGsList[index] = selectedGs
        setGsList(updatedGsList);
    }

    const handleGroundStationChange = (groundStation, index) => {
        const updatedReportList = [...reportList];
        updatedReportList[index] = (groundStation.label === 'All' ? groundStation.value : groundStation.value.analysisReport);
        setReportList(updatedReportList);
    }


    useEffect(() => {
        if (groundStationAnalysisReport?.report?.length > 0) {
            let allReport = []
            let allSatellite = []
            let allGsList = []

            groundStationAnalysisReport?.report?.map((report, index) => {
                if (report.type === 'Constellation') {
                    report.groups?.map((group, groupIndex) => {
                        allReport.push(group.allSatellitesAnalysisReport)
                        let satelliteList = [{ label: "All", value: group.allSatellitesAnalysisReport }]
                        let groundStationList = [{ label: "All", value: "All" }]

                        group?.satellites?.map((satellite, satIndex) => {
                            satelliteList.push({ label: satellite.satelliteName, value: satellite })
                        })

                        allSatellite.push(satelliteList)

                        group?.allSatellitesAnalysisReport?.perGroundStationReport?.map((gs, index) => {
                            groundStationList.push({ label: gs.groundStationName, value: gs })
                        })

                        allGsList.push(groundStationList)
                    })
                } else {
                    let satelliteList = [{ label: report?.satelliteName, value: report }]
                    allReport.push(report?.allGSAnalysisReport)
                    let groundStationList = [{ label: "All", value: report?.allGSAnalysisReport }]
                    report?.perGroundStationAnalysisReport?.map((gs, index) => {
                        groundStationList.push({ label: gs?.groundStationName, value: gs })
                    })
                    allGsList.push(groundStationList)
                    allSatellite.push(satelliteList)
                }
            })

            setReportList(allReport)
            setSatList(allSatellite)
            setGsList(allGsList)
        }
    }, [groundStationAnalysisReport])



    return (
        <div className='gs_report_section' >
            <div className='report_title_section' >
                <div className='date_title' >
                    <div className='report_header_section' >
                        {"Ground Contact Analysis"}
                    </div>
                </div>
                {/* <div className='zomm_in_icon'>
                    <ZoomInIcon />
                </div> */}
            </div>
            < div className='ouput_section' >
                <div className='output_header_block' onClick={() => {
                    setOpenSection({
                        ...openSection,
                        input: !openSection.input
                    })
                }}>
                    <span>
                        Input
                    </span>
                    {openSection.input ? <OpenArrow /> : <CloseArrow />}
                </div>
                {
                    openSection?.input && <div className='overview_section' >
                        < div className='input_object_section' >
                            <div className='input_sections' >
                                <span>
                                    {groundStationAnalysisReport?.input?.totalSatellites}
                                </span>
                                <span>
                                    Satellites
                                </span>
                            </div>
                            < div className='verticle_devider' > </div>
                            < div className='input_sections' >
                                <span>
                                    {groundStationAnalysisReport?.input?.totalConstellations}
                                </span>
                                <span>
                                    Constellations
                                </span>
                            </div>
                            < div className='verticle_devider' > </div>
                            < div className='input_sections' >
                                <span>
                                    {groundStationAnalysisReport?.input?.totalGroundStations}
                                </span>
                                <span>
                                    Ground Stations
                                </span>
                            </div>
                        </div>

                    </div>
                }
            </div>
            < div className='ouput_section' >
                <div className='output_header_block' onClick={() => {
                    setOpenSection({
                        ...openSection,
                        output: !openSection.output
                    })
                }}>
                    <span>
                        Output
                    </span>
                    {openSection.output ? <OpenArrow /> : <CloseArrow />}
                </div>
                {
                    openSection.output &&
                    <div className='w-100 h-500' style={{ overflowY: "scroll" }
                    }>
                        {groundStationAnalysisReport?.report?.length > 0 && groundStationAnalysisReport?.report?.map((report, index) => (
                            <div className='overview_section' >
                                <div className='gs_sat_gs_alignment' >
                                    <span>{report?.type === 'Constellation' ? report?.constellationName : report?.satelliteName} </span>
                                    < div className='modal_alignment_dropdown' >
                                        <ModalDropdown
                                            options={satList[index]}
                                            handleSelectedOption={(value) => {
                                                handleSatelliteChange(value, index)
                                            }}
                                            previousOption={'All'}
                                        />
                                        <ModalDropdown
                                            options={gsList[index]}
                                            previousOption={'All'}
                                            handleSelectedOption={(gsValue) => {
                                                handleGroundStationChange(gsValue, index)
                                            }}
                                        />
                                    </div>

                                </div>
                                <div>
                                    Overview
                                </div>
                                < div className='overview_detail_section' >
                                    <div className='overview_setion_item' >
                                        <span>
                                            Total Contact Time
                                        </span>
                                        <span>
                                            {reportList[index]?.['totalAccessTime']} min
                                        </span>
                                    </div>
                                    < div className='overview_setion_item' >
                                        <span>
                                            Avg.Time Per Contact
                                        </span>
                                        <span>
                                            {reportList[index]?.['avgAccessTime']} min
                                        </span>
                                    </div>
                                    {
                                        reportList[index]?.['totalContacts'] && <div className='overview_setion_item'>
                                            <span>
                                                Total Contacts
                                            </ span >
                                            <span>
                                                {reportList[index]?.['totalContacts']}
                                            </span>
                                        </div>}
                                </div>
                            </div>
                        ))}

                    </div>
                }
            </div>
        </div >
    );
};

export default GroundContactAnalysisReport;
