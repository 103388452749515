import { ContactStation } from "../../../hooks/useGroundContact"
import { calculate_duration, get_formated_time } from "../../../utils"

export const NavigationButton = [
    {
        label: 'Onboard Schedule',
        name: 'plan-onboard-schedule',
        used_by: ['operate', 'simulation', 'summary']
    },
    {
        label: 'Control Schedule',
        name: 'plan-control-schedule',
        used_by: ['']
    },
    {
        label: 'Ground Contacts',
        name: 'plan-ground-contact',
        used_by: ['operate', 'simulation', 'summary']
    },
]

export const get_navigator_button_by_dashboard = (dashboard: string) => {
    return NavigationButton.filter(item => item.used_by.includes(dashboard))
}


export const get_contact_pass_details = (contact_type: string, contact: ContactStation, current_time: number) => {
    const pass_details: {
        time: string;
        duration: string;
        remaining: string
    } = {
        time: '',
        duration: '',
        remaining: '00:00:00'
    }
    if (!contact) return pass_details
    if (contact_type === 'Ongoing') {
        pass_details.time = get_formated_time(contact?.['startTime'])
        pass_details.duration = calculate_duration(contact?.['startTime'], contact?.['endTime'])
        pass_details.remaining = get_remaining_time(current_time, contact?.['endTime'])
    } else if (contact_type === 'Upcoming') {
        pass_details.time = get_formated_time(contact?.['startTime'])
        pass_details.duration = calculate_duration(contact?.['startTime'], contact?.['endTime'])
        pass_details.remaining = get_remaining_time(current_time, contact?.['startTime'])
    } else if (contact_type === 'Previous') {
        pass_details.time = get_formated_time(contact?.['endTime'])
        pass_details.duration = calculate_duration(contact?.['startTime'], contact?.['endTime'])
        pass_details.remaining = '00:00:00'
    }
    return pass_details
}

export const get_remaining_time = (start_time: number, end_time: number) => {
    const remainingTime = (end_time * 1000) - (start_time * 1000)
    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
    const paddedSeconds = seconds.toString().padStart(2, '0');
    return paddedHours === '00' ? `${paddedMinutes}:${paddedSeconds}` : `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}