import useViewer from '../hooks/useViewer';
import './PreviewSlider.css'
import { useState, useEffect } from 'react';
import { NextSvg, PlayPauseSvg, PreviousSvg } from './Svgs';

const PreviewSlider = () => {

    const { viewer } = useViewer()
    const [value, setValue] = useState(() => {
        const storedValue = sessionStorage.getItem("multiplierValue");
        return storedValue ? Number(storedValue) : 1;
    });
    useEffect(() => {
        sessionStorage.setItem("multiplierValue", value.toString()); // Save the value to sessionStorage whenever it changes
    }, [value]);
    const [play, setPlay] = useState(true);

    useEffect(() => {
        if (viewer) {
            const onViewerReady = () => {
                viewer.clock.multiplier = value;
                viewer.scene.postRender.removeEventListener(onViewerReady); // Remove this listener after setting
            };
            viewer.scene.postRender.addEventListener(onViewerReady);  // After rendering viewer we will set the multiplier
            return () => {
                viewer.scene.postRender.removeEventListener(onViewerReady);
            };
        }
    }, [viewer, value]);

    const updateClockMultiplier = (newValue) => {
        if (viewer) {
            viewer.clock.multiplier = newValue;
        }
    };

    const click_next = () => {
        if (!viewer || value === 32) return;
        let newValue;
        switch (value) {
            case 1:
                newValue = 2;
                break;
            case 2:
                newValue = 8;
                break;
            case 8:
                newValue = 32;
                break;
            default:
                newValue = 1;
                break;
        }
        setPlay(true)
        setValue(newValue);
        updateClockMultiplier(newValue);
    };

    const click_previous = () => {
        if (!viewer || value === -32) return;

        let newValue;
        switch (value) {
            case 1:
                newValue = -2;
                break;
            case -2:
                newValue = -8;
                break;
            case -8:
                newValue = -32;
                break;
            default:
                newValue = 1;
                break;
        }
        setPlay(true)
        setValue(newValue);
        updateClockMultiplier(newValue);
    };

    const click_play_pause = () => {
        if (!viewer) return;
        if (play) {
            setPlay(false);
            viewer.clock.multiplier = 0;
        } else {
            setPlay(true);
            viewer.clock.multiplier = value;
        }
    };

    return (
        <div className="preview__slider__container">
            <span className={`left__count__container ${value < 0 && 'show__container'}`}>{Math.abs(value)}x</span>
            <div className='buttons__container'>
                <button className='previous__button' onClick={click_previous}>
                    <PreviousSvg active={value <= -32 ? true : false} />
                    <PreviousSvg active={value <= -8 ? true : false} />
                    <PreviousSvg active={value <= -2 ? true : false} />
                </button>
                <button className='play_pause__buton' onClick={click_play_pause}>
                    <PlayPauseSvg state={play ? 'play' : 'pause'} />
                </button>
                <button className='next__button' onClick={click_next}>
                    <NextSvg active={value >= 2 ? true : false} />
                    <NextSvg active={value >= 8 ? true : false} />
                    <NextSvg active={value >= 32 ? true : false} />
                </button>
            </div>
            <span className={`right__count__container ${value > 1 && 'show__container'}`}>{value}x</span>
        </div>
    );
};

export default PreviewSlider;

