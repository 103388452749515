import React, { useEffect } from "react";
import { useState } from "react";
import "./CustomModelInfoCard.css";

import {
  CopyIcon,
  ReplaceIcon,
  TrashIcon,
  EditIcon,
  ToggleButtonInactiveIcon,
  ToggleButtonActiveIcon,
  ShowReportIcon,
  PlusIcon,
  AddItemIconPlus,
} from "../SatelliteProfileImgAssets/SatelliteProfileImgAssets";

import ModalDropdown from "../../../../MissionModelling/commonComponents/ModalDropdown";
import { useParams } from "react-router-dom";

const SpikeModelInfoCard = ({ reportProps, controlModeModalProps, controlModes }) => {

  const params = useParams();
  const { mission_name, sat_name, mission_id, sat_id } = params;

  const [isComponentInfoCardActive, setIsComponentInfoCardActive] = useState<boolean>(true);
  const [isMass, setIsMass] = useState(false);

  const InfoCardReportActions = () => (
    <div className="infoCardReportActionsDiv">
      <div>
        <CopyIcon />
      </div>
      <div>
        <ReplaceIcon />
      </div>
      <div>
        <EditIcon />
      </div>
      <div>
        <TrashIcon />
      </div>
    </div>
  );

  const massInput = {
    name: "Mass",
    isEnabled: isMass,
    isEnabledFunction: setIsMass,
    value: 2,
    unit: "g",
  };

  const fovInput = { name: "FOV", value: 9, unit: "deg" };

  const SingleInputItem = ({ inputObject }) => (
    <>
      <div className="infocard-component-properties-div">
        <div className="infocard-component-properties-sub-div">
          <span className="infocard-units-location-span">{inputObject.name}</span>
          <div className="infocard-units-location-sub-div">
            {inputObject.isEnabledFunction && (
              <button onClick={() => inputObject?.isEnabledFunction(!inputObject?.isEnabled)}>
                {inputObject?.isEnabled ? <ToggleButtonActiveIcon /> : <ToggleButtonInactiveIcon />}
              </button>
            )}
            <div className="infocard-component-togglebtn-div">
              <div className="infocard-component-value-div">
                <span className="infocard-location-value">{!inputObject?.custom ? inputObject?.orientation?.join(",") : 'Dynamic'}</span>
              </div>
              <div className="infocard-component-units-div">
                <span className="infocard-location-y-axis">{inputObject?.unit}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const orientationValues = [0, 0, 0];
  const orientationLabels = ["x", "y", "z"];

  const moiXvalues = [0, 0, 0];
  const moiXlabels = ["xx", "xy", "xz"];

  const moiYvalues = [0, 0, 0];
  const moiYlabels = ["yx", "yy", "yz"];

  const moiZvalues = [0, 0, 0];
  const moiZlabels = ["zx", "zy", "zz"];

  const ThreeInputItems = ({ values, labels }) => (
    <div className="infocard-three-inputs-div">
      {values.map((value, index) => (
        <div className="infocard-location-div">
          <div className="infocard-location-value-div">
            {/* <span className="infocard-location-value">{value}</span> */}
            <input className="infocard-location-value" type='number' value={value}></input>
          </div>
          <div className="infocard-location-x-axis-div">
            <span className={`${index === 0 ? "infocard-location-x-axis" : ""} ${index === 1 ? "infocard-location-y-axis" : ""} ${index === 2 ? "infocard-location-z-axis" : ""}`}>
              {labels[index]}
            </span>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className={`custom-model-infocard ${isComponentInfoCardActive ? "active" : ""} ${reportProps.isReportBarOpen ? "isReportOpen" : ""}`} id="create_model_infocard">
      <div className="infocard-label-div">
        <span className="infocard-component-label">{sat_name}</span>
        <InfoCardReportActions />
      </div>
      {/* <div className="infocard-properties-div">
        <div className="infocard-component-properties-div">
          <SingleInputItem inputObject={massInput} />

          <div className="infocard-units-location-value-div">
            <span className="infocard-units-location-span">Orientation</span>
            <ThreeInputItems values={orientationValues} labels={orientationLabels} />
          </div>

          <div className="infocard-units-location-value-div">
            <span className="infocard-units-location-span">MOI</span>
            <ThreeInputItems values={moiXvalues} labels={moiXlabels} />
          </div>

          <div className="infocard-units-location-value-div">
            <span className="infocard-units-location-span"></span>
            <ThreeInputItems values={moiYvalues} labels={moiYlabels} />
          </div>

          <div className="infocard-units-location-value-div">
            <span className="infocard-units-location-span"></span>
            <ThreeInputItems values={moiZvalues} labels={moiZlabels} />
          </div>
        </div>
      </div> */}
      <div className="infocard-label-div smaller">
        <span className="infocard-component-label">Control Mode</span>
        <div className="scalingButton" onClick={() => { controlModeModalProps.setIsAddControlModeModal(true) }}>
          <AddItemIconPlus />
        </div>
      </div>
      <div className="infocard-properties-div">
        {controlModes && controlModes?.map((controlMode) => (
          <SingleInputItem inputObject={controlMode} />
        ))}
      </div>
    </div>
  );
};

export default SpikeModelInfoCard;
