
import { FC, useEffect, useMemo, useState } from 'react'
import CustomSelect from '../../../../custom_components/CustomSelect'
import useContacts from '../../../../hooks/useContacts'
import { ScheduleTask } from '../../../../hooks/useOnBoardSchedule'
import MissionService from '../../../../../../service/MissionService'
import useUrlParams from '../../../../hooks/useUrlParams'

const TaskType = [
    {
        label: 'Adhoc Task',
        value: 'adhoc'
    },
    {
        label: 'Upload Scheduled',
        value: 'schedule'
    }
]

const TaskForm = () => {

    const { sat_id } = useUrlParams()
    const { allOnBoardSchedule } = useContacts()
    const [taskType, setTaskType] = useState<{ label: string, value: string } | null>(null)
    const [allTaskOptions, setAllTaskOptions] = useState<{ label: string, value: any }[]>([])
    const [selected_task, set_selected_task] = useState<{ label: string, value: any } | null>(null)
    const [selected_schedule, set_selected_schedule] = useState<{ label: string, value: ScheduleTask } | null>(null)

    const PLANED_SCHEDULE = useMemo(() => {
        return allOnBoardSchedule.Planned && allOnBoardSchedule.Planned.map((schedule) => ({ label: schedule.ScheduleName, value: schedule }))
    }, [allOnBoardSchedule])

    const get_adhoc_task = async () => {
        if (!sat_id) return
        try {
            const response = await MissionService.getAllTask(sat_id)
            const all_task: any[] = response.data
            if (!all_task) return
            const adhoc_tasks: { label: string, value: any }[] = response.data.filter((task: any) => task.taskType === "Adhoc")
                .map((task: any) => ({ label: task.taskName, value: task }))
            setAllTaskOptions(adhoc_tasks)

        } catch (error) {
            console.error(error)
        } finally {
            const selected_task = sessionStorage.getItem('selected_task') ? JSON.parse(sessionStorage.getItem('selected_task') || '{}') : null
            const selected_schedule = sessionStorage.getItem('selected_schedule') ? JSON.parse(sessionStorage.getItem('selected_schedule') || '{}') : null
            set_selected_task({
                label: selected_task?.taskName,
                value: selected_task
            })
            set_selected_schedule({
                label: selected_schedule?.ScheduleName,
                value: selected_schedule
            })
        }
    }

    useEffect(() => {
        get_adhoc_task()
    }, [sat_id])



    return (
        <div className="left__panel__body__form__section__container">
            <div className="left__panel__body__input__option__container">
                <span className="input__label">Task Type</span>
                <div className='input__box__container'>
                    <CustomSelect options={TaskType} value={taskType} onChange={(e) => {
                        setTaskType(e)
                        sessionStorage.setItem('taskType', e.value)
                    }} placeholder='Select Task Type' disabled_search={true} />
                    <span className='input__feedback'></span>
                </div>
            </div>
            {taskType?.value === 'adhoc' &&
                <div className="left__panel__body__input__option__container">
                    <span className="input__label">Task</span>
                    <div className='input__box__container'>
                        <CustomSelect options={allTaskOptions} value={selected_task} onChange={(e) => {
                            set_selected_task(e)
                            sessionStorage.setItem('selected_task', JSON.stringify(e.value))
                        }} disabled_search={true} />
                        <span className='input__feedback'></span>
                    </div>
                </div>
            }
            {taskType?.value === 'schedule' &&
                <div className="left__panel__body__input__option__container">
                    <span className="input__label">Schedule</span>
                    <div className='input__box__container'>
                        <CustomSelect options={PLANED_SCHEDULE} value={selected_schedule} onChange={(e) => {
                            set_selected_schedule(e)
                            sessionStorage.setItem('selected_schedule', JSON.stringify(e.value))
                        }} disabled_search={true} />
                        <span className='input__feedback'></span>
                    </div>
                </div>
            }
        </div>
    )
}

export default TaskForm