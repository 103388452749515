import { Fragment, useEffect, useRef } from "react";
import './ContactTimeline.scss'
import ContactTimeFrame from "./ContactTimeFrame";
import GroundContact from "../contact/GroundContact";
import CustomLoader from "../../../../custom_components/CustomLoader";
import useTimeline from "../../../../hooks/useTimeline";

const GroundContactDetails = () => {

    const containerRef = useRef<HTMLDivElement>(null);
    const { loading, set_window_width } = useTimeline()

    useEffect(() => {
        const container = containerRef.current;
        if (container instanceof HTMLElement) {
            const width = container.offsetWidth;
            set_window_width(width - 32);
        }
    }, [containerRef.current])

    return (
        <div className='contact__container hud__ground__contact__container' ref={containerRef}>
            {loading &&
                <div className='container__loading'>
                    <CustomLoader loading={loading} />
                </div>
            }
            {!loading &&
                <Fragment>
                    <ContactTimeFrame />
                    <GroundContact />
                </Fragment>
            }
        </div>

    )
}

export default GroundContactDetails