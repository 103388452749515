
import { useState } from 'react';
import CustomToolTip from '../../CommonComponents/CustomToolTip'
import { UploadSvg } from '../components/center/planning/Svgs'
import "./UploadSchedule.css"
import useContacts from '../hooks/useContacts';
import { toast } from 'react-toastify';
const UploadScheduleButton = () => {
    const { import_schedule } = useContacts()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any | null>(null);
    const [selectedDropFile, setSelectedDropFile] = useState<File | null>(null);


    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedFile(null); // Clear file when closing the modal
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const uploadFile = e.target.result;
                // Do something with the file content
                const blob = new Blob([uploadFile], { type: "multipart/form-data" });
                const file_data = new FormData();
                file_data.append("file", blob, file.name);

                setSelectedFile({
                    file_data: file_data!,
                    name: file.name,

                })
                setSelectedDropFile(null)

            };
            reader.readAsText(file);
        } else {
            toast.error("Invalid file format")
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files?.[0];
        if (file) {
            setSelectedDropFile(file);
            setSelectedFile(null);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleImport = () => {
        if (selectedFile) {
            // Add file upload logic here
            import_schedule(selectedFile?.file_data, selectedFile?.name).then((result) => {
                toast.success('Schedule generated', { toastId: '0' })
                closeModal();
            }).catch((error) => {
                console.error('Schedule generation failed:', error);
                toast.error(error?.response?.data, { toastId: '0' })
            }).finally(() => {
                // setLoading(false)
            })
        }
    };

    return (
        <>
            <CustomToolTip title='Upload Schedule' placement='bottom'>
                <button onClick={openModal}>
                    <i className="fe fe-upload fs-18 import-schedule-button"></i>
                </button>
            </CustomToolTip>

            {isModalOpen && (
                <div className="upload-modal-overlay" onClick={closeModal}>
                    <div
                        className="upload-modal-container"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="upload-modal-header">
                            <h2>Upload Schedule</h2>
                            <button className="upload-modal-close" onClick={closeModal}>
                                &times;
                            </button>
                        </div>

                        <div className="upload-modal-content">
                            {/* Drag & Drop Zone */}
                            <div
                                className="upload-drop-zone"
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                {selectedDropFile ? selectedDropFile.name : "Drag & Drop file here"}
                            </div>

                            {/* Select from Device */}
                            <label className="upload-device-zone">
                                {selectedFile ? selectedFile.name : "Select from Device"}
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={handleFileSelect}
                                />
                            </label>
                        </div>

                        <div className="upload-modal-footer">
                            <button className="upload-cancel-btn" onClick={closeModal}>
                                Cancel
                            </button>
                            <button
                                className="upload-import-btn"
                                onClick={handleImport}
                                disabled={!selectedFile}
                            >
                                Import
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default UploadScheduleButton