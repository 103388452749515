import { ContactStation } from "../../../../hooks/useGroundContact";
import { get_formated_time } from "../../../../utils";

interface Props {
    open: boolean;
    upcommingContacts: ContactStation[];
    selectedContact: ContactStation | null;
    setSelectedContact: (contact: ContactStation) => void
}

const UpcommingContactsModal: React.FC<Props> = ({ open, upcommingContacts, selectedContact, setSelectedContact }) => {

    return (
        <div className={`overlay__modal ${open && 'active'}`}>
            <div className='modal__container'>
                {/* <div className='modal__header'>
                                <div className='search__icon'>
                                    <SearchSvg />
                                </div>
                                <input />
                            </div> */}
                <div className='modal__body'>
                    {upcommingContacts.map((station, index) => {
                        return (
                            <button className={`contact__details__container ${selectedContact?.['uniqueId'] === station?.['uniqueId'] && 'selected'}`}
                                onClick={() => { setSelectedContact(station) }}
                                key={index}
                            >
                                <div className='left__contact__details'>
                                    <span>{`${station?.groundStationName}, ${station?.groundStationProperties?.country}`}</span>
                                    <span>{station?.groundStationProviderName}</span>
                                </div>
                                <div className='right__contact__details'>
                                    <span>{get_formated_time(station?.['startTime'])}</span>
                                    <span>{station?.['bandInfo']?.['bandName']}</span>
                                </div>
                            </button>
                        )
                    })}
                </div>
                {/* 
                    <div className='modal__footer'>
                        <button className='modal__footer__btn'>Any Contact</button>
                    </div> 
                */}
            </div>
        </div>
    )
}

export default UpcommingContactsModal