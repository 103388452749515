import { useEffect, useState } from "react";
import './Schedule.scss'
import { CollapseSvg, GroundSvg, PinSvg } from "../Svgs";
import Modal from "../Modal";
import useModals from "../../../../hooks/useModals";
import { Modals } from "../../../../providers/ModalProvider";

const GroundSchedule = () => {
    const [expand, setExpand] = useState(false);
    const { open_modal, close_modal, pinned_modal, get_modal_details } = useModals()
    const modal: Modals = get_modal_details('ground_schedule')

    useEffect(() => {
        const close_open_modal = () => {
            if (!modal.open) return
            else if (modal.open && modal.pinned) return
            else {
                close_modal('ground_schedule')
            }
        }
        window.addEventListener('click', close_open_modal)
        return () => {
            window.removeEventListener('click', close_open_modal)
        }
    }, [])


    return (
        <Modal
            open={modal.open ? true : false}
            pinned={modal.current_modal === 'ground_schedule' && modal.pinned}
            disabled={modal.current_modal === 'ground_schedule' && modal.pinned}
            class_name={`${modal.current_modal === 'ground_schedule' ? 'current__modal' : ''}`}
        >
            <div className={`schedule__panel__container ${expand && 'expand'} `}
                onClick={(event: any) => {
                    event.stopPropagation()
                    open_modal('ground_schedule')
                }}>
                <div className="schedule__panel__header">
                    <div className=" d-flex align-items-center gap-2">
                        <GroundSvg />
                        <span className="panel__header__title">Gound Schedule</span>
                    </div>
                    <div className=" d-flex align-items-center">
                        <button className="panel__colapse__btn" onClick={(event: any) => {
                            event.stopPropagation()
                            setExpand(!expand)
                        }}>
                            <CollapseSvg />
                        </button>
                        <button className={`panel__pin__btn ${modal.pinned ? 'pinned' : ''}`}
                            onClick={(event: any) => {
                                event.stopPropagation()
                                pinned_modal('ground_schedule')
                            }}>
                            <PinSvg />
                        </button>
                        <button className={`panel__close__btn`}
                            onClick={(event: any) => {
                                event.stopPropagation()
                                close_modal('ground_schedule')
                            }}>
                            <i className="fe fe-x fs-16" />
                        </button>
                    </div>
                </div>
                <div className="schedule__panel__body"></div>
            </div>
        </Modal>
    )
}

export default GroundSchedule