import { useContext } from "react"
import { ModalContext } from "../providers/ModalProvider";


const useModals = () => {
    const context = useContext(ModalContext);

    if (context === undefined) {
        throw new Error('useError must be used within a ErrorProvider');
    }
    return context
}

export default useModals