import { createContext, Fragment, ReactNode, useState } from 'react'
import Console from '../components/center/modals/console/Console'
import OnBoardschedule from '../components/center/modals/schedule/OnBoardschedule'
import GroundSchedule from '../components/center/modals/schedule/GroundSchedule'
import Telemetry from '../components/center/modals/telemetry/Telemetry'
import FileQueue from '../components/center/modals/files/FileQueue'

interface ModalContextType {
  modals: Modals[],
  open_modal: (modal: ModalType) => void,
  close_modal: (modal: ModalType) => void,
  pinned_modal: (modal: ModalType) => void,
  get_modal_details: (modal: ModalType) => Modals,
}

export type Modals = {
  name: string,
  open: boolean,
  pinned: boolean,
  current_modal: string
}

export type ModalType = 'console' | 'filequeue' | 'ground_schedule' | 'onboard_schedule' | 'telemetry'

export const ModalContext = createContext<ModalContextType | undefined>(undefined);

interface ModalProviderProps {
  children: ReactNode;
}
const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {

  const [modals, set_Modals] = useState<Modals[] | []>([])

  const get_modal_details = (modal: ModalType) => {
    const selected_modal = modals.find((m: Modals) => m.name === modal)
    if (!selected_modal) {
      return {
        name: modal,
        open: false,
        pinned: false,
        current_modal: ''
      }
    } else {
      return selected_modal
    }
  }

  const close_modal = (modal: ModalType) => {
    if (!modals.length) return
    const filter_modals = modals.filter((m: Modals) => m.name !== modal)
    set_Modals(filter_modals)
  }

  const pinned_modal = (modal: ModalType) => {
    const filter_modals = modals.filter((m: Modals) => m.name !== modal)
    let selected_modal: Modals | undefined = modals.find((m: Modals) => m.name === modal)
    if (selected_modal) {
      selected_modal.pinned = !selected_modal.pinned
      selected_modal.current_modal = modal
    } else {
      selected_modal = {
        name: modal,
        open: true,
        pinned: true,
        current_modal: modal
      }
    }
    const updated_modals = [...filter_modals, selected_modal].map((m: Modals) => {
      if (m.name !== modal) {
        m.current_modal = ''
        return m
      } else {
        return m
      }
    })
    set_Modals(updated_modals)
  }

  const open_modal = (modal: ModalType) => {
    const filter_modals = modals.filter((m: Modals) => m.name !== modal)
    let selected_modal: Modals | undefined = modals.find((m: Modals) => m.name === modal)
    if (selected_modal) {
      selected_modal.open = true
      selected_modal.current_modal = modal
    } else {
      selected_modal = {
        name: modal,
        open: true,
        pinned: false,
        current_modal: modal
      }
    }
    const updated_modals = [...filter_modals, selected_modal].map((m: Modals) => {
      if (m.name !== modal) {
        m.current_modal = ''
        return m
      } else {
        return m
      }
    })
    set_Modals(updated_modals)
  }

  return (
    <ModalContext.Provider value={{ modals, open_modal, close_modal, pinned_modal, get_modal_details }}>
      <Console />
      <OnBoardschedule />
      <GroundSchedule />
      <Telemetry />
      <FileQueue />
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider