// @ts-nocheck
import { useDispatch, useSelector } from 'react-redux';
import { AddIcon, NormalGeoFeature, GeofenceSvg, GroundTargetSvg, MenuIcon } from '../../../commonMMSvgs/MissionModellingSvgs';
import './geoFeatureCreation.css'
import ActionsMenu from '../../../commonComponents/ActionsMenu';
import { useEffect, useState } from 'react';
import MissionModellingService from '../../../MissionModelling.service';
import { useParams } from 'react-router-dom';
import ModalInput from '../../../commonComponents/ModalInput';
import GeoFeatureCreationTree from './GeoFeatureCreationTree';
import * as mapActions from '../../../../../store/MapSlice';
import * as mmActions from '../../../../../store/MissionModellingSlice';
import { gs_visualization_presentation } from '../GroundStationCreation/Utils';

const GeoFeatureCreation = ({ mm_viewer, openedCreationModal }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const { mission_id } = params;
    const allAoisList = useSelector(state => state.missionModelling.aoiCollection)
    const aois = useSelector(state => state.missionModelling.aois);
    const allOrbitData = useSelector(state => state.missionModelling.allOrbit)

    const openGeoFeatureView = () => {
        dispatch(mmActions.setEditAreaOfInterestObject({}))
        dispatch(mmActions.setGeoFeatureView(true));
    };

    useEffect(() => {
        if (aois?.length > 0 && mm_viewer) {
            gs_visualization_presentation().init_all_aois(aois, mm_viewer)
            gs_visualization_presentation().init_all_satellites(allOrbitData, mm_viewer, "Orbit_Object")
        }
    }, [aois, mm_viewer])

    return (
        <div className='main_geo_feature'>
            <div className='GeoFeatureCreationHeader'>
                <div className='creationState'>
                    <NormalGeoFeature />
                    <span className='headerText'>
                        GeoFeatures
                    </span>
                    <span className='headerSubText'>
                        ({aois?.length})
                    </span>
                </div>
                <div
                    className='addIcon'
                    onClick={openGeoFeatureView}
                >
                    <AddIcon />
                </div>
            </div>
            {aois?.length === 0 ?
                (
                    <div className='GeoFeatureCreationBody'>
                        <div
                            className='create_geo_feature_alignment'
                            onClick={openGeoFeatureView}
                        >
                            <AddIcon />
                            <span className='bodyText'>Create GeoFeature</span>
                        </div>
                    </div>
                ) :
                <GeoFeatureCreationTree mm_viewer={mm_viewer} openedCreationModal={openedCreationModal} />
            }
        </div>
    );
};

export default GeoFeatureCreation;
