//@ts-nocheck
import { Form } from "react-bootstrap";
import "./modalInput.css"
import { CloseSvg, SaveSvg } from "../commonMMSvgs/MissionModellingSvgs";

interface ModalInputProps { inputType?: string, isRename?: boolean, unit?: string, value: string | number, handleChange: (data: string | number) => void, disabled?: boolean, handleSave?: (data: string | number) => void, handleClose?: (data: string | number) => void, minLength?: string | number, maxLength?: string | number, }

const ModalInput = ({ inputType, isRename, unit, value, handleChange, disabled, handleSave, handleClose, minLength, maxLength }: ModalInputProps) => {

    // Normalize min and max values to ensure they are numbers or undefined
    const min = isNaN(minLength) ? undefined : Number(minLength);
    const max = isNaN(maxLength) ? undefined : Number(maxLength);

    return (
        <>
            <div className="input_modal_with_unit">
                <input
                    className={`input_modal ${unit ? `required` : `not_required`}`}
                    required
                    type={inputType?.length > 0 ? inputType : "text"}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            e.preventDefault();
                        }
                    }}
                    disabled={disabled}
                    onChange={(e) => {
                        handleChange(e.target.value)
                    }}
                    value={value}
                    {...(inputType === 'number' && min !== undefined && { min })}
                    {...(inputType === 'number' && max !== undefined && { max })}
                    {...(inputType === 'number' && { step: "0.01" })}
                />

                {unit && <div
                    className="unit_modal"
                >{unit}</div>}


            </div>

            {isRename && <div className="rename_section">
                <button disabled={!value} className="svg_icon_container__btn" onClick={handleSave}>
                    <SaveSvg />
                </button>
                <button className="svg_icon_container__btn" onClick={handleClose}>
                    <CloseSvg />
                </button>
            </div>}
        </>
    );
};

export default ModalInput;