// @ts-nocheck
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon, MinimizeIcon, MaximizeIcon } from '../commonMMSvgs/MissionModellingSvgs';
import './modalHeader.css'
import { resetIsModalMinimized, setIsModalMinimized } from '../../../store/MissionModellingSlice';

const ModalHeader = ({ icon, title, handleClose, isMinimized, setIsMinimized }) => {

    const dispatch = useDispatch();
    const isModalMinimized = useSelector(state => state.missionModelling.isModalMinimized);

    const toggleMinimizeModal = () => {
        setIsMinimized(!isMinimized)
    };

    return (
        <div className='modal_header_main_container'>
            <div className='modal_header_left_panel'>
                {icon}
                <span>
                    {title}
                </span>
            </div>

            <div className='model_header_actions_btn'>
                <div className='modal_header_right_panel'>
                    <div className='minimize_icon' onClick={() => {
                        toggleMinimizeModal();
                    }}>
                        {isMinimized ? <MaximizeIcon /> : <MinimizeIcon />}
                    </div>
                </div>

                <div className='modal_header_right_panel'>
                    <div className='close_icon' onClick={() => {
                        dispatch(resetIsModalMinimized());
                        handleClose();
                    }}>
                        <CloseIcon />
                    </div>
                </div>
            </div>

        </div>
    )
};

export default ModalHeader; 