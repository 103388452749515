// import { useState } from "react";
// import WarningBarBox from "./WarningBarBox";
// import SpikeWarningDetailsBox from "./SpikeWarningDetailsBox";

import { useDispatch, useSelector } from "react-redux";
import { ORDER_STATUS_SERVER } from "../UnifiedControlPlane.constants";
import { setHighlightedOrderAssign } from "../../../store/UnifiedControlPlaneSlice";
import { ucp_visualization_presentation } from "../Utils";

const RenderSubOrder = ({ orderAssignments, ucpViewer }) => {
  console.log("order", orderAssignments)
  const dispatch = useDispatch();
  const highlightedOrderAssign = useSelector(state => state.unifiedControlPlane.highlightedOrderAssign);

  // const [spikeWarningDetails, setSpikeWarningDetails] = useState({
  //   Order_Name: "Order_03",
  //   Fault_Message: "Fault Tolerance using ISL triggered",
  //   Spike_Fail_Message: "SPIKE_02 Task Failed",
  //   Spike_Fail_Time: "2023-10-12 11:20:45 UTC",
  //   Spike_Type: "Strip Mapping (Capture_Strip_08)",
  //   Spike_Malfunction_Type: "ADCS Malfunction",
  //   Spikes_Conditions: [
  //     { label: "SPIKE 01", condition: "Operational" },
  //     { label: "SPIKE 02", condition: "Currently Inactive" },
  //     { label: "SPIKE 03", condition: "Operational" },
  //     { label: "SPIKE 04", condition: "Operational" },
  //     { label: "SPIKE 09", condition: "Provisioned on demand" },
  //     { label: "SPIKE 11", condition: "Provisioned on demand" },
  //   ],
  // });
  // const [isSpikeWarningDetails, setIsSpikeWarningDetails] = useState(false);

  const setOrderAssignmentActive = (item) => {
    dispatch(setHighlightedOrderAssign(item));
    ucp_visualization_presentation().highlight_strip(ucpViewer, item, 'Highlight_Order_Assignment')
  };

  return (
    orderAssignments.map((item, index) => (
      <div key={index}>
        {/* {isSpikeWarningDetails && <SpikeWarningDetailsBox spikeItemWarning={spikeWarningDetails} />} */}
        <div className="subOrderTypeMainBars" onClick={() => setOrderAssignmentActive(item)}>
          <div
            className={(item.start_epoch_sec === highlightedOrderAssign.start_epoch_sec) ?
              "subOrdersTypeHeaderBar activeHeaderBarSubOrders noTopPadding" :
              "subOrdersTypeHeaderBar blackHeaderBarSubOrders noTopPadding"}
            style={{ margin: "0px", display: 'flex' }}
          >
            <button className="orderProgressDivPendingBtn" style={{ float: "left", padding: "0px" }}>
              {item.status === ORDER_STATUS_SERVER.COMPLETE && <img className="pauseIcon noTop" src={require("../ImgAssets/GreenTickSmallIcon.svg").default} alt=""/>}
              {item.status === ORDER_STATUS_SERVER.WAITING && <img className="pauseIcon noTop rotationIcon" src={require("../ImgAssets/PendingIcon.svg").default} alt=""/>}
              {item.status === ORDER_STATUS_SERVER.PENDING && <img className="pauseIcon noTop" src={require("../ImgAssets/WaitingTimeIcon.svg").default} alt=""/>}
              {item.status === ORDER_STATUS_SERVER.WARNING && <img className="pauseIcon noTop" src={require("../ImgAssets/OrangeWarningIcon.svg").default} alt=""/>}
            </button>
            <div className="orderTitleMainDiv" style={{ alignContent: 'center' }}>
              <div className="orderTitleDiv">
                <div className="subOrderTitleName grey">{item.satellite.name}&ensp;</div>
                <div className="subOrderTitleName white"> {item.name}</div>
              </div>
              <div className="orderTitleDiv">
                {item.band === 'Imager' && <img className="subOrderImg" src={require("../ImgAssets/DbIcon.svg").default} alt=""/>}
                {item.band === 'S-Band' && <img className="subOrderImg" src={require("../ImgAssets/SatelliteTabs.svg").default} alt=""/>}
                {/* {item.componentImg != "" && <img className="subOrderImg" src={item.componentImg} alt=""/>} */}
                <div className="subOrderTitleName grey">{item.band ? item.band : item?.ground_station?.name}&ensp;</div>
                <div className="subOrderTitleName yellow">{item.type}</div>
              </div>
            </div>
            {/* <button className="orderProgressDivPendingBtn" style={{ position: "relative", top: "-16px" }}>
            <img className="pauseIcon" src={require("../ImgAssets/OpenNewWindowIcon.svg").default} alt=""/>
          </button> */}
          </div>
          {/* DEMO 14th Oct - No warning stautus in the data */}
          {/* {item.status === "warning" && (
          <div className="orderRescheduledBox">
            <div style={{ width: "100%", overflow: "hidden" }}>
              <div className="orderRescheduledBoxHeader">{headerElement.spec.name} Rescheduled</div>
              <button
                className={isSpikeWarningDetails ? "orderRescheduledDetailsBtn clicked" : "orderRescheduledDetailsBtn"}
                onClick={() => setIsSpikeWarningDetails(!isSpikeWarningDetails)}
              >
                Details
              </button>
            </div>
            <div className="orderRescheduledBoxSubHeader">Fault Tolerance using ISL triggered</div>
          </div>
        )}
        {item.status === "warning" && <WarningBarBox orderSpikeItem={item} />} */}

        </div>
      </div>
    )));
};

export default RenderSubOrder;
