// @ts-nocheck
import React, { useState, useRef, useEffect, Suspense } from "react";
import { Canvas, useFrame, useLoader } from "react-three-fiber";
import { OrbitControls, Text, TransformControls } from "@react-three/drei";
import * as THREE from "three";
import Loader from "../../Layouts/Loader/Loader";

function InsideBox1(props) {
  const meshRef = useRef();
  const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
  directionalLight.position.set(10, 1, 1);
  const texture = useLoader(
    THREE.TextureLoader,
    process.env.PUBLIC_URL + props.solarImage
  );

  const textureBack = useLoader(
    THREE.TextureLoader,
    process.env.PUBLIC_URL + props.solarImageBack
  );

  const [zval, setZval] = useState(parseInt(props.firstWing));
  const [zvalG2, setZValG2] = useState(parseInt(props.secondWing));
  const [antarisPlateZval, setAntarisPlateZval] = useState(parseInt(-8));
  const [cnt, setCnt] = useState(0)
  const [grpOneCnt, setGrpOntCnt] = useState(0)
  const [grpTwoCnt, setGrpTwoCnt] = useState(0)
  const [antarisLeftPlate, setAntarisLeftPlate] = useState(-7.5)
  const [antarisLeftPlateCnt, setAntarisLeftPlateCnt] = useState(0)
  const [wings, setWings] = useState(props.wings)

  useFrame(() => {
    let grpOneZ;
    let grpTwoZ;
    let antarisPlateZ;
    let antarisLeftPlateZ;

    if (props.wings <= 6) {
      grpOneZ = 0;
      grpTwoZ = -2.58;
      antarisPlateZ = -1.79;
      antarisLeftPlateZ = -1.26;
    } else if (props.wings >= 7 && props.wings <= 16) {
      grpOneZ = 0.5;
      grpTwoZ = -3.08;
      antarisPlateZ = -2.31;
      antarisLeftPlateZ = -1.28;
    } else {
      grpOneZ = 1.4;
      grpTwoZ = -4;
      antarisPlateZ = -2.8;
      antarisLeftPlateZ = -1.31;
    }

    if (props.wings > 27) {
      grpOneZ = 1.4;
      grpTwoZ = -4;
      antarisPlateZ = -2.8;
      antarisLeftPlateZ = -2.32;
    }

    if (zval > grpOneZ) {
      let z = zval - 0.09;
      setZval(z);
    }
    else {
      setGrpOntCnt((prev) => {
        return prev + 1;
      })

    }

    if (zvalG2 < grpTwoZ) {
      let z = zvalG2 + 0.079;
      setZValG2(z);
    } else {
      setGrpTwoCnt((prev) => {
        return prev + 1;
      })
    }

    if (antarisPlateZval < antarisPlateZ) {
      let z = antarisPlateZval + 0.09;
      setAntarisPlateZval(z);
    } else {
      setCnt((prev) => {
        return prev + 1;
      })
    }

    if (antarisLeftPlate < antarisLeftPlateZ) {
      let z = antarisLeftPlate + 0.09;
      setAntarisLeftPlate(z);
    } else {
      setAntarisLeftPlateCnt((prev) => {
        return prev + 1;
      })
    }

    if (cnt === 1) {
      setAntarisPlateZval(antarisPlateZ)
    }

    if (grpOneCnt === 1) {
      setZval(grpOneZ)
    }
    if (grpTwoCnt === 1) {
      setZValG2(grpTwoZ)
    }

    if (antarisLeftPlateCnt === 1) {
      setAntarisLeftPlate(antarisLeftPlateZ)
    }

  });

  return (
    <>
      <group
        name="Solar Panel"
        rotation={[Math.PI / 2, 2, 11]}
        position={[0.45, 2.12, 0]}
        dispose={null}
      >
        <group position={[props.group_one[0].x, props.group_one[0].y, zval]} dispose={null}>
          <mesh
            ref={meshRef}
            position={[
              props.group_one[1].x,
              props.group_one[1].y,
              props.group_one[1].z,
            ]}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <boxGeometry
              args={[
                props.group_one[2].x,
                wings > 27 ? props.group_one[2].y + 1 : props.group_one[2].y,
                props.group_one[2].z,
              ]}
              attach="geometry"
            />
            <meshLambertMaterial
              attach="material"
              color="black"
              opacity={0.5}
            />
            <meshBasicMaterial map={texture} key={Math.random()} />
          </mesh>
          <mesh
            ref={meshRef}
            position={[
              props.group_one[1].x,
              props.group_one[1].y - 0.01,
              props.group_one[1].z,
            ]}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <boxGeometry
              args={[
                props.group_one[2].x,
                wings > 27 ? props.group_one[2].y + 1 : props.group_one[2].y,
                props.group_one[2].z,
              ]}
              attach="geometry"
            />
            <meshLambertMaterial
              attach="material"
              color="black"
              opacity={0.5}
            />

            <meshBasicMaterial map={textureBack} key={Math.random()} />
          </mesh>
          <mesh
            ref={meshRef}
            position={[
              props.group_one[3].x,
              props.group_one[3].y,
              props.group_one[3].z,
            ]}
            rotation={[-Math.PI / 2, 0, 0]}
          >
            <boxGeometry
              args={[
                props.group_one[4].x,
                props.group_one[4].y,
                props.group_one[4].z,
              ]}
              attach="geometry"
            />
            <meshLambertMaterial
              attach="material"
              color="black"
              opacity={0.5}
            />
            <meshBasicMaterial map={texture} key={Math.random()} />
          </mesh>
          <mesh
            ref={meshRef}
            position={[
              props.group_one[3].x,
              props.group_one[3].y - 0.01,
              props.group_one[3].z,
            ]}
            rotation={[-Math.PI / 2, 0, 0]}
          >
            <boxGeometry
              args={[
                props.group_one[4].x,
                props.group_one[4].y,
                props.group_one[4].z,
              ]}
              attach="geometry"
            />
            <meshLambertMaterial
              attach="material"
              color="black"
              opacity={0.5}
            />
            <meshBasicMaterial map={textureBack} key={Math.random()} />
          </mesh>

          <mesh
            ref={meshRef}
            position={[
              props.group_one[5].x,
              props.group_one[5].y,
              props.group_one[5].z,
            ]}
            rotation={[0, Math.PI, 0]}
          >
            <boxGeometry
              args={[
                props.group_one[2].x,
                props.group_one[2].y,
                props.group_one[2].z,
              ]}
              attach="geometry"
            />
            <meshPhysicalMaterial
              attach="material"
              color="#2D56AC"
              metalness={0.95}
              roughness={0.5}
            />
          </mesh>

          <mesh
            ref={meshRef}
            position={[
              props.group_one[7].x,
              props.group_one[7].y,
              props.group_one[7].z,
            ]}
            rotation={[0, Math.PI / 2, 0]}
          >
            <boxGeometry
              args={[
                props.group_one[8].x + 0.07,
                props.group_one[8].y,
                props.group_one[8].z,
              ]}
              attach="geometry"
            />
            <meshPhysicalMaterial
              attach="material"
              color="#2D56AC"
              metalness={1}
              roughness={0.5}
            />
          </mesh>
        </group>

        <mesh
          ref={meshRef}
          position={[
            props.group_one[6].x,
            props.group_one[6].y,
            wings > 27 ? antarisPlateZval - 1.01 : antarisPlateZval,
          ]}
          rotation={[0, Math.PI, 0]}
        >
          <boxGeometry
            args={[
              props.group_one[2].x,
              props.group_one[2].y,
              props.group_one[2].z,
            ]}
            attach="geometry"
          />
          <meshPhysicalMaterial
            attach="material"
            color="#2D56AC"
            metalness={0.95}
            roughness={0.5}
          />
          <Text
            position={[0, 0, 0.06]}
            fontSize={0.5}
            fontFamily="Montserrat"
            color="black"
            anchorX="center"
            anchorY="middle"
          >
            ANTARIS
          </Text>
        </mesh>

        <mesh
          ref={meshRef}
          position={[
            props.group_one[10].x,
            props.group_one[10].y,
            props.group_one[10].z
          ]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <boxGeometry
            args={[
              props.group_one[11].x,
              props.group_one[11].y,
              props.group_one[11].z,
            ]}
            attach="geometry"
          />
          <meshPhysicalMaterial
            attach="material"
            color="#2D56AC"
            metalness={0.95}
            roughness={0.5}
          />
        </mesh>

        <mesh
          ref={meshRef}
          position={[
            props.group_one[9].x,
            props.group_one[9].y,
            wings > 27 ? antarisLeftPlate + 1.01 : antarisLeftPlate,
          ]}
          rotation={[0, Math.PI / 2, 0]}
        >
          <boxGeometry
            args={[
              props.group_one[8].x + 0.07,
              props.group_one[8].y,
              props.group_one[8].z,
            ]}
            attach="geometry"
          />
          <meshPhysicalMaterial
            attach="material"
            color="#2D56AC"
            metalness={0.95}
            roughness={0.5}
          />
        </mesh>

        <group position={[props.group_two[0].x, props.group_two[0].y, zvalG2]}>
          <mesh
            ref={meshRef}
            position={[
              props.group_two[1].x,
              props.group_two[1].y,
              props.group_two[1].z,
            ]}
            rotation={[-Math.PI / 2, 0, 0]}
          >
            <boxGeometry
              args={[
                props.group_two[2].x,
                props.group_two[2].y,
                props.group_two[2].z,
              ]}
              attach="geometry"
            />
            <meshLambertMaterial
              attach="material"
              color="black"
              opacity={0.5}
            />
            <meshBasicMaterial map={texture} key={Math.random()} />
          </mesh>
          <mesh
            ref={meshRef}
            position={[
              props.group_two[1].x,
              props.group_two[1].y - 0.02,
              props.group_two[1].z,
            ]}
            rotation={[-Math.PI / 2, 0, 0]}
          >
            <boxGeometry
              args={[
                props.group_two[2].x,
                props.group_two[2].y,
                props.group_two[2].z,
              ]}
              attach="geometry"
            />
            <meshLambertMaterial
              attach="material"
              color="black"
              opacity={0.5}
            />
            <meshBasicMaterial map={textureBack} key={Math.random()} />
          </mesh>
          <mesh
            ref={meshRef}
            position={[
              props.group_two[3].x,
              props.group_two[3].y,
              props.group_two[3].z,
            ]}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <boxGeometry
              args={[
                props.group_two[4].x,
                props.group_two[4].y,
                props.group_two[4].z,
              ]}
              attach="geometry"
            />
            <meshLambertMaterial
              attach="material"
              color="black"
              opacity={0.5}
            />
            <meshBasicMaterial map={texture} key={Math.random()} />

            <primitive object={directionalLight} dispose={null} key={Math.random()} />
          </mesh>
          <mesh
            ref={meshRef}
            position={[
              props.group_two[3].x,
              props.group_two[3].y - 0.02,
              props.group_two[3].z + 0.01,
            ]}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <boxGeometry
              args={[
                props.group_two[4].x,
                props.group_two[4].y,
                props.group_two[4].z,
              ]}
              attach="geometry"
            />
            <meshLambertMaterial
              attach="material"
              color="black"
              opacity={0.5}
            />
            <meshBasicMaterial map={textureBack} key={Math.random()} />
            <primitive object={directionalLight} dispose={null} key={Math.random()} />
          </mesh>
        </group>
      </group>
    </>
  );
}

function CreateMesh(props) {
  const [fall, setFall] = useState(8);
  const [falling, setFalling] = useState(true);

  useFrame(() => {
    if (falling) {
      if (fall <= props.position[1]) {
        setFalling(false);
        setFall((prev) => prev + 0.1);
      }
      setFall((prev) => (prev -= 0.05));
    }
  });

  const color = new THREE.Color();
  color.setHex(Math.random() * 0xffffff);

  return (
    <mesh position={[props.position[0], fall, props.position[2]]}>
      <boxGeometry args={props.args} attach="geometry" />
      <meshPhysicalMaterial
        attach="material"
        color="GREY"
        metalness={0.7}
        roughness={0.2}
      />

    </mesh>
  );
}

const calculateMeshOrientation = (satelliteMesh) => {
  const quaternion = satelliteMesh.quaternion; // Mesh's quaternion
  const worldAxisY = new THREE.Vector3(0, 1, 0); // World Y-axis
  const worldAxisX = new THREE.Vector3(1, 0, 0); // World X-axis
  const worldAxisZ = new THREE.Vector3(0, 0, 1); // World Z-axis
  // Mesh's local axes in world space
  const meshAxisY = new THREE.Vector3(0, 1, 0).applyQuaternion(quaternion);
  const meshAxisX = new THREE.Vector3(1, 0, 0).applyQuaternion(quaternion);
  const meshAxisZ = new THREE.Vector3(0, 0, 1).applyQuaternion(quaternion);
  // Calculate angles (in degrees) between mesh's axes and world axes
  const angleWithX = meshAxisX.angleTo(worldAxisX) * (180 / Math.PI);
  const angleWithY = meshAxisY.angleTo(worldAxisY) * (180 / Math.PI);
  const angleWithZ = meshAxisZ.angleTo(worldAxisZ) * (180 / Math.PI);
}

const CommonSat = ({ containX, containY, containZ, group1, group2, u, solarImage, solarImageBack, antarisLogo, cubes }) => {
  const [rotation, setRotation] = useState(11)
  const [isStop, setIsStop] = useState(false)
  const meshRef = useRef();
  const orbitControlsRef = useRef();
  const transformControlsRef = useRef();
  useFrame(() => {
    if (!isStop) {
      setRotation((prev) => {
        if (prev < 12.1) {
          prev = prev + 0.01;
        } else {
          setIsStop(true);
        }
        return prev;
      })
      if (meshRef.current) {
        calculateMeshOrientation(meshRef.current)
      }
    }
  })
  useEffect(() => {
    const transformControls = transformControlsRef.current;
    const handleDragStart = () => {
      console.log("Drag started");
      if (orbitControlsRef?.current) {
        orbitControlsRef.current.enabled = false; // Disable OrbitControls
      }
    };
    const handleDragEnd = () => {
      console.log("Drag ended");
      if (orbitControlsRef?.current) {
        orbitControlsRef.current.enabled = true; // Enable OrbitControls
      }
    };
    if (transformControls) {
      transformControls.addEventListener("dragging-changed", (event) => {
        if (event.value) {
          handleDragStart(); // When dragging starts
        } else {
          handleDragEnd(); // When dragging ends
        }
      });
    }
    return () => {
      if (transformControls) {
        transformControls.removeEventListener("dragging-changed", handleDragStart);
        transformControls.removeEventListener("dragging-changed", handleDragEnd);
      }
    };
  }, [orbitControlsRef]);
  console.log(rotation);
  return (
    <>
      {/* TransformControls */}
      <TransformControls
        ref={transformControlsRef}
        object={meshRef.current}
        onDragStart={() => {
          // Disable OrbitControls when dragging starts
          if (orbitControlsRef.current) {
            orbitControlsRef.current.enabled = false;
          }
        }}
        onDragEnd={() => {
          // Re-enable OrbitControls when dragging ends
          if (orbitControlsRef.current) {
            orbitControlsRef.current.enabled = true;
          }
        }}
      >
        <mesh ref={meshRef} rotation={[rotation, rotation, rotation]}>
          <mesh rotation={[Math.PI / 2, 2, 11]}>
            <boxGeometry
              args={[
                containX,
                containY,
                containZ,
                containX,
                containY,
                containZ,
              ]}
              attach="geometry"
            />
            <spotLight intensity={5} position={[-1000, 1, 1000]} angle={50} />
            <meshLambertMaterial
              attach="material"
              color="#FDB813"
              transparent
              opacity={0.15}
              wireframe={true}
              lineWidth={5}
            />
            {cubes}
          </mesh>
          <InsideBox1
            group_one={group1}
            group_two={group2}
            wings={u}
            firstWing="6"
            secondWing="-8"
            solarImage={solarImage}
            solarImageBack={solarImageBack}
            antarisLogo={antarisLogo}
          />
          <ambientLight intensity={3} />
        </mesh>
      </TransformControls>
      {/* OrbitControls */}
      <OrbitControls
        ref={orbitControlsRef}
        target={[0, 0, 0]}
        enableZoom={true}
        minDistance={5} // Minimum zoom level
        maxDistance={100} // Maximum zoom level
      />
    </>
  );
}
const Common3DSat = ({ busSize, size, canvas_height, canvas_width, isPayloadChanges, cubeSize }) => {

  const yVal = useRef(0);
  let viewFinalSatellite = true;

  let u = parseInt(busSize, 10); // bus size
  let bus_u = Math.round(parseFloat(size)) < size ? Math.round(parseFloat(size)) + 1 : Math.round(parseFloat(size)); // total cubes

  if (Number.isNaN(u)) {
    viewFinalSatellite = false;
    u = bus_u;
  }

  let containX;
  let containY;
  let containZ;
  let solarImage;
  let solarImageBack;
  let antarisLogo = "/Antaris_logo.png";
  let group1 = [];
  let group2 = [];
  let camPosition = { x: -10, y: 6, z: 10 };

  switch (true) {
    case u <= 3:
      camPosition.z = 1;

      if (isPayloadChanges === 'true') {
        camPosition.x = 6;
        camPosition.y = 4;
        camPosition.z = -2;
      }
      group1.push(
        { x: 0, y: -0.25, z: 0 },
        { x: 0, y: -1, z: -0.285 },
        { x: 3, y: 1, z: 0.02 },
        { x: 0, y: -1, z: 0.73 },
        { x: 3, y: 1, z: 0.01 },
        { x: 0, y: -1.50, z: -0.76 },
        { x: 0, y: -1.75, z: -1.79 },
        { x: 1.5, y: -1.50, z: -1.26 },
        { x: 1, y: 1, z: 0.02 },
        { x: -1.5, y: -1.75, z: -1.26 },
        { x: 0, y: -2.25, z: -1.29 },
        { x: 3, y: 1, z: 0.02 }

      );
      group2.push(
        { x: 0, y: -2.25, z: -5.58 },
        { x: 0, y: 1, z: 0.285 },
        { x: 3, y: 1, z: 0.02 },
        { x: 0, y: 1, z: -0.73 },
        { x: 3, y: 1, z: 0.01 }
      );

      solarImage = "/solar3.png";
      solarImageBack = "/back_3U.png";
      containX = 3;
      containY = containZ = 1;

      break;
    case u >= 4 && u <= 6:
      camPosition.z = 3;

      if (isPayloadChanges === 'true') {
        camPosition.x = 6;
        camPosition.y = 4;
        camPosition.z = -2;
      }
      group1.push(
        { x: 0, y: 0, z: 0 },
        { x: 0, y: -0.75, z: 0.23 },
        { x: 3, y: 2, z: 0.02 },
        { x: 0, y: -0.75, z: 2.24 },
        { x: 3, y: 2, z: 0.01 },
        { x: 0, y: -1.75, z: -0.76 },
        { x: 0, y: -1.75, z: -1.79 },
        { x: 1.5, y: -1.75, z: -1.26 },
        { x: 1, y: 2, z: 0.02 },
        { x: -1.5, y: -1.75, z: -1.26 },
        { x: 0, y: -2.75, z: -1.29 },
        { x: 3, y: 1.1, z: 0.02 }
      );
      group2.push(
        { x: 0, y: -2, z: -2.58 },
        { x: 0, y: 1.25, z: -0.23 },
        { x: 3, y: 2, z: 0.02 },
        { x: 0, y: 1.25, z: -2.24 },
        { x: 3, y: 2, z: 0.01 }
      );

      solarImage = "/solar612.png";
      solarImageBack = "/back_6U.png";
      containX = 3;
      containY = 2;
      containZ = 1;
      break;
    case u >= 7 && u <= 12:
      camPosition.z = 3;

      if (isPayloadChanges === 'true') {
        camPosition.x = 6;
        camPosition.y = 4;
        camPosition.z = -2;
      }
      group1.push(
        { x: 0, y: 0.02, z: 0.5 },
        { x: 0, y: -0.75, z: 0.23 },
        { x: 3, y: 2, z: 0.02 },
        { x: 0, y: -0.75, z: 2.24 },
        { x: 3, y: 2, z: 0.01 },
        { x: 0, y: -1.75, z: -0.76 },
        { x: 0, y: -1.75, z: -2.31 },
        { x: 1.5, y: -1.75, z: -1.76 },
        { x: 2, y: 2, z: 0.02 },
        { x: -1.5, y: -1.74, z: -1.28 },
        { x: 0, y: -2.75, z: -1.28 },
        { x: 3.03, y: 2.01, z: 0.02 }

      );
      group2.push(
        { x: 0, y: -1.98, z: -3.08 },
        { x: 0, y: 1.25, z: -0.23 },
        { x: 3, y: 2, z: 0.02 },
        { x: 0, y: 1.25, z: -2.24 },
        { x: 3, y: 2, z: 0.01 }
      );

      solarImage = "/solar612.png";
      solarImageBack = "/back_12U.png";
      containX = 3;
      containY = containZ = 2;
      break;
    case u >= 13 && u <= 16:
      camPosition.z = 7;

      if (isPayloadChanges === 'true') {
        camPosition.x = 6;
        camPosition.y = 4;
        camPosition.z = -2;
      }
      group1.push(
        { x: 0, y: 0.02, z: 0.5 },
        { x: 0, y: -0.75, z: 0.22 },
        { x: 4, y: 2, z: 0.02 },
        { x: 0, y: -0.75, z: 2.23 },
        { x: 4, y: 2, z: 0.01 },
        { x: 0, y: -1.75, z: -0.73 },
        { x: 0, y: -1.75, z: -2.31 },
        { x: 2, y: -1.75, z: -1.76 },
        { x: 2, y: -1.99, z: -0.02 },
        { x: -2, y: -1.75, z: -1.26 },
        { x: 0, y: -2.75, z: -1.28 },
        { x: 4, y: 2.1, z: 0.03 }
      );
      group2.push(
        { x: 0, y: -1.97, z: -3.08 },
        { x: 0, y: 1.25, z: -0.22 },
        { x: 4, y: 2, z: 0.02 },
        { x: 0, y: 1.25, z: -2.23 },
        { x: 4, y: 2, z: 0.01 }
      );

      solarImage = "/solar16.png";
      solarImageBack = "/back_16U.png";
      containX = 4;
      containY = containZ = 2;
      break;
    case u >= 17 && u <= 27:
      camPosition.z = 7;

      if (isPayloadChanges === 'true') {
        camPosition.x = 6;
        camPosition.y = 4;
        camPosition.z = -8;
      }
      group1.push(
        { x: 0, y: 0.5, z: 1.4 },
        { x: 0, y: -0.75, z: 0.3 },
        { x: 3, y: 3, z: 0.02 },
        { x: 0, y: -0.75, z: 3.31 },
        { x: 3, y: 3, z: 0.01 },
        { x: 0, y: -2.24, z: -1.17 },
        { x: 0, y: -1.75, z: -2.8 },
        { x: 1.5, y: -2.25, z: -2.71 },
        { x: 3, y: -2.99, z: -0.02 },
        { x: -1.5, y: -1.75, z: -1.31 },

        { x: 0, y: -3.25, z: -1.283 },
        { x: 3, y: 3, z: 0.02 },

      );
      group2.push(
        { x: 0, y: -0.75, z: -4.0 },
        { x: 0, y: 0.5, z: -0.3 },
        { x: 3, y: 3, z: 0.02 },
        { x: 0, y: 0.5, z: -3.31 },
        { x: 3, y: 3, z: 0.01 }
      );
      solarImage = "/solar27.png";
      solarImageBack = "/back_27U.png";
      containX = containY = containZ = 3;
      break;

    case u > 27 && u <= 100:
      group1.push(
        { x: 0, y: 0.5, z: 1.4 },
        { x: 0, y: 1.25, z: 3.8 },
        { x: 10, y: 7, z: 0.02 },
        { x: 0, y: 1.25, z: 11.81 },
        { x: 10, y: 8, z: 0.01 },
        { x: 0, y: -2.24, z: -0.17 },
        { x: 0, y: -1.75, z: -2.8 },
        { x: 5, y: -2.25, z: -2.71 },
        { x: 5, y: -6.99, z: -0.02 },
        { x: -5.01, y: -1.75, z: -1.31 },

        { x: 0, y: -5.25, z: -1.283 },
        { x: 10, y: 5.02, z: 0.02 },

      );
      group2.push(
        { x: 0, y: -0.75, z: -4.0 },
        { x: 0, y: 2.5, z: -3.8 },
        { x: 10, y: 8, z: 0.02 },
        { x: 0, y: 2.5, z: -11.31 },
        { x: 10, y: 7, z: 0.01 }
      );
      solarImage = "/solar_350.png";
      solarImageBack = "/solar_back_350.png";
      containX = 10;
      containY = 2;
      containZ = 5;
      // containX = containY = containZ = 3;
      break;

    case u > 100:
      group1.push(
        { x: 0, y: 0.5, z: 1.4 },
        { x: 0, y: 1.25, z: 3.8 },
        { x: 10, y: 7, z: 0.02 },
        { x: 0, y: 1.25, z: 11.81 },
        { x: 10, y: 8, z: 0.01 },
        { x: 0, y: -2.24, z: -0.17 },
        { x: 0, y: -1.75, z: -2.8 },
        { x: 5, y: -2.25, z: -2.71 },
        { x: 5, y: -6.99, z: -0.02 },
        { x: -5.01, y: -1.75, z: -1.31 },

        { x: 0, y: -5.25, z: -1.283 },
        { x: 10, y: 5.02, z: 0.02 },

      );
      group2.push(
        { x: 0, y: -0.75, z: -4.0 },
        { x: 0, y: 2.5, z: -3.8 },
        { x: 10, y: 8, z: 0.02 },
        { x: 0, y: 2.5, z: -11.31 },
        { x: 10, y: 7, z: 0.01 }
      );
      solarImage = "/solar_350.png";
      solarImageBack = "/solar_back_350.png";
      containX = 10;
      containY = 4;
      containZ = 5;
      // containX = containY = containZ = 3;
      break;

    default:
      return null;
  }

  const containerSize = [containX, containY, containZ];
  const containerPos = [
    -containerSize[0] / 2,
    -containerSize[1] / 2,
    -containerSize[2] / 2,
  ];
  const cubes = [];

  for (let x = 0; x < containX; x++) {
    for (let y = 0; y < containY; y++) {
      for (let z = 0; z < containZ; z++) {
        var cubePos = [
          containerPos[0] + x * cubeSize + cubeSize / 2,
          containerPos[1] + y * cubeSize + cubeSize / 2,
          containerPos[2] + z * cubeSize + cubeSize / 2,
        ];

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(1, 1, 1);

        const color = new THREE.Color();
        color.setHex(Math.random() * 0xffffff);
        cubes.push(
          <CreateMesh
            position={[cubePos[0], cubePos[1], cubePos[2]]}
            args={[cubeSize, cubeSize, cubeSize]}
            key={cubes.length}
          />
        );
      }
    }
  }

  var removedIndexArray = [];
  var cond = containX * containY * containZ - bus_u;
  var removedPos = u >= 7 ? 2 : 1;

  if (u > 16) {
    removedPos = 26;
  }


  if (size <= 27) {
    for (var i = 1; i <= cond; i++) {
      if (u === 2) {
        removedPos++;
      }
      removedIndexArray.push(removedPos);

      if (u > 16) {
        removedPos--;

        if (i % 3 === 0) {
          if (i % 9 === 0) {
            removedPos += 10;
          } else {
            removedPos -= 6;
          }
        }
      } else {
        if (i <= containX) {
          if (u <= 4) {
            if (u !== 1) {
              removedPos += 1;
            }
          } else {
            removedPos = u >= 7 ? removedPos + 3 : removedPos + 2;
          }
        }
        if (i >= containX) {
          removedPos -= 3;
        }
      }
    }

    if (viewFinalSatellite) {
      if (u == 6) {
        switch (bus_u) {
          case 1:
            removedIndexArray.push(2)
            removedIndexArray.push(1)
            break;
          case 2:
            removedIndexArray.push(2)
            removedIndexArray.push(-1)
            break;
          case 3:
            removedIndexArray.push(-1)
            break;
          case 4:
            removedIndexArray.push(2)
            break;
        }
      }
      if (u == 3) {
        if (bus_u == 1) {
          removedIndexArray.push(1)
        }
      }
    }

    for (i = 0; i < removedIndexArray.length; i++) {
      cubes.splice(removedIndexArray[i], 1);
    }
  }


  return (
    <div style={{ height: canvas_height, width: canvas_width }}>
      <Canvas
        className="h-100 w-100 "
        camera={{ position: [camPosition.x, size > 27 ? camPosition.y + 30 : camPosition.y, camPosition.z] }}
      >
        <Suspense fallback={Loader}>
          <CommonSat containX={containX} containY={containY} containZ={containZ} group1={group1} group2={group2} u={u} solarImage={solarImage} solarImageBack={solarImageBack} antarisLogo={antarisLogo} cubes={cubes} />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default Common3DSat;
