import { useState } from "react";
import "./SatellitePhysicalPropertiesBar.css";

import { ScaleIcon, WeightIcon, RenewableEnergyIcon } from "../SatelliteProfileImgAssets/SatelliteProfileImgAssets";

const SatellitePhysicalPropertiesBar = () => {
  return (
    <>
      <div className="satellitePhysicalPropertiesBar">
        <div className="propertiesBarItemsPlusProgress">
          <div className="propertiesBarItems">
            <div className="propertiesBarItemsIconDiv">
              <div className="">
                <ScaleIcon />
              </div>
              <div className="">8</div>
            </div>
            <div className="propertiesBarValue">24U</div>
          </div>
          <progress className="propertiesBarProgress" max="100" value="70" />
        </div>
        <div className="propertiesBarItemsPlusProgress">
          <div className="propertiesBarItems">
            <div className="propertiesBarItemsIconDiv">
              <div className="">
                <WeightIcon />
              </div>
              <div className="">10</div>
            </div>
            <div className="propertiesBarValue">100KG</div>
          </div>
          <progress className="propertiesBarProgress" max="100" value="20" />
        </div>
        <div className="propertiesBarItemsPlusProgress">
          <div className="propertiesBarItems">
            <div className="propertiesBarItemsIconDiv">
              <div className="">
                <RenewableEnergyIcon />
              </div>
              <div className="">0.3</div>
            </div>
            <div className="propertiesBarValue">6W</div>
          </div>
          <progress className="propertiesBarProgress" max="100" value="50" />
        </div>
      </div>
    </>
  );
};

export default SatellitePhysicalPropertiesBar;
