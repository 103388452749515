import "./CustomModelBottomBar.css";
import { useState } from "react";
import { SmallSatelliteIcon, MagnetIcon, Rotate3dIcon, PaletteIcon } from "../SatelliteProfileImgAssets/SatelliteProfileImgAssets";

const CustomModelBottomBar = ({ setControlMode, setSelectedMesh }) => {
  const [selectedBottomBarItem, setSelectedBottomBarItem] = useState({ ControlMode: false, Magnet: false, Rotate3d: false, Palette: false });

  const setItem = (item) => {
    setSelectedMesh('')
    if (item === "ControlMode") {
      setSelectedBottomBarItem({ ControlMode: true, Magnet: false, Rotate3d: false, Palette: false });
      setControlMode(true);
    }
    else if (item === "Magnet") {
      setSelectedBottomBarItem({ ControlMode: false, Magnet: true, Rotate3d: false, Palette: false });
      setControlMode(false);
    }
    else if (item === "Rotate3d") {
      setSelectedBottomBarItem({ ControlMode: false, Magnet: false, Rotate3d: true, Palette: false });
      setControlMode(false);
    }
    else if (item === "Palette") {
      setSelectedBottomBarItem({ ControlMode: false, Magnet: false, Rotate3d: false, Palette: true });
      setControlMode(false);
    }
  };

  return (
    <>
      <div className="customModelBottomBar">
        <button onClick={() => setItem("ControlMode")} className={selectedBottomBarItem.ControlMode ? "active" : ""}>
          <SmallSatelliteIcon />
        </button>
        <button onClick={() => setItem("Magnet")} className={selectedBottomBarItem.Magnet ? "active" : ""}>
          <MagnetIcon />
        </button>
        <button onClick={() => setItem("Rotate3d")} className={selectedBottomBarItem.Rotate3d ? "active" : ""}>
          <Rotate3dIcon />
        </button>
        <button onClick={() => setItem("Palette")} className={selectedBottomBarItem.Palette ? "active" : ""}>
          <PaletteIcon />
        </button>
      </div>
    </>
  );
};

export default CustomModelBottomBar;
