import MissionService from "../../../../../../service/MissionService";
import * as Cesium from "cesium";

export const scenario_visualization = () => {

    function removeDataSourceByName(viewer: any, dataSourceName: string) {
        const dataSources = viewer.dataSources;
        if (!dataSources) return;
        // Iterate through the data sources and remove all with matching names
        const sourcesToRemove: any[] = [];
        for (let i = 0; i < dataSources.length; i++) {
            const dataSource = dataSources.get(i);
            if (dataSource.name === dataSourceName) {
                sourcesToRemove.push(dataSource);
            }
        }
        // Remove all matching data sources
        sourcesToRemove.forEach((dataSource) => {
            dataSources.remove(dataSource, true); // true ensures complete cleanup
        });
        // Re-render the scene
        viewer.scene.requestRender();
    }

    function getDataSourceByName(viewer: any, dataSourceName: string) {
        const dataSources = viewer.dataSources;
        if (!dataSources) return
        for (let i = 0; i < dataSources.length; i++) {
            const dataSource = dataSources.get(i);
            if (dataSource.name === dataSourceName) {
                return dataSource;
            }
        }
        return null;
    }

    const getOrbitAvailability = (viewe: any, datasourceName: string) => {
        const dataSource = getDataSourceByName(viewe, datasourceName);
        if (dataSource && dataSource.clock) {
            const start = dataSource.clock.startTime;
            const stop = dataSource.clock.stopTime;
            return `${start.toString()}/${stop.toString()}`
        }
    }

    const fetchOrbitData = async (scenario_id, truetwin_id) => {
        try {
            const response = await MissionService.getOribtData(scenario_id, truetwin_id, "scenario")
            const data = response?.data
            let satellites = (data?.satellite && data?.satellite?.length > 0) ? data?.satellite : []
            return satellites
        } catch (error) {
            return null
        }
    };

    // convert the position caoordinate to Cartographic Degrees
    const getCartographicDegrees = (position: any) => {
        let cartographicDegrees: any[] = [];
        position.forEach((element: any[]) => {
            cartographicDegrees.push(element[0]);
            cartographicDegrees.push(element[1]);
            cartographicDegrees.push(0);
        });
        return cartographicDegrees;
    }

    // building AIO datasource
    const createAoiPacket = (viewer: any, aois: any[]) => {
        const availability = getOrbitAvailability(viewer, 'Orbit_Object')
        let aoiData: any[] = [];
        if (aois?.length > 0) {
            aois?.forEach((aoi: any, index) => {
                let aoiPacket = {
                    "description": "area of interest - " + index,
                    "id": "Facility/aoi/" + index,
                    "name": (aoi?.name ? aoi?.name : aoi?.aoiName),
                    "polygon": {
                        "extrudedHeight": 0,
                        "height": 0,
                        "material": {
                            "solidColor": {
                                "color": {
                                    "rgba": [204, 245, 78, 50]
                                }
                            }
                        },
                        "arcType": "RHUMB",
                        "positions": {
                            "cartographicDegrees": getCartographicDegrees(aoi?.polygon)
                        }
                    }
                }
                aoiData.push(aoiPacket)
            })
            removeDataSourceByName(viewer, "AOI_Object")
            removeDataSourceByName(viewer, "GT_Object")
            const dataSource = new Cesium.CzmlDataSource();
            viewer && viewer.dataSources.add(dataSource.load([
                {
                    "clock": {
                        interval: availability,
                        "multiplier": 0,
                        "range": "CLAMPED",
                        "step": "SYSTEM_CLOCK_MULTIPLIER"
                    },
                    "id": "document",
                    "name": "AOI_Object",
                    "version": "1.0"
                },
                ...aoiData
            ]))
        }

    }

    // building Ground target datasource
    const createGroundTargetPacket = (viewer: any, groundTargets: any[]) => {
        let groundTargetData: any[] = [];
        if (groundTargets?.length > 0) {
            const availability = getOrbitAvailability(viewer, 'Orbit_Object')
            groundTargets?.forEach((groundTarget: any, index) => {
                let gtPacket = {
                    "billboard": {
                        "eyeOffset": {
                            "cartesian": [
                                0,
                                0,
                                0
                            ]
                        },
                        "horizontalOrigin": "CENTER",
                        "image": require('../Images/gsTarget.png'),
                        "pixelOffset": {
                            "cartesian2": [
                                0,
                                0
                            ]
                        },
                        "scale": 1,
                        "show": true,
                        "verticalOrigin": "CENTER"
                    },
                    "description": "Ground Target - " + index,
                    "id": `ground_target_${groundTarget?.['name']}`,
                    "label": {
                        "fillColor": {
                            "rgba": [
                                255,
                                255,
                                255,
                                255
                            ]
                        },
                        "font": "montserrat",
                        "horizontalOrigin": "LEFT",
                        "outlineColor": {
                            "rgba": [
                                0,
                                0,
                                0,
                                255
                            ]
                        },
                        "outlineWidth": 2,
                        "pixelOffset": {
                            "cartesian2": [
                                12,
                                0
                            ]
                        },
                        "show": true,
                        "style": "FILL_AND_OUTLINE",
                        "verticalOrigin": "CENTER"
                    },
                    "name": groundTarget?.['name'],
                    "position": {
                        "cartographicDegrees": groundTarget?.position?.length > 0 ?
                            [
                                groundTarget?.position?.[0],
                                groundTarget?.position?.[1],
                                groundTarget?.position?.[2]
                            ]
                            : [
                                groundTarget?.coordinates?.long,
                                groundTarget?.coordinates?.lat,
                                groundTarget?.coordinates?.altitude
                            ]
                    }
                }
                groundTargetData.push(gtPacket)
            })
            removeDataSourceByName(viewer, "AOI_Object")
            removeDataSourceByName(viewer, "GT_Object")
            const dataSource = new Cesium.CzmlDataSource();
            viewer && viewer.dataSources.add(dataSource.load([
                {
                    "clock": {
                        interval: availability,
                        "multiplier": 0,
                        "range": "CLAMPED",
                        "step": "SYSTEM_CLOCK_MULTIPLIER"
                    },
                    "id": "document",
                    "name": "GT_Object",
                    "version": "1.0"
                },
                ...groundTargetData
            ]))
        }

    }

    const getEntityById = (viewer: any, dataSourceName: string, entityId: string) => {
        const dataSource = getDataSourceByName(viewer, dataSourceName)
        if (!dataSource) return
        const entity = dataSource.entities.getById(entityId);
        return entity
    }

    const request_render = (viewer) => {
        viewer.scene.requestRender();
        setTimeout(() => {
            viewer.scene.requestRender();
        }, 1000);
    }

    return {
        init_satellite_packet: async (viewer: any, scenario_id: any, truetwin_id: any) => {
            const satellites = await fetchOrbitData(scenario_id, truetwin_id)
            if (satellites) {
                let satelliteData: any[] = []
                satellites.forEach(satellite => {
                    let packet = {
                        availability: satellite?.availability,
                        billboard: {
                            eyeOffset: { cartesian: [0, 0, 0] },
                            horizontalOrigin: "CENTER",
                            image: '/assets/selectedSatellite.png',
                            pixelOffset: { cartesian2: [0, 0] },
                            scale: 0.05,
                            show: true,
                            verticalOrigin: "CENTER"
                        },
                        description: "Attached Object to satellite",
                        id: satellite?.satId,
                        path: {
                            // leadTime: [{ number: 20000 }],
                            width: 1,
                            material: {
                                solidColor: {
                                    color: { rgba: satellite?.isAdditionalObject ? [142, 125, 238, 100] : [138, 138, 138, 60] }
                                }
                            },
                            resolution: 10,
                            show: [{ boolean: true, interval: satellite?.availability }],
                            trailTime: [{ number: 300 }]
                        },
                        position: {
                            cartographicDegrees: satellite?.satOrbitPoint,
                            epoch: satellite?.availability?.split('/')[0]
                        }
                    };

                    satelliteData.push(packet);
                });
                removeDataSourceByName(viewer, "Orbit_Object")
                const dataSource = new Cesium.CzmlDataSource();
                viewer.dataSources.add(dataSource.load([
                    {
                        clock: {
                            interval: satellites[0]?.availability,
                            multiplier: 0,
                            range: "CLAMPED",
                            step: "SYSTEM_CLOCK_MULTIPLIER"
                        },
                        id: "document",
                        name: "Orbit_Object",
                        version: "1.0"
                    },
                    ...satelliteData
                ]));
                request_render(viewer);
            }
        },
        remove_additional_satellite_packet: async (viewer: any, satId: any,) => {
            const datasource = getDataSourceByName(viewer, 'Orbit_Object')
            if (!datasource) return
            const entity = getEntityById(viewer, 'Orbit_Object', satId);
            if (!entity) return;
            datasource.entities.remove(entity);
            request_render(viewer);
        },
        init_ground_station_packet: (viewer: any, scenario_gs: any[], all_ground_stations: any[]) => {
            if (scenario_gs?.length) {
                scenario_visualization().init_gs_packets(viewer, all_ground_stations, scenario_gs)
            } else {
                const ground_stations = all_ground_stations.map(gs => gs?.['groundStationId'])
                scenario_visualization().init_gs_packets(viewer, all_ground_stations, ground_stations)
            }
            request_render(viewer);
        },
        init_aoi_packet: (viewer: any, taskplanid: string) => {
            if (taskplanid === '') return
            MissionService.getTaskPlan_by_id(taskplanid).then((res => {
                if (res.data) {
                    scenario_visualization().selected_aoi_packet(viewer, res?.data)
                }
            })).catch((err) => {
                console.log("Error in getting taskplan");
            })
            request_render(viewer);
        },
        selected_aoi_packet: (viewer: any, task: any) => {
            task?.taskDetails?.map((task) => {
                if (task?.groundTarget?.type === "Geofence") {
                    createAoiPacket(viewer, [task?.groundTarget])
                }
                if (task?.groundTarget?.type === "Target Track") {
                    createGroundTargetPacket(viewer, [task?.groundTarget])
                }
            })
            request_render(viewer);
        },
        init_gs_packets: (viewer: any, ground_stations: any, selected_ground_stations: any) => {
            let gsPacketList: any[] = [];
            ground_stations.forEach((station: any) => {
                const gsId = station?.groundStationId
                const packet = {
                    "billboard": {
                        "horizontalOrigin": Cesium.HorizontalOrigin.CENTER,
                        "image": selected_ground_stations.includes(gsId) ? '/assets/select_gs.svg' : '/assets/un_select_gs.svg',
                        "pixelOffset": new Cesium.Cartesian2(0, 0),
                        "show": true,
                        "verticalOrigin": Cesium.VerticalOrigin.CENTER
                    },
                    "description": "Ground Station - " + gsId,
                    "label": {
                        "fillColor": Cesium.Color.fromBytes(255, 255, 255, 255),
                        "font": "montserrat",
                        "horizontalOrigin": "LEFT",
                        "outlineColor": Cesium.Color.fromBytes(0, 0, 0, 255),
                        "outlineWidth": 2,
                        "pixelOffset": {
                            "cartesian2": [
                                15,
                                0
                            ]
                        },
                        "show": false,
                        "style": "FILL_AND_OUTLINE",
                        "verticalOrigin": "CENTER",
                        "text": station?.groundStationName ? station?.groundStationName : station?.name,
                    },
                    "name": station?.groundStationName ? station?.groundStationName : station?.name,
                    "position": {
                        "cartographicDegrees": [
                            station?.properties?.longitude,
                            station?.properties?.latitude,
                            0
                        ]
                    },
                    "id": gsId,
                };
                gsPacketList.push(packet)
            })
            removeDataSourceByName(viewer, "GS_Object")
            const dataSource = new Cesium.CzmlDataSource();
            viewer && viewer.dataSources.add(dataSource.load([
                {
                    "clock": {
                        "multiplier": 0,
                        "range": "CLAMPED",
                        "step": "SYSTEM_CLOCK_MULTIPLIER"
                    },
                    "id": "document",
                    "name": "GS_Object",
                    "version": "1.0"
                },
                ...gsPacketList
            ]))
            viewer.scene.requestRender();
        },
        select_unselect_gs: (viewer: any, ground_station: string, state: string) => {
            const entity = getEntityById(viewer, "GS_Object", ground_station)
            if (entity) {
                if (state === 'checked') {
                    entity.billboard.image = '/assets/select_gs.svg';
                } else {
                    entity.billboard.image = '/assets/un_select_gs.svg';
                }
            }
            request_render(viewer);
        },
        focus_gs: (viewer: any, ground_station: string, selected_ground_stations: any) => {

            const datasource = getDataSourceByName(viewer, "GS_Object")
            if (datasource.entities && datasource.entities.values) {
                for (const entity of datasource.entities.values) {
                    if (entity && entity.billboard) {
                        if (entity.id === ground_station) {
                            var position = entity.position.getValue(viewer.clock.currentTime);
                            var cartographic = Cesium.Cartographic.fromCartesian(position);
                            entity.billboard.image = '/assets/selectedHowerGs.png';
                            viewer.camera.flyTo({
                                destination: Cesium.Cartesian3.fromRadians(
                                    cartographic.longitude,
                                    cartographic.latitude,
                                    10000000
                                ),
                                duration: 3
                            });
                            const element = document.getElementById(ground_station);
                            if (element) {
                                element.style.color = '#CCF54E';
                            }
                        } else {
                            entity.billboard.image = selected_ground_stations.includes(entity.id) ? '/assets/select_gs.svg' : '/assets/un_select_gs.svg';
                            const element = document.getElementById(entity.id);
                            if (element) {
                                element.style.color = '';
                            }
                        }
                    }
                }
            }
        },
        removed_focus_gs: (viewer: any, selected_ground_stations: any) => {
            const datasource = getDataSourceByName(viewer, "GS_Object")
            if (datasource && datasource.entities && datasource.entities.values) {
                for (const entity of datasource.entities.values) {
                    if (entity && entity.billboard) {
                        entity.billboard.image = selected_ground_stations.includes(entity.id) ? '/assets/select_gs.svg' : '/assets/un_select_gs.svg';
                        const element = document.getElementById(entity.id);
                        if (element) {
                            element.style.color = '';
                        }
                        request_render(viewer);
                    }
                }
            }
        }
    }
}