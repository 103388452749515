
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CatalogueService from "../../../../../service/CatalogueService";
import MissionService from "../../../../../service/MissionService";
import {
  MissionStore,
  setSatellite,
} from "../../../../../store/MissionSlice";
import { usageStore } from "../../../../../store/UsageSlice";
import {
  calculateEdgeUsage,
  calculatePayloadUsage,
  calculateUsagePercentage,
  calculate_Total_AOP_Usage,
} from "../../../../CommonComponents/CommonFunctions";
import Custom3DSat from "./Custom3DSat";


const Create3DSat = () => {
  const [loading, setLoading] = useState(true)

  const [espaTempData, setEspaTempData] = useState({
    type: '',
    value: '',
  })

  const params = useParams();
  const { mission_name, sat_name, mission_id, sat_id } = params;
  const dispatch = useDispatch();
  const [satellieteDeta, setSatellieteData] = useState<any>({});

  const Satellite = useSelector(
    (state: { mission: MissionStore }) => state.mission.Satellite
  );

  const busSizeUsage = useSelector(
    (state: { usage: usageStore }) => state.usage.busSizeUsage
  );
  const payloadSizeUsage = useSelector(
    (state: { usage: usageStore }) => state.usage.payloadSizeUsage
  );
  const payloadPowerUsage = useSelector(
    (state: { usage: usageStore }) => state.usage.payloadPowerUsage
  );
  const payloadWeightUsage = useSelector(
    (state: { usage: usageStore }) => state.usage.payloadWeightUsage
  );
  const edgePowerUsage = useSelector(
    (state: { usage: usageStore }) => state.usage.edgePowerUsage
  );
  const edgeSizeUsage = useSelector(
    (state: { usage: usageStore }) => state.usage.edgeSizeUsage
  );
  const edgeWeightUsage = useSelector(
    (state: { usage: usageStore }) => state.usage.edgeWeightUsage
  );




  const get_satellite = () => {
    MissionService.get_satellite_by_sat_id(sat_id)?.then((sat) => {
      if (sat?.data) {
        setSatellieteData(sat.data)
        CatalogueService.getTemplate(sat?.data?.['versions']?.[0]?.['assembly']?.['Template'])?.then((template) => {
          if (template.data) {
            let all_payloas = sat?.data?.['versions']?.[0]?.['assembly']?.['Payloads'] ? sat?.data?.['versions']?.[0]?.['assembly']?.['Payloads'] : []
            let all_edges = sat?.data?.['versions']?.[0]?.['assembly']?.['Edge'] ? sat?.data?.['versions']?.[0]?.['assembly']?.['Edge'] : []
            calculate_Total_AOP_Usage(all_payloas, all_edges, dispatch)
            calculatePayloadUsage(all_payloas, dispatch)
            calculateEdgeUsage(all_edges, dispatch)
            dispatch(setSatellite({
              name: sat?.data?.['name'],
              missionId: sat?.data?.['containing-mission'],
              Payloads: sat?.data?.['versions']?.[0]?.['assembly']?.['Payloads'] ? sat?.data?.['versions']?.[0]?.['assembly']?.['Payloads'] : [],
              Edges: sat?.data?.['versions']?.[0]?.['assembly']?.['Edge'] ? sat?.data?.['versions']?.[0]?.['assembly']?.['Edge'] : [],
              Template: template.data,
              satellite_Id: sat_id,
            }))
            if (template?.data["template-attribute"]?.["type"] === 'espa') {
              setEspaTempData({
                type: template?.data["template-attribute"]?.["type"],
                value: template?.data["template-attribute"]?.["sizeInXYZ"],
              })
            } else {
              setEspaTempData({
                type: '',
                value: '',
              })
            }
          }
          setLoading(false)
        }).catch((err) => {
          console.error(err);
          setLoading(false)
        })
      }
    }).catch((err) => {
      console.error(err);
      setLoading(false)
    })
  }

  useEffect(() => {
    if (sat_id) {
      get_satellite()
    }
  }, [sat_id])

  useEffect(() => {
    if (Satellite?.Template && Object.keys(Satellite?.Template).length > 0) {
      const allParams = {
        temp: Satellite?.Template,
        templateDataAvailable: true,
        dispatch: dispatch,
        powerUsage: payloadPowerUsage,
        edgePowerUsage: edgePowerUsage,
        edgeSizeUsage: edgeSizeUsage,
        edgeWeightUsage: edgeWeightUsage,
        sizeUsage: payloadSizeUsage,
        weightUsage: payloadWeightUsage
      }
      calculateUsagePercentage(allParams);
    }

  }, [Satellite?.Template, payloadPowerUsage, edgePowerUsage])

  const bus_type = useMemo(() => Satellite?.Template?.['template-attribute']?.['bus-type'] === undefined ? '3U' : Satellite?.Template?.['template-attribute']?.['bus-type'], [Satellite?.Template?.['template-attribute']?.['bus-type']])
  return (
    <div className="h-100 w-100 overflow-hidden align-items-center justify-content-center d-flex" style={{ background: 'black' }}>
      <Custom3DSat Satellite={Satellite} busSize={bus_type}
        size={busSizeUsage + payloadSizeUsage} cubeSize={1}
      />

    </div>
  );
};
export default Create3DSat;
