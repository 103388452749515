import { useEffect, useRef } from "react";
import UnifiedControlPlaneService from "../UnifiedControlPlane.service";
import "./UcpComponents.css";
import { useDispatch } from "react-redux";

const ThreeDotMenuComponent = ({ item, type, mission_id, scenarioId, previewOrders, onMenuClose }) => {

  const dispatch = useDispatch();
  
  const threeDotMenuRef = useRef();
  
  const orderUpdateSuccessCallback = () => {
    dispatch(UnifiedControlPlaneService.getOrders());
  };

  const updatePeviewOrders = (action) => {
    dispatch(UnifiedControlPlaneService.updateOrders(previewOrders, mission_id, action, orderUpdateSuccessCallback));
    if(onMenuClose && typeof onMenuClose === 'function'){
      onMenuClose();
    };
  };

  const requestSchedule = () => {
    dispatch(UnifiedControlPlaneService.generateSchedule(scenarioId, mission_id, orderUpdateSuccessCallback));
    if(onMenuClose && typeof onMenuClose === 'function'){
      onMenuClose();
    };
  };

  const deleteOrder = () => {
    dispatch(UnifiedControlPlaneService.deleteOrders(item.orderId, orderUpdateSuccessCallback));
    if(onMenuClose && typeof onMenuClose === 'function'){
      onMenuClose();
    };
  }
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (threeDotMenuRef.current && !threeDotMenuRef.current.contains(event.target)) {
        onMenuClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onMenuClose]);

  return (
    <div ref={threeDotMenuRef}>
      {item?.status?.state ?
        <div className="threeDotMenuParentDiv">
          <div className="threeDotOptionsMenu">
            <>
              {item.status.state === "Complete" && (
                <>
                  <button className="modalInputBox fullWidth" onClick={() => { deleteOrder(); }}>
                    <div className="modalInputBoxText">Delete Order</div>
                  </button>
                </>
              )}
              {item.status.state === "Current" && (
                <>
                  <button className="modalInputBox fullWidth" onClick={() => { deleteOrder(); }}>
                    <div className="modalInputBoxText">Delete Order</div>
                  </button>
                </>
              )}
              {item.status.state === "Pending" && (
                <>
                  <button className="modalInputBox fullWidth" onClick={() => { deleteOrder(); }}>
                    <div className="modalInputBoxText">Delete Order</div>
                  </button>
                </>
              )}
              {item.status.state === "Preview" && (
                <>
                  <button className="modalInputBox fullWidth" onClick={() => { deleteOrder(); }}>
                    <div className="modalInputBoxText">Delete Order</div>
                  </button>
                </>
              )}
            </>
          </div>
        </div>
        :
        <div className="threeDotMenuParentDiv">
          <div className="threeDotOptionsMenu">
            {type === "preview-none" && (
              <>
                <button className="modalInputBox fullWidth" onClick={() => requestSchedule()}>
                  <div className="modalInputBoxText">Generate Schedule</div>
                </button>
              </>
            )}
            {type === "preview-partial" && (
              <>
                <button className="modalInputBox fullWidth" onClick={() => updatePeviewOrders('Approve')}>
                  <div className="modalInputBoxText">Approve Schedule</div>
                </button>
                <button className="modalInputBox fullWidth" onClick={() => updatePeviewOrders('Reject')}>
                  <div className="modalInputBoxText">Reject Schedule</div>
                </button>
                <button className="modalInputBox fullWidth" onClick={() => requestSchedule() }>
                  <div className="modalInputBoxText">Regenerate Schedule</div>
                </button>
              </>
            )}
            {type === "preview-all" && (
              <>
                <button className="modalInputBox fullWidth" onClick={() => updatePeviewOrders('Approve')}>
                  <div className="modalInputBoxText">Approve Schedule</div>
                </button>
                <button className="modalInputBox fullWidth" onClick={() => updatePeviewOrders('Reject')}>
                  <div className="modalInputBoxText">Reject Schedule</div>
                </button>
                <button className="modalInputBox fullWidth" onClick={() => requestSchedule() }>
                  <div className="modalInputBoxText">Regenerate Schedule</div>
                </button>
              </>
            )}
          </div>
        </div>
      }
    </div>
  );
};

export default ThreeDotMenuComponent;
