import { createContext, ReactNode, useEffect, useMemo, useRef } from 'react';
import * as Cesium from "cesium";
import useScenario from '../hooks/useScenario';
import useTimeline from '../hooks/useTimeline';
import useTrueTwin from '../hooks/useTrueTwin';
import useContacts from '../hooks/useContacts';
import useUrlParams from '../hooks/useUrlParams';

interface ViewerContextType {
    viewer: Cesium.Viewer | null;
    setViewer: (data: any) => void;
    current_simulation_time: number;
    set_current_simulation_time: (data: number) => void;
}

export const ViewerContext = createContext<ViewerContextType | undefined>(undefined);

interface CesiumProviderProps {
    children: ReactNode;
}

const CesiumProvider = ({ children }: CesiumProviderProps) => {
    const { set_simulation_time, simulation_time } = useScenario()
    const { get_truetwin_status } = useTrueTwin()
    const { dashboard, scenario_id } = useUrlParams()
    const { get_time_based_gs_contact_details } = useContacts()
    const viewerRef = useRef<Cesium.Viewer | null>(null);
    const correntTimeRef = useRef<number>(0)

    const setViewer = (viewer: Cesium.Viewer) => {
        viewerRef.current = viewer;
    };
    const set_current_simulation_time = (time: number) => {
        correntTimeRef.current = time
    }
    const viewer = useMemo(() => viewerRef.current, [viewerRef.current])
    const current_simulation_time = useMemo(() => correntTimeRef.current, [correntTimeRef.current])

    const { window_width, timeframe_details, set_cesium_time, timeline_container } = useTimeline()
    const { frame_start_time, frame_duration, manual_change } = timeframe_details

    useEffect(() => {
        if (scenario_id && dashboard === 'operate' && simulation_time.start_time && simulation_time.end_time) {
            get_time_based_gs_contact_details(scenario_id, simulation_time.start_time, simulation_time.end_time, 'compact')
        }
    }, [simulation_time.start_time, simulation_time.end_time, dashboard, scenario_id])

    useEffect(() => {
        if (viewerRef.current) {
            const viewer = viewerRef.current;
            const simulation_duration = simulation_time.duration
            const simulation_end_time = simulation_time.end_time
            const simulation_end_time_milisec = simulation_end_time * 1000

            if (simulation_end_time && simulation_duration && timeline_container.slots.length > 0) {
                if (dashboard !== 'operate') {
                    const Interval = setInterval(() => {
                        const clock = viewer?.clock;
                        if (clock) {
                            const currentTime = clock?.currentTime;
                            if (currentTime) {
                                const epochTime = Cesium.JulianDate.toDate(currentTime).getTime();
                                set_cesium_time(viewer, epochTime)
                                if (epochTime >= simulation_end_time_milisec && dashboard !== "summary") {
                                    clearInterval(Interval);
                                    set_current_simulation_time(simulation_end_time_milisec)
                                    get_truetwin_status()
                                } else if (epochTime < simulation_end_time_milisec) {
                                    set_current_simulation_time(epochTime)
                                }
                            }
                        }
                    }, 1000);
                    // Code to run every second goes here
                    return () => {
                        clearInterval(Interval);
                    };
                } else if (dashboard === 'operate') {
                    const Interval = setInterval(() => {
                        const clock = viewer?.clock;
                        const current_time = clock && Cesium.JulianDate.toDate(clock?.currentTime).getTime();
                        if (current_time) {
                            set_cesium_time(viewer, current_time)
                            set_current_simulation_time(current_time)
                            if (current_time >= simulation_end_time_milisec) {
                                const new_simulation_end_time = simulation_end_time + simulation_duration
                                set_simulation_time({
                                    start_time: simulation_end_time,
                                    end_time: new_simulation_end_time,
                                    duration: simulation_duration
                                })
                            }
                        }
                        // Code to run every second goes here
                    }, 1000);
                    return () => {
                        clearInterval(Interval);
                    };
                }
            }
        }
    }, [simulation_time, window_width, frame_duration, frame_start_time, timeline_container, manual_change]);

    return (
        <ViewerContext.Provider value={{ viewer, setViewer, current_simulation_time, set_current_simulation_time }}>
            {children}
        </ViewerContext.Provider>
    )
}

export default CesiumProvider