//@ts-nocheck
import ModalFooter from '../../../commonComponents/ModalFooter';
import ModalHeader from '../../../commonComponents/ModalHeader';
import { DatePicker } from 'rsuite';
import { ActiveSatellite, CloseIcon, CloseSingleViewIcon, GeofenceSvg, HeaderCoverageIcon, NormalConstellation, NormalGroundStation, NormalSatellite } from '../../../commonMMSvgs/MissionModellingSvgs';
import './coverage.css'
import ModalInput from '../../../commonComponents/ModalInput';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as mmActions from '../../../../../store/MissionModellingSlice.js';
import { useParams } from 'react-router-dom';
import MissionModellingService from '../../../MissionModelling.service';
import { gs_visualization_presentation } from '../../Sidebar/GroundStationCreation/Utils';
import { toast } from 'react-toastify';

const CoverageAnalysis = ({ closeModalCreation, mm_viewer }) => {

    const get_utc_date_time = (date) => {
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()))
        return utcDate
    }

    const dispatch = useDispatch()
    const params = useParams()
    const { mission_id, scenario_id } = params;

    const [activeAnalyis, setActiveAnalysis] = useState('Imaging')
    const [duration, setDuration] = useState('')
    const [startTime, setStartTime] = useState(get_utc_date_time(new Date()))
    const [isMinimized, setIsMinimized] = useState(false)

    const selectedConstellationForCoverage = useSelector(state => state.missionModelling.selectedConstellationsForCoverage)
    const selectedGeoFeaturesForCoverage = useSelector(state => state.missionModelling.selectedGeoFeaturesForCoverage)
    const creatingConstellation = useSelector(state => state.missionModelling.creatingConstellation);
    const coverageAnalysisLoader = useSelector(state => state.missionModelling.coverageAnalysisLoader);

    const isModalMinimized = useSelector(state => state.missionModelling.isModalMinimized);

    const handleRemoveConstellation = (constellation) => {
        dispatch(mmActions.removeSelectedConstellationsForCoverage(constellation))
    }

    const handleRemoveGeoFeature = (geoFeature) => {
        dispatch(mmActions.removeSelectedGeoFeatureForCoverage(geoFeature))
        gs_visualization_presentation().selected_aois(geoFeature.areaOfInterestId, mm_viewer)
        gs_visualization_presentation().selected_target(geoFeature.areaOfInterestId, mm_viewer)
    }

    const handleDateChange = (date) => {
        if (date) {
            setStartTime(date)
        }
    }

    const handleDuration = (value) => {
        setDuration(value)
    }

    const isValidInputs = () => {
        if (selectedConstellationForCoverage?.length > 0 && selectedGeoFeaturesForCoverage?.length > 0 && duration > 0) {
            return true
        }
        return false
    }

    const isValidDurationInputs = () => {
        let isValid = true
        if (duration > 48) {
            isValid = false
            toast.error("Duration Should be less than 48 hours.")
        }
        return isValid
    }

    const handleSubmit = () => {
        let body;
        let constellationId = [];
        let geoFetaureId = [];

        selectedConstellationForCoverage?.map((item, index) => {
            if (item?.type === 'Single') {
                let satelliteId = item?.groups[0]?.satellites[0]?.id
                constellationId.push({ objectId: satelliteId, objectType: item?.type })
            } else {
                constellationId.push({ objectId: item?.constellationId, objectType: item?.type })
            }
        })

        selectedGeoFeaturesForCoverage?.map((item, index) => {
            geoFetaureId.push({ aoiId: item?.areaOfInterestId })
        })

        body = {
            constellations: constellationId,
            aoiobjects: geoFetaureId,
            startTime: startTime.getTime() / 1000,
            duration: duration
        }

        if (isValidDurationInputs()) {
            dispatch(MissionModellingService.coverageAnalysis(mission_id, body, scenario_id, closeModalCreation))
        }
    }
    const close_modal = () => {
        dispatch(mmActions.removeAllSelectedConstellationsForCoverage())
        dispatch(mmActions.removeAllSelectedGeoFeatureForCoverage())
        closeModalCreation()
    }

    useEffect(() => {
        console.log(selectedGeoFeaturesForCoverage);
    }, [selectedGeoFeaturesForCoverage])

    return (
        <div className='coverage_analysis_container'>
            <ModalHeader
                icon={<HeaderCoverageIcon />}
                title={"Coverage Analysis"}
                handleClose={() => { close_modal() }}
                isMinimized={isMinimized}
                setIsMinimized={setIsMinimized}
            />

            {!isMinimized &&
                (<>
                    <div className='coverage_analysis_container_body' style={{ pointerEvents: coverageAnalysisLoader ? 'none' : 'auto', opacity: coverageAnalysisLoader ? '0.5' : '1' }}>
                        <div className='immaging_comm_section'>
                            <div className='immaging_com_subsection' onClick={() => { setActiveAnalysis('Imaging') }}>
                                <div className={`imagging_com_section_svg_container ${activeAnalyis === 'Imaging' ? `active_container` : ``}`}>
                                    <ActiveSatellite />
                                </div>
                                <span className={`${activeAnalyis === 'Imaging' ? `active_container_text` : `container_text`}`}>
                                    Imaging
                                </span>
                            </div>

                            {/* Need to hide this for release */}
                            {/* <div className='immaging_com_subsection disable_text'>
                                <div className={`imagging_com_section_svg_container ${activeAnalyis === 'Communication' ? `active_container` : ``}`}>
                                    <NormalConstellation />
                                </div>
                                <span className='container_text'>
                                    Communication
                                </span>
                            </div> */}
                        </div>
                        <div className='inner_section'>
                            <div className='section_title'>
                                <NormalSatellite />
                                <span>Satellites</span>
                            </div>
                            <div className='inner_container'>

                                {selectedConstellationForCoverage?.map((item, index) => (
                                    <div className='single_view'>
                                        <span title={item?.name}>
                                            {item?.name?.length >= 10 ? item?.name?.substring(0, 10) + ".." : item?.name}
                                        </span>
                                        <div onClick={() => { handleRemoveConstellation(item) }}>
                                            <CloseSingleViewIcon />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='inner_section'>
                            <div className='section_title'>
                                <GeofenceSvg />
                                <span>GeoFeatures</span>
                            </div>
                            <div className='inner_container'>

                                {selectedGeoFeaturesForCoverage?.map((item, index) => (
                                    <div className='geofense_single_view'>
                                        <span title={item?.name}>
                                            {item?.name?.length >= 10 ? item?.name?.substring(0, 10) + ".." : item?.name}
                                        </span>
                                        <div onClick={() => { handleRemoveGeoFeature(item) }}>
                                            <CloseSingleViewIcon color='#7AB3FF' />
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>

                        <div className='other_section'>
                            <div className='timeframe_section'>
                                <span>
                                    Start Time
                                </span>
                                <div>
                                    <DatePicker
                                        format="yyyy/MM/dd HH:mm:ss"
                                        onChange={(date) => {
                                            if (new Date(date).getFullYear() <= 2037) {
                                                handleDateChange(date)
                                            } else {
                                                toast.error("Please select a date before 2038.", { toastId: 'error' });
                                            }
                                        }}
                                        value={startTime}
                                        cleanable={true}
                                    />
                                </div>
                            </div>
                            <div className='timeframe_section'>
                                <span>
                                    Duration
                                </span>
                                <div>
                                    <ModalInput unit='hr' value={duration} handleChange={(value) => { handleDuration(value) }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <ModalFooter
                        isValid={isValidInputs()}
                        closingText='Close'
                        actionText='Analyze'
                        loadingText='Analyzing '
                        handleClose={() => { close_modal() }}
                        handleSubmit={() => { handleSubmit() }}
                        loader={coverageAnalysisLoader}
                    />
                </>)
            }
        </div>
    );
};

export default CoverageAnalysis;
