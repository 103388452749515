import { useState } from "react";
import "./Orders.css";
// import "./../UcpComponents.css";

import OrderDescriptionBox from "./OrderDescriptionBox";
import FeaturesFilter from "../FeaturesFilter";
import OrdersBarFeaturesList from "../OrdersBarFeaturesList";
// import SubOrdersBarFeaturesList from "../SubOrdersBarFeaturesList";
import ExpandedSubOrderTable from "../ExpandedSubOrderTable";
import RenderSubOrder from "../RenderSubOrder";
import HoverBoxNotification from "../HoverBoxNotification";
import CircularProgressBarComponent from "../CircularProgressBarComponent";
import ThreeDotMenuComponent from "../ThreeDotMenuComponent";
import { useDispatch, useSelector } from "react-redux";
import { resetHighlightedOrderAssign, setOrdersSection, setShowMapBoxMap } from "../../../../store/UnifiedControlPlaneSlice";
import { ORDER_STATUS } from "../../UnifiedControlPlane.constants";
import { ucp_visualization_presentation } from "../../Utils";
import PreviewSchedule from "../PreviewSchedule/PreviewSchedule";
const OrdersView = ({ props, ucpViewer, mission_id, scenarioId }) => {

  const dispatch = useDispatch();

  const activeOrdersSection = useSelector(state => state.unifiedControlPlane.activeOrdersSection);
  const orders = useSelector(state => state.unifiedControlPlane.orders);

  const [threeDotOptionsMenu, setThreeDotOptionsMenu] = useState();
  const [isThreeDotOptionsVisible, setIsThreeDotOptionsVisible] = useState(true);
  const [isOrdersAllOrders, setIsOrdersAllOrders] = useState(true);

  const [headerElement, setHeaderElement] = useState({
    status: {
      state: '',
    },
    spec: {
      name: '',
    },
    orderId: ""
  });

  const [orderDescriptionBoxVisibility, setOrderDescriptionBoxVisibility] = useState({ display: "none" });

  const createNewOrder = () => {
    props.setIsOrdersBarVisible(false);
    props.setIsOrderCreationModal(true);
    dispatch(setShowMapBoxMap(true));
  };

  const showSingleOrderBar = (item) => {
    setIsOrdersAllOrders(false);
    setHeaderElement(item);
  };

  const showOrderDescriptionBoxOnHover = (e, item, type) => {
    setHeaderElement(item);
    setOrderDescriptionBoxVisibility({ display: "block" });
  };

  const showOrderDescriptionBoxOffHover = (e, item, type) => {
    setOrderDescriptionBoxVisibility({ display: "none" });
  };

  const [hoverBoxNotificationVisibility, setHoverBoxNotificationVisibility] = useState({ display: "none" });
  const [hoverBoxElement, setHoverBoxElement] = useState({
    isType: "",
    name: "",
    orderId: ""
  });

  const showHoverBoxNotificationOnHover = (e, item, type) => {
    if (item && Object.keys(item).length > 0) {
      setHeaderElement(item);
      setHoverBoxElement({ isType: type, name: item.spec.name, orderId: item?.orderId });
    } else {
      setHoverBoxElement({ isType: type });
    }
    setHoverBoxNotificationVisibility({ display: "block" });
  };

  const showHoverBoxNotificationOffHover = (e, item) => {
    setHoverBoxNotificationVisibility({ display: "none" });
  };

  const showAllOrders = () => {
    setIsOrdersAllOrders(true);
    ucp_visualization_presentation().remove_highlight_strip(ucpViewer, 'Highlight_Order_Assignment');
    dispatch(resetHighlightedOrderAssign());
  };

  const threeDotMenuClick = (event, item, type) => {
    event.stopPropagation();
    if (type) {
      setThreeDotOptionsMenu(type);
    } else {
      setThreeDotOptionsMenu(item?.orderId);
    }
    setIsThreeDotOptionsVisible(true);
  }

  const threeDotMenuClose = () => {
    setThreeDotOptionsMenu('');
    setIsThreeDotOptionsVisible(false);
  }

  var truncateName = (name) => {
    if (name.length > 20) {
      return `${name.substring(0, 10)}...${name.substring(name.length - 10)}`;
    }
    return name;
  };

  const RenderOrder = (item, i) => (
    <div
      className={`ordersTypeHeaderBar activeHeaderBar`}
      key={`${item?.status?.state}-${i}`}
      onClick={(e) => {
        showSingleOrderBar(item);
        threeDotMenuClose();
      }}
      onMouseEnter={(e) => showOrderDescriptionBoxOnHover(e, item)}
      onMouseLeave={(e) => showOrderDescriptionBoxOffHover(e, item)}
      style={orderDescriptionBoxVisibility.display === "block" && headerElement?.orderId === item.orderId ? { background: "rgba(204, 245, 78, 0.10)" } : {}}
    >
      <div className="orderTitleDiv">
        <div className="priorityIconDiv">
          {item?.spec.attributes.priorityLane === 'Normal' && <img className="priorityIcon" src={require("../../ImgAssets/Normal.svg").default} alt=""/>}
          {item?.spec.attributes.priorityLane === 'High' && <img className="priorityIcon" src={require("../../ImgAssets/High.svg").default} alt=""/>}
          {item?.spec.attributes.priorityLane === 'ASAP' && <img className="priorityIcon lowPriorityIcon" src={require("../../ImgAssets/Asap.svg").default} alt=""/>}
        </div>
        <div className="orderTitleName" title={item?.spec?.name} >{truncateName(item?.spec?.name)}</div>
      </div>
      <div
        className="orderThreeDotMenu mouse-cursor-pointer "
        onClick={(e) => {
          threeDotMenuClick(e, item);
        }}
      >
        <img src={require("../../ImgAssets/ThreeDotIcon.svg").default} alt=""/>
        {threeDotOptionsMenu === item?.orderId && isThreeDotOptionsVisible &&
          <ThreeDotMenuComponent
            item={item}
            onMenuClose={threeDotMenuClose}
          />}
      </div>

      {item.status.state === "Current" && (
        <div className="orderProgressDiv mouse-cursor-pointer ">
          <div onMouseEnter={(e) => showHoverBoxNotificationOnHover(e, item, item.status.state)} onMouseLeave={(e) => showHoverBoxNotificationOffHover(e, item)}>
            <CircularProgressBarComponent item={item} />
          </div>
        </div>
      )}
      {item.status.state === "Executed" && (
        <div className="orderProgressDiv mouse-cursor-pointer ">
          <div onMouseEnter={(e) => showHoverBoxNotificationOnHover(e, item, item.status.state)} onMouseLeave={(e) => showHoverBoxNotificationOffHover(e, item)}>
            <img className="pauseIcon" style={{ scale: "1.4", position: "relative", top: "4px" }} src={require("../../ImgAssets/GreenTickSmallIcon.svg").default} alt=""/>
          </div>
        </div>
      )}
      {item.spec.attributes.recurring && (
        <>
          <div
            style={{ display: "contents" }}
            onMouseEnter={(e) => showHoverBoxNotificationOnHover(e, item, "Recurring")}
            onMouseLeave={(e) => showHoverBoxNotificationOffHover(e, item)}
          >
            <img className="pauseIcon recurringIcon" src={require("../../ImgAssets/RecurringIcon.svg").default} alt=""/>
          </div>
        </>
      )}
      {(item.status.state === "Pending") && (
        <>
          <button
            className="orderProgressDivPendingBtn"
            onMouseEnter={(e) => showHoverBoxNotificationOnHover(e, item, item.status.state)}
            onMouseLeave={(e) => showHoverBoxNotificationOffHover(e, item)}
          >
            <img className="pauseIcon orderPendingIcon" src={require("../../ImgAssets/OrderPendingIcon.svg").default} alt=""/>
          </button>
        </>
      )}
      {(item.status.state === "Preview") && (
        <>
          <button
            className="orderProgressDivPendingBtn"
            onMouseEnter={(e) => showHoverBoxNotificationOnHover(e, item, item.status.state)}
            onMouseLeave={(e) => showHoverBoxNotificationOffHover(e, item)}
          >
            <img className="pauseIcon orderPendingIcon" src={require("../../ImgAssets/SingletickIcon.svg").default} alt=""/>
          </button>
        </>
      )}
      <div className="hoverBoxParentContainer">
        {hoverBoxElement?.orderId === item.orderId && (
          <HoverBoxNotification
            headerElement={headerElement}
            hoverBoxNotificationVisibility={hoverBoxNotificationVisibility}
            type={hoverBoxElement.isType}
          />
        )}
      </div>
    </div>
  );

  const getPreviewOrders = (orders) => {
    return orders.filter(order => order.status.state === 'Preview');
  };

  const getPreviewOrdersIds = (orders) => {
    const previewOrdersList = getPreviewOrders(orders);
    return previewOrdersList.map(order => order.orderId);
  };

  const showStatusAndOption = (pendingOrders) => {
    const previewOrders = getPreviewOrdersIds(pendingOrders);
    return (
      <>
        {previewOrders.length === 0 &&
          <>
            <div className="orderThreeDotMenu mouse-cursor-pointer ">
              <img onClick={(e) => {
                threeDotMenuClick(e, {}, 'preview-none');
              }}
                src={require("../../ImgAssets/ThreeDotIcon.svg").default} alt=""/>
              {threeDotOptionsMenu === 'preview-none' && isThreeDotOptionsVisible &&
                <ThreeDotMenuComponent
                  type={'preview-none'}
                  mission_id={mission_id}
                  scenarioId={scenarioId}
                  previewOrders={previewOrders}
                  onMenuClose={threeDotMenuClose}
                />}
            </div>
            <button
              className="orderProgressDivPendingBtn"
              onMouseEnter={(e) => showHoverBoxNotificationOnHover(e, {}, 'preview-none')}
              onMouseLeave={(e) => showHoverBoxNotificationOffHover(e)}
            >
              <img className="pauseIcon orderPendingIcon" src={require("../../ImgAssets/OrderPendingIcon.svg").default} alt=""/>
            </button>
            <div className="hoverBoxParentContainer">
              {hoverBoxElement.isType === 'preview-none' && (
                <HoverBoxNotification
                  hoverBoxNotificationVisibility={hoverBoxNotificationVisibility}
                  type={hoverBoxElement.isType}
                  isOrderLevel={true}
                />
              )}
            </div>
          </>
        }
        {(previewOrders.length !== 0 && previewOrders.length < pendingOrders.length) &&
          <>
            <div className="orderThreeDotMenu mouse-cursor-pointer ">
              <img onClick={(e) => {
                threeDotMenuClick(e, {}, 'preview-partial');
              }}
                src={require("../../ImgAssets/ThreeDotIcon.svg").default} alt=""/>
              {threeDotOptionsMenu === 'preview-partial' && isThreeDotOptionsVisible &&
                <ThreeDotMenuComponent
                  type={'preview-partial'}
                  mission_id={mission_id}
                  scenarioId={scenarioId}
                  previewOrders={previewOrders}
                  onMenuClose={threeDotMenuClose}
                />}
            </div>
            <button
              className="orderProgressDivPendingBtn"
              onMouseEnter={(e) => showHoverBoxNotificationOnHover(e, {}, 'preview-partial')}
              onMouseLeave={(e) => showHoverBoxNotificationOffHover(e)}
            >
              <img className="pauseIcon orderPendingIcon" src={require("../../ImgAssets/OrderPartialPendingIcon.svg").default} alt=""/>
            </button>
            <div className="hoverBoxParentContainer">
              {hoverBoxElement.isType === 'preview-partial' && (
                <HoverBoxNotification
                  hoverBoxNotificationVisibility={hoverBoxNotificationVisibility}
                  type={hoverBoxElement.isType}
                  isOrderLevel={true}
                />
              )}
            </div>
          </>
        }
        {previewOrders.length === pendingOrders.length &&
          <>
            <div className="orderThreeDotMenu mouse-cursor-pointer ">
              <img onClick={(e) => {
                threeDotMenuClick(e, {}, 'preview-all');
              }}
                src={require("../../ImgAssets/ThreeDotIcon.svg").default} alt=""/>
              {threeDotOptionsMenu === 'preview-all' && isThreeDotOptionsVisible &&
                <ThreeDotMenuComponent
                  type={'preview-all'}
                  mission_id={mission_id}
                  scenarioId={scenarioId}
                  previewOrders={previewOrders}
                  onMenuClose={threeDotMenuClose}
                />}
            </div>
            <button
              className="orderProgressDivPendingBtn"
              onMouseEnter={(e) => showHoverBoxNotificationOnHover(e, {}, 'preview-all')}
              onMouseLeave={(e) => showHoverBoxNotificationOffHover(e)}
            >
              <img className="pauseIcon orderPendingIcon" src={require("../../ImgAssets/SingletickIcon.svg").default} alt=""/>
            </button>
            <div className="hoverBoxParentContainer">
              {hoverBoxElement.isType === 'preview-all' && (
                <HoverBoxNotification
                  hoverBoxNotificationVisibility={hoverBoxNotificationVisibility}
                  type={hoverBoxElement.isType}
                  isOrderLevel={true}
                />
              )}
            </div>
          </>
        }
      </>
    );
  };

  return (
    <>
      {props.isOrdersBarVisible && headerElement.status.state !== "" && isOrdersAllOrders && !props.isOrdersBarFeaturesList && (
        <OrderDescriptionBox headerElement={headerElement} orderDescriptionBoxVisibility={orderDescriptionBoxVisibility} ucpViewer={ucpViewer} />
      )}
      {<PreviewSchedule
        mission_id={mission_id}
        scenarioId={scenarioId}
        previewOrders={getPreviewOrdersIds(orders.pending)}
      />}
      {props.isOrdersBarVisible && <FeaturesFilter props={props} ucpViewer={ucpViewer} />}
      {/* {props.isOrdersBarVisible && !isOrdersAllOrders && <SubOrdersBarFeaturesList props={props} />} */}
      {props.isOrdersBarFeaturesList && <OrdersBarFeaturesList props={props} />}
      {props.isOrdersBarVisible && isOrdersAllOrders && (
        <div className="ordersBar">
          <div className="ordersGreenHeaderBar">
            <div className="orders">
              <button className="ordersGreenHeader" onClick={() => setIsOrdersAllOrders(true)}>
                Orders
              </button>
            </div>
            <div className="">
              <button onClick={() => createNewOrder()} className="addNewOrderBtn">
                + Order
              </button>
            </div>
          </div>

          <div className="scrollableOrdersBar">
            <div className="ordersTypeHeaderBar">
              <button onClick={() => {
                activeOrdersSection !== ORDER_STATUS.EXECUTED ?
                  dispatch(setOrdersSection(ORDER_STATUS.EXECUTED)) :
                  dispatch(setOrdersSection(''));
              }
              }
                className={`ordersDropdownArrow ${activeOrdersSection === ORDER_STATUS.EXECUTED ? "activeArrow" : ""}`}
              >
                <img src={require("../../ImgAssets/DropdownArrow.svg").default} alt=""/>
              </button>
              <div className={`ordersTypeHeader ${activeOrdersSection === ORDER_STATUS.EXECUTED ? "activeHeader" : ""}`}>
                Executed Orders <span className="ordersCount">({orders.executed.length})</span>
              </div>
            </div>
            {(activeOrdersSection === ORDER_STATUS.EXECUTED) && <>{orders.executed.length > 0 && orders.executed.map(RenderOrder)}</>}

            <div className="ordersTypeHeaderBar">
              <button
                onClick={() => {
                  activeOrdersSection !== ORDER_STATUS.CURRENT ?
                    dispatch(setOrdersSection(ORDER_STATUS.CURRENT)) :
                    dispatch(setOrdersSection(''));
                }
                }
                className={`ordersDropdownArrow ${activeOrdersSection === ORDER_STATUS.CURRENT ? "activeArrow" : ""}`}
              >
                <img src={require("../../ImgAssets/DropdownArrow.svg").default} alt=""/>
              </button>
              <div className={`ordersTypeHeader ${activeOrdersSection === ORDER_STATUS.CURRENT ? "activeHeader" : ""}`}>
                Current Orders <span className="ordersCount">({orders.current.length})</span>
              </div>

            </div>
            {(activeOrdersSection === ORDER_STATUS.CURRENT) && <>{orders.current.length > 0 && orders.current.map(RenderOrder)}</>}
            <div className="ordersTypeHeaderBar">
              <button onClick={() => {
                activeOrdersSection !== ORDER_STATUS.PENDING ?
                  dispatch(setOrdersSection(ORDER_STATUS.PENDING)) :
                  dispatch(setOrdersSection(''));
              }}
                className={`ordersDropdownArrow ${activeOrdersSection === ORDER_STATUS.PENDING ? "activeArrow" : ""}`}>
                <img src={require("../../ImgAssets/DropdownArrow.svg").default} alt=""/>
              </button>
              <div className={`ordersTypeHeader ${activeOrdersSection === ORDER_STATUS.PENDING ? "activeHeader" : ""}`}>
                Pending Orders <span className="ordersCount">({orders.pending.length})</span>
              </div>
              {orders.pending.length > 0 && showStatusAndOption(orders.pending)}
            </div>
            {(activeOrdersSection === ORDER_STATUS.PENDING) && <>{orders.pending.length > 0 && orders.pending.map(RenderOrder)}</>}
          </div>
        </div>
      )}

      {props.isOrdersBarVisible && !isOrdersAllOrders && (
        <div className={props.isExpandedOrdersBar ? "ordersBar expandedOrdersBar" : "ordersBar"}>
          <div className="ordersGreenHeaderBar singleOrderView">
            <button className="ordersGreenHeader" onClick={() => showAllOrders()}>
              Orders
            </button>
            <img className="dropdownArrowWhiteSpacer" src={require("../../ImgAssets/DropdownArrowWhite.svg").default} alt="" />
            <button className="ordersGreenHeader subOrderHeaderClass activeHeader">
              {headerElement.status.state} Orders
            </button>
          </div>

          <div className="ordersTypeHeaderBar" style={{ height: 'auto', padding: '12px 12px 0px' }}>
            <div className="ordersTypeHeaderTwoLineDiv">
              <div className="ordersTypeHeader" title={headerElement.spec.name}>{truncateName(headerElement.spec.name)}</div>
              <div className="ordersTypeHeader activeHeader">
                SCHEDULE
              </div>
            </div>
            <button className="orderThreeDotMenu">
              <img onClick={() => props.setIsExpandedOrdersBar(!props.isExpandedOrdersBar)} src={require("../../ImgAssets/ThreeDotIcon.svg").default} alt=""/>
            </button>
            <div className="orderThreeDotMenu circularBar mouse-cursor-pointer ">
              <CircularProgressBarComponent item={headerElement} />
            </div>
          </div>
          {headerElement.status.schedule.displayAssignments.length > 0 ?
            <div className="scrollableOrdersBar">
              {!props.isExpandedOrdersBar && (
                <>
                  <RenderSubOrder orderAssignments={headerElement.status.schedule.displayAssignments} ucpViewer={ucpViewer} />
                </>
              )}
              {props.isExpandedOrdersBar && <ExpandedSubOrderTable displayAssingments={headerElement.status.schedule.displayAssignments} />}
            </div>
            :
            <div className='scheduleDescription'>Schedule not generated yet</div>
          }
        </div>
      )}
    </>
  );
};

export default OrdersView;
