import "./CustomModelBottomBarModals.css";
import { useState } from "react";
import { MinimizeIcon, CloseModalIcon, ToggleButtonActiveIcon, ToggleButtonInactiveIcon } from "../../SatelliteProfileImgAssets/SatelliteProfileImgAssets";

const CustomModelControlModeModal = ({ controlModeModalProps, addControlMode }) => {
  const CustomModelModalHeader = ({ headerProps }) => (
    <div className="customModelModalHeader">
      <div className="customModelModalHeaderTitle">{headerProps.title}</div>
      <div className="customModelModalHeaderIcons">
        <button onClick={() => setIsModalMinimized(!isModalMinimized)}>
          <MinimizeIcon />
        </button>
        <button
          onClick={() => {
            controlModeModalProps.setIsAddControlModeModal(false);
          }}
        >
          <CloseModalIcon />
        </button>
      </div>
    </div>
  );

  const SingleInputItem = ({ inputObject }) => (
    <>
      <div className="infocard-component-properties-div">
        <div className="infocard-component-properties-sub-div">
          <span className="infocard-units-location-span">{inputObject.name}</span>
          <div className="infocard-units-location-sub-div">
            <div className="infocard-component-togglebtn-div">
              <div className="infocard-component-value-div">
                <input className="infocard-location-value" type='number' value={'0'}></input>
              </div>
              <div className="infocard-component-units-div">
                <span className="infocard-location-y-axis">{inputObject.unit}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );



  const [rollInput, setRollInput] = useState({ name: "Roll", value: '2', unit: "deg" });
  const [pitchInput, setPitchInput] = useState({ name: "Pitch", value: '4', unit: "deg" });
  const [yawInput, setYawInput] = useState({ name: "Yaw", value: '8', unit: "deg" });

  const [orientation, setOrientation] = useState({ x: '0', y: '0', z: '0' })



  const [isModalMinimized, setIsModalMinimized] = useState(false);
  const [headerObject, setHeaderObject] = useState({ title: "Add Control Mode", isModalMinimized: isModalMinimized, setIsModalMinimized: setIsModalMinimized });

  const [isDynamic, setIsDynamic] = useState(false);
  const [name, setName] = useState('')

  return (
    <>
      <div className="customModelControlModeModal">
        <CustomModelModalHeader headerProps={headerObject} />
        {!isModalMinimized && (
          <div className="customModelModalBody">
            {/* Name of control mode input */}
            <div className="infocard-component-properties-div">
              <div className="infocard-component-properties-sub-div">
                <span className="infocard-units-location-span">Name</span>
                <div className="infocard-units-location-sub-div">
                  <input className="infocard-input-long-type" type="text" placeholder="CM Name" value={name} onChange={(e) => {
                    setName(e.target.value)
                  }}></input>
                </div>
              </div>
            </div>

            <div className="greenModalFooter"></div>

            <div className="infocard-component-properties-div">
              <div className="infocard-component-properties-sub-div">
                <span className="infocard-units-location-span">ORIENTATION</span>
                <div className="infocard-units-location-sub-div">
                  <span className="infocard-units-location-span">Custom</span>
                  <div className="infocard-units-location-sub-div">
                    <button style={{ marginRight: '0px' }} onClick={() => setIsDynamic(!isDynamic)}>{isDynamic ? <ToggleButtonActiveIcon /> : <ToggleButtonInactiveIcon />}</button>
                  </div>
                </div>
              </div>
            </div>

            {!isDynamic && (
              <>
                <>
                  <div className="infocard-component-properties-div">
                    <div className="infocard-component-properties-sub-div">
                      <span className="infocard-units-location-span">{rollInput.name}</span>
                      <div className="infocard-units-location-sub-div">
                        <div className="infocard-component-togglebtn-div">
                          <div className="infocard-component-value-div">
                            <input
                              className="infocard-location-value"
                              type='number'
                              value={rollInput.value}
                              onChange={(e) => {
                                setRollInput({
                                  ...rollInput,
                                  value: e.target.value
                                })
                              }}></input>
                          </div>
                          <div className="infocard-component-units-div">
                            <span className="infocard-location-y-axis">{rollInput.unit}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                <>
                  <div className="infocard-component-properties-div">
                    <div className="infocard-component-properties-sub-div">
                      <span className="infocard-units-location-span">{pitchInput.name}</span>
                      <div className="infocard-units-location-sub-div">
                        <div className="infocard-component-togglebtn-div">
                          <div className="infocard-component-value-div">
                            <input
                              className="infocard-location-value"
                              type='number'
                              onChange={(e) => {
                                setPitchInput({
                                  ...pitchInput,
                                  value: e.target.value
                                })
                              }}
                              value={pitchInput.value}></input>
                          </div>
                          <div className="infocard-component-units-div">
                            <span className="infocard-location-y-axis">{pitchInput.unit}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                <>
                  <div className="infocard-component-properties-div">
                    <div className="infocard-component-properties-sub-div">
                      <span className="infocard-units-location-span">{yawInput.name}</span>
                      <div className="infocard-units-location-sub-div">
                        <div className="infocard-component-togglebtn-div">
                          <div className="infocard-component-value-div">
                            <input
                              className="infocard-location-value"
                              type='number'
                              onChange={(e) => {
                                setYawInput({
                                  ...yawInput,
                                  value: e.target.value
                                })
                              }}
                              value={yawInput.value}
                            ></input>
                          </div>
                          <div className="infocard-component-units-div">
                            <span className="infocard-location-y-axis">{yawInput.unit}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </>
            )}

            {isDynamic && (
              <div className="infocard-units-location-value-div">
                <span className="infocard-units-location-span">Orientation</span>
                <div className="infocard-three-inputs-div">
                  <div className="infocard-location-div">
                    <div className="infocard-location-value-div">
                      <input
                        className="infocard-location-value"
                        type='number'
                        value={orientation.x}
                        onChange={(e) => {
                          setOrientation({
                            ...orientation,
                            x: e.target.value
                          })
                        }}></input>
                    </div>
                    <div className="infocard-location-x-axis-div">
                      <span className="infocard-location-x-axis">x</span>
                    </div>
                  </div>
                  <div className="infocard-location-div">
                    <div className="infocard-location-value-div">
                      <input
                        className="infocard-location-value"
                        type='number'
                        value={orientation.y}
                        onChange={(e) => {
                          setOrientation({
                            ...orientation,
                            y: e.target.value
                          })
                        }}></input>
                    </div>
                    <div className="infocard-location-x-axis-div">
                      <span className="infocard-location-y-axis">y</span>
                    </div>
                  </div>
                  <div className="infocard-location-div">
                    <div className="infocard-location-value-div">
                      <input
                        className="infocard-location-value"
                        type='number'
                        value={orientation.z}
                        onChange={(e) => {
                          setOrientation({
                            ...orientation,
                            z: e.target.value
                          })
                        }}></input>
                    </div>
                    <div className="infocard-location-x-axis-div">
                      <span className="infocard-location-z-axis">z</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="customModelModalFooter">
              <button className="" onClick={() => {
                let orientations = isDynamic ? [parseFloat(orientation.x), parseFloat(orientation.y), parseFloat(orientation.z)] : [parseFloat(rollInput.value), parseFloat(pitchInput.value), parseFloat(yawInput.value)]
                let body = {
                  "name": name,
                  "custom": !isDynamic,
                  "editable": true,
                  "orientation": orientations
                }
                addControlMode(body)
              }}>ADD</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomModelControlModeModal;
