import { useNavigate, useParams } from "react-router-dom";
import { BackButton, ModelSvg } from "../../../commonMMSvgs/MissionModellingSvgs";
import "./backToHome.css"

const BackToHomePanel = () => {
    const params = useParams()
    const {
        mission_name,
        mission_id
    } = params;
    const navigate = useNavigate();

    const navigate_to_home = () => {
        // from here we can navigate to the home
        navigate(`${process.env.PUBLIC_URL}/${mission_name}/_details/${mission_id}/Modelling`)
    }

    return (
        <div className="backpanel">
            <div className="svgContainerBackPanel pointing_cursor" onClick={() => { navigate_to_home() }}>
                <BackButton />
            </div>
            <div className="svgContainerBackPanel">
                <ModelSvg />
            </div>
            <div className="modelHeadingStyle">
                <span>Model</span>
            </div>
        </div>
    );
};

export default BackToHomePanel;
