import React, { useEffect } from "react";
import { useState } from "react";
import "./CustomModelInfoCard.css";

import {
  CopyIcon,
  ReplaceIcon,
  TrashIcon,
  EditIcon,
  ToggleButtonInactiveIcon,
  ToggleButtonActiveIcon,
  ShowReportIcon,
  PlusIcon,
  WhiteCrossIcon,
  ReportIcon,
} from "../SatelliteProfileImgAssets/SatelliteProfileImgAssets";

import ModalDropdown from "../../../../MissionModelling/commonComponents/ModalDropdown";
import { ButtonToolbar, OverlayTrigger } from "react-bootstrap";
import { Loader } from "rsuite";
import { toast } from "react-toastify";

const CustomModelComponentInfoCard = ({ reportProps, orientation, onOrientationChange, selectedSensor, controlModes, startSensorAnalysis, loading, setIsReportBarOpen, isReportBarOpen }) => {
  const [isComponentInfoCardActive, setIsComponentInfoCardActive] = useState<boolean>(true);
  const [isMass, setIsMass] = useState(false);
  const [isSize, setIsSize] = useState(false);
  const [isExclusionAngle, setIsExclusionAngle] = useState(false);

  const [duration, setDuration] = useState('1')

  const handleOrientationChange = (axis, value) => {
    // Update the orientation state
    onOrientationChange({ ...orientation, [axis]: value });
  };

  const InfoCardReportActions = () => (
    <div className="infoCardReportActionsDiv">
      <div>
        <CopyIcon />
      </div>
      <div>
        <ReplaceIcon />
      </div>
      <div>
        <EditIcon />
      </div>
      <div>
        <TrashIcon />
      </div>
    </div>
  );

  const [massInput, setMassInput] = useState({ name: "Mass", isEnabled: isMass, isEnabledFunction: setIsMass, value: '2', unit: "g" });
  const [sizeInput, setSizeInput] = useState({ name: "Size", isEnabled: isSize, isEnabledFunction: setIsSize, value: '4', unit: "U" });
  const [fovInput, setFovInput] = useState({ name: "FOV", value: '9', unit: "deg" });

  const [sunAngle, setSunAngle] = useState({ name: "Sun", value: '22', unit: "deg" });
  const [moonAngle, setMoonAngle] = useState({ name: "Moon", value: '18', unit: "deg" });
  const [earthAngle, setEarthAngle] = useState({ name: "Earth", value: '16', unit: "deg" });

  const controlModeOptions = [{ label: "Sun", value: "Sun" }, { label: "Earth", value: "Earth" }];
  const [selectedModeOption, setSelectedModeOption] = useState<any>("Sun")

  const [controlModeObjects, setControlModeObjects] = useState<any>([]);
  const [showControlModes, setShowcontrolModes] = useState(false)


  useEffect(() => {
    if (selectedSensor) {
      console.log("Selected Sensor -> ", selectedSensor)
      console.log("Selected Sensor mode -> ", controlModes)
      setMassInput({
        ...massInput,
        isEnabled: selectedSensor?.massBool,
        value: selectedSensor?.mass
      })
      setSizeInput({
        ...sizeInput,
        isEnabled: selectedSensor?.sizeBool,
        value: selectedSensor?.size
      })
      setFovInput({
        ...fovInput,
        value: selectedSensor?.fov
      })
      onOrientationChange({
        x: selectedSensor.orientation[0],
        y: selectedSensor.orientation[1],
        z: selectedSensor.orientation[2]
      })

      setIsExclusionAngle(selectedSensor?.exclusionAngleBool)
      setSunAngle({
        ...sunAngle,
        value: selectedSensor?.exclusionAngleSunEarthMoon[0]
      })
      setEarthAngle({
        ...earthAngle,
        value: selectedSensor?.exclusionAngleSunEarthMoon[1]
      })
      setMoonAngle({
        ...moonAngle,
        value: selectedSensor?.exclusionAngleSunEarthMoon[2]
      })

    }
  }, [selectedSensor])

  return (
    <div className={`custom-model-infocard   ${isComponentInfoCardActive ? "active" : ""} ${reportProps.isReportBarOpen ? "isReportOpen" : ""}`} id="create_model_infocard">
      <div className="infocard-label-div">
        <span className="infocard-component-label">{selectedSensor?.name}</span>
        <InfoCardReportActions />
      </div>
      <div className="infocard-properties-div">
        <div className="infocard-component-properties-div">
          <>
            <div className="infocard-component-properties-div">
              <div className="infocard-component-properties-sub-div">
                <span className="infocard-units-location-span">{massInput.name}</span>
                <div className="infocard-units-location-sub-div">
                  {massInput.isEnabledFunction && (
                    <button onClick={() => massInput?.isEnabledFunction(!massInput?.isEnabled)}>
                      {massInput?.isEnabled ? <ToggleButtonActiveIcon /> : <ToggleButtonInactiveIcon />}
                    </button>
                  )}
                  <div className="infocard-component-togglebtn-div">
                    <div className="infocard-component-value-div">
                      <input className="infocard-location-value" type='number'
                        onChange={(e) => {
                          setMassInput({
                            ...massInput,
                            value: e.target.value
                          })
                        }}
                        value={massInput.value}></input>
                    </div>
                    <div className="infocard-component-units-div">
                      <span className="infocard-location-y-axis">{massInput.unit}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          <>
            <div className="infocard-component-properties-div">
              <div className="infocard-component-properties-sub-div">
                <span className="infocard-units-location-span">{sizeInput.name}</span>
                <div className="infocard-units-location-sub-div">
                  {sizeInput.isEnabledFunction && (
                    <button onClick={() => sizeInput?.isEnabledFunction(!sizeInput?.isEnabled)}>
                      {sizeInput?.isEnabled ? <ToggleButtonActiveIcon /> : <ToggleButtonInactiveIcon />}
                    </button>
                  )}
                  <div className="infocard-component-togglebtn-div">
                    <div className="infocard-component-value-div">
                      <input className="infocard-location-value" type='number'
                        onChange={(e) => {
                          setSizeInput({
                            ...sizeInput,
                            value: e.target.value
                          })
                        }}
                        value={sizeInput.value}></input>
                    </div>
                    <div className="infocard-component-units-div">
                      <span className="infocard-location-y-axis">{sizeInput.unit}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>


          <div className="greenModalFooter"></div>

          <div className="infocard-units-location-value-div">
            <span className="infocard-units-location-span">Orientation</span>
            <div className="infocard-three-inputs-div">
              <div className="infocard-location-div">
                <div className="infocard-location-value-div">
                  {/* <span className="infocard-location-value"
                    onClick={(e) => handleOrientationChange('x', parseFloat(e.target.textContent))}>{orientation['x']}
                  </span> */}
                  <input
                    type="number"
                    className="infocard-location-value"
                    value={orientation['x']}
                    onChange={(e) => handleOrientationChange('x', parseFloat(e.target.value))}
                  />
                </div>
                <div className="infocard-location-x-axis-div">
                  <span className="infocard-location-x-axis">x</span>
                </div>
              </div>
              <div className="infocard-location-div">
                <div className="infocard-location-value-div">
                  {/* <span className="infocard-location-value">0</span> */}
                  <input
                    type="number"
                    className="infocard-location-value"
                    value={orientation['y']}
                    onChange={(e) => handleOrientationChange('y', parseFloat(e.target.value))}
                  />
                </div>
                <div className="infocard-location-x-axis-div">
                  <span className="infocard-location-y-axis">y</span>
                </div>
              </div>
              <div className="infocard-location-div">
                <div className="infocard-location-value-div">
                  {/* <span className="infocard-location-value">0</span> */}
                  <input
                    type="number"
                    className="infocard-location-value"
                    value={orientation['z']}
                    onChange={(e) => handleOrientationChange('z', parseFloat(e.target.value))}
                  />
                </div>
                <div className="infocard-location-x-axis-div">
                  <span className="infocard-location-z-axis">z</span>
                </div>
              </div>
            </div>
          </div>


          <>
            <div className="infocard-component-properties-div">
              <div className="infocard-component-properties-sub-div">
                <span className="infocard-units-location-span">{fovInput.name}</span>
                <div className="infocard-units-location-sub-div">

                  <div className="infocard-component-togglebtn-div">
                    <div className="infocard-component-value-div">
                      <input className="infocard-location-value" type='number' onChange={(e) => {
                        setFovInput({
                          ...fovInput,
                          value: e.target.value
                        })
                      }} value={fovInput.value}></input>
                    </div>
                    <div className="infocard-component-units-div">
                      <span className="infocard-location-y-axis">{fovInput.unit}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>

          <div className="infocard-component-properties-div">
            <div className="infocard-component-properties-sub-div not-space-between">
              <span className="infocard-units-location-span">Exclusion Angle</span>
              <div className="infocard-units-location-sub-div">
                <button onClick={() => setIsExclusionAngle(!isExclusionAngle)}>{isExclusionAngle ? <ToggleButtonActiveIcon /> : <ToggleButtonInactiveIcon />}</button>
              </div>
            </div>
          </div>
        </div>

        {isExclusionAngle && (
          <>
            <>
              <div className="infocard-component-properties-div">
                <div className="infocard-component-properties-sub-div">
                  <span className="infocard-units-location-span">{sunAngle.name}</span>
                  <div className="infocard-units-location-sub-div">

                    <div className="infocard-component-togglebtn-div">
                      <div className="infocard-component-value-div">
                        <input className="infocard-location-value" type='number' onChange={(e) => {
                          setSunAngle({
                            ...sunAngle,
                            value: e.target.value
                          })
                        }} value={sunAngle.value}></input>
                      </div>
                      <div className="infocard-component-units-div">
                        <span className="infocard-location-y-axis">{sunAngle.unit}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <>
              <div className="infocard-component-properties-div">
                <div className="infocard-component-properties-sub-div">
                  <span className="infocard-units-location-span">{moonAngle.name}</span>
                  <div className="infocard-units-location-sub-div">

                    <div className="infocard-component-togglebtn-div">
                      <div className="infocard-component-value-div">
                        <input className="infocard-location-value" type='number'
                          onChange={(e) => {
                            setMoonAngle({
                              ...moonAngle,
                              value: e.target.value
                            })
                          }}
                          value={moonAngle.value}></input>
                      </div>
                      <div className="infocard-component-units-div">
                        <span className="infocard-location-y-axis">{moonAngle.unit}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <>
              <div className="infocard-component-properties-div">
                <div className="infocard-component-properties-sub-div">
                  <span className="infocard-units-location-span">{earthAngle.name}</span>
                  <div className="infocard-units-location-sub-div">

                    <div className="infocard-component-togglebtn-div">
                      <div className="infocard-component-value-div">
                        <input className="infocard-location-value" type='number'
                          onChange={(e) => {
                            setEarthAngle({
                              ...earthAngle,
                              value: e.target.value
                            })
                          }}
                          value={earthAngle.value}></input>
                      </div>
                      <div className="infocard-component-units-div">
                        <span className="infocard-location-y-axis">{earthAngle.unit}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <div className="greenModalFooter"></div>
          </>
        )}

        <div className="infocard-component-properties-div">
          <>
            <div className="infocard-component-properties-div">
              <div className="infocard-component-properties-sub-div">
                <span className="infocard-units-location-span">{"Duration"}</span>
                <div className="infocard-units-location-sub-div">

                  <div className="infocard-component-togglebtn-div">
                    <div className="infocard-component-value-div">
                      <input className="infocard-location-value" type='number' onChange={(e) => {
                        setDuration(e.target.value)
                      }} value={duration}></input>
                    </div>
                    <div className="infocard-component-units-div">
                      <span className="infocard-location-y-axis">{"year"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>


          <div className="infocard-component-properties-sub-div">
            <span className="infocard-units-location-span">Entity</span>
            <div className="infocard-units-location-sub-div">
              <div className="infocard-component-togglebtn-div" style={{ width: "auto" }}>
                <ModalDropdown options={controlModeOptions} isMulti={false} handleSelectedOption={(e) => setSelectedModeOption(e.label)} previousOption={selectedModeOption} />
              </div>
            </div>
          </div>
        </div>

        <div className="infocard-component-properties-div">
          <div className="infocard-component-properties-sub-div">
            <span className="infocard-units-location-span">Control Mode</span>
          </div>
        </div>

        <div className="infocard-component-properties-div controlModeObjectsDiv">
          {controlModeObjects && controlModeObjects?.map((item) =>
            <div className="controlModeObjectsButton">
              <button className="controlModeObjectsButton">{item.title}</button>
              <button className="controlModeObjectsButton" onClick={() => {
                setControlModeObjects([])
              }}>
                <WhiteCrossIcon />
              </button>
            </div>

          )}

          {controlModeObjects.length === 0 && <ButtonToolbar key={'control__mode__dropdown'}>
            <OverlayTrigger delay={0} placement="bottom" show={showControlModes} trigger='click' onToggle={setShowcontrolModes}
              overlay={(
                <div className={`control__mode__dropdown__container`}>
                  {controlModes?.map((mode) => (
                    <button onClick={() => {
                      setShowcontrolModes(!showControlModes)
                      let controlMode: any = []
                      controlMode = controlModeObjects
                      controlMode.push({ title: mode.name, value: mode })
                      setControlModeObjects(controlMode)

                    }} className={`dropdown__item__container`}>
                      <span className="dropdown__item__title">{mode.name}</span>
                      <span className="dropdown__item__details">{!mode?.custom ? 'Dynamic' : mode.orientation?.join(",") + " deg"}</span>
                    </button>
                  ))
                  }
                </div>
              )}>
              <button className="controlModeObjectsButton"><PlusIcon /></button>
            </OverlayTrigger>
          </ButtonToolbar>}
        </div>

      </div>
      <div className="report_icon_alignment" onClick={() => {
        setIsReportBarOpen(!isReportBarOpen)
      }}>
        <ReportIcon />
      </div>
      <button className="reportSwitchOnInfocardBtn" onClick={() => {

        // reportProps.setIsReportBarOpen(!reportProps.isReportBarOpen) 

        if (loading) {
          return
        }



        let sensor = {
          "name": selectedSensor.name,
          "editable": false,
          "massBool": massInput.isEnabled,
          "sizeBool": sizeInput.isEnabled,
          "exclusionAngleBool": isExclusionAngle,
          "mass": massInput.value,
          "size": sizeInput.value,
          "orientation": [
            orientation.x,
            orientation.y,
            orientation.z
          ],
          "fov": fovInput.value,
          "sensorType": selectedSensor.sensorType,
          "exclusionAngleSunEarthMoon": [
            parseFloat(sunAngle.value),
            parseFloat(earthAngle.value),
            parseFloat(moonAngle.value)
          ]
        }
        let controlModess: any = [];

        controlModeObjects?.map((controlMode) => {
          controlModess.push(controlMode.value)
        })

        if (controlModess.length === 0) {
          toast.error("Please Add Control Mode", { toastId: 0 })
          return
        }

        let data = {
          "entity": selectedModeOption,
          "duration": parseFloat(duration),
          "controlModes": controlModess,
          "sensorsConfigurations": [sensor]
        }

        startSensorAnalysis(data)
      }}>
        {!loading ? <ShowReportIcon /> :
          <Loader />}
      </button>
    </div>
  );
};

export default CustomModelComponentInfoCard;
