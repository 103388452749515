import { ReactNode, createContext, useEffect, useState } from "react";
import { AllOnBoardSchedule, ScheduleTask } from "../hooks/useOnBoardSchedule";
import { ContactStation } from "../hooks/useGroundContact";
import CommandCenterService from "../../../service/CommandCenterService";
import useScenario from "../hooks/useScenario";
import useUrlParams from "../hooks/useUrlParams";
import { toast } from "react-toastify";

interface ContactsContextType {
    selectedScheduleTask: {
        default_schedule: boolean;
        schedule_data: ScheduleTask;
    };
    setSelectedScheduleTask: (data: {
        default_schedule: boolean;
        schedule_data: ScheduleTask;
    }) => void;
    selectedContact: {
        contact_type: string;
        contact_data: ContactStation,
    } | null;
    setSelectedContact: (data: {
        contact_type: string;
        contact_data: ContactStation,
    } | null) => void;
    contacts: {
        previous_contact: null | ContactStation,
        ongoing_contact: null | ContactStation,
        upcoming_contact: null | ContactStation
        current_time: null | number,
    }
    setContacts: (data: {
        previous_contact: null | ContactStation,
        ongoing_contact: null | ContactStation,
        upcoming_contact: null | ContactStation
        current_time: null | number,
    }) => void;
    timelineContactData: ContactStation[];
    planingContactData: ContactStation[];
    scheduleData: ScheduleTask[];
    timelineScheduleData: ScheduleTask[];
    scheduleDetails: {
        start_time: number,
        name: string,
    };
    allOnBoardSchedule: AllOnBoardSchedule;
    selectedSchedule: null | string;
    select_schedule: (schedule: any) => void;
    generate_schedule: (schedule: any) => Promise<void>;
    send_operate_task_command: (task_type: string, execute_now: boolean, payload: any) => Promise<void>;
    send_operate_tc_command: (contact_id: string, execute_now: boolean, payload: any) => Promise<void>;
    delete_onboard_schedule: (schedule: any) => Promise<void>;
    get_time_based_gs_contact_details: (scenarioId: string, startTime: number, endTime: number, mode: string) => void;
    import_schedule: any
    getTcCommand: null | any;
}

export const ContactsContext = createContext<ContactsContextType | undefined>(undefined);

interface ContactsProviderProps {
    children: ReactNode;
}

const ContactsProvider = ({ children }: ContactsProviderProps) => {
    const { scenario } = useScenario()
    const { scenario_id, truetwin_id, dashboard } = useUrlParams()
    const [selectedScheduleTask, setSelectedScheduleTask] = useState<{
        default_schedule: boolean;
        schedule_data: ScheduleTask;
    }>({
        default_schedule: true,
        schedule_data: {} as ScheduleTask,
    })

    const [selectedSchedule, setSelectedSchedule] = useState<null | any>(null)

    const [selectedContact, setSelectedContact] = useState<
        {
            contact_type: string;
            contact_data: ContactStation,
        } | null
    >(null)
    const [contacts, setContacts] = useState<{
        previous_contact: null | ContactStation,
        ongoing_contact: null | ContactStation,
        upcoming_contact: null | ContactStation
        current_time: null | number,
    }>({
        previous_contact: null,
        ongoing_contact: null,
        upcoming_contact: null,
        current_time: null,
    })

    const [timelineContactData, setTimelineContactData] = useState<ContactStation[]>([])
    const [planingContactData, setPlaningContactData] = useState<any>(null)
    const [allOnBoardSchedule, setAllOnBoardSchedule] = useState<AllOnBoardSchedule>({} as AllOnBoardSchedule)
    const [scheduleData, setScheduleData] = useState<ScheduleTask[]>([])
    const [timelineScheduleData, setTimelineScheduleData] = useState<ScheduleTask[]>([])
    const [scheduleDetails, setScheduleDetails] = useState<{
        start_time: number,
        name: string,
    }>({
        start_time: 0,
        name: '',
    })
    const [getTcCommand, setGetTcCommand] = useState<any>()

    const structure_schedule_date = (schedule: any) => {
        let updated_data: ScheduleTask[] = schedule && schedule.map(task => {
            return {
                ...task,
                "properties": {
                    ...task?.['properties'],
                    "extraDetails": task?.properties?.['extraDetails'] ?? task?.properties?.['ExtraDetails'],
                    "freqBand": task?.properties?.['freqBand'] ?? task?.properties?.['FreqBand'],
                    "groundStationName": task?.properties?.['groundStationName'] ?? task?.properties?.['GroundStationName'],
                    "payloadName": task?.properties?.['payloadName'] ?? task?.properties?.['PayloadName'],
                    "sequenceName": task?.properties?.['sequenceName'] ?? task?.properties?.['SequenceName'],
                }
            }
        })
        return updated_data
    }

    const select_schedule = (schedule: any) => {
        const Data = schedule?.['ScheduledTasks']
        setScheduleDetails({
            start_time: new Date(schedule?.['StartTime']).getTime() / 1000,
            name: schedule?.['ScheduleName'],
        })
        const schedule_data = structure_schedule_date(Data)
        setScheduleData(schedule_data)
        setTimelineScheduleData(schedule_data)
        setSelectedSchedule(schedule?.['ScheduleId'])
    }

    const get_timeline_onboard_schedule_data = () => {
        CommandCenterService.getOnBoardScheduleData(scenario?.['simulationId']).then((res) => {
            if (res?.data) {
                const Data = res.data
                setScheduleData(Data)
                setTimelineScheduleData(Data)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const get_ongoing_schedule = () => {
        if (dashboard === 'operate') {
            CommandCenterService.getOngoingSchedule(scenario_id).then((res) => {
                if (res?.data) {
                    select_schedule(res?.data)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const get_all_onboard_schedules = () => {
        if (dashboard === 'operate') {
            CommandCenterService.getAllOnBoardSchedules(scenario_id).then((res) => {
                if (res?.data) {
                    const Data = res?.data
                    setAllOnBoardSchedule(Data)
                    if (Data?.['Ongoing']?.length > 0) {
                        select_schedule(Data?.['Ongoing']?.[0])
                    } else {
                        select_schedule(Data?.['Planned']?.[0])
                    }

                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const get_onboard_schedule_data = () => {
        if (dashboard !== 'operate') {
            CommandCenterService.getSimulationStatus(scenario?.['simulationId']).then((res) => {
                if (res?.data) {
                    const Data = res?.data?.['taskList']
                    setScheduleDetails({
                        start_time: res?.data?.['simulation_start_epoch'],
                        name: res?.data?.['scheduleName'],
                    })
                    const schedule_data = structure_schedule_date(Data)
                    setScheduleData(schedule_data)
                    setTimelineScheduleData(schedule_data)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const get_gs_contact_details = () => {
        if (dashboard !== 'operate') {
            CommandCenterService.getGroundContacts(scenario_id).then((res) => {
                if (res?.['data']?.['groundStations']) {
                    const ground_tations = res?.['data']?.['groundStations']
                    setTimelineContactData(ground_tations)
                    const all_stations: any = {
                        stations: {},
                        contacts: {}
                    }
                    ground_tations.map(contact => {
                        if (all_stations.contacts[contact?.['groundStationId']]) {
                            all_stations.contacts[contact?.['groundStationId']].push(contact)
                        } else {
                            all_stations.stations[contact?.['groundStationId']] = contact?.['groundStationName']
                            all_stations.contacts[contact?.['groundStationId']] = [contact]
                        }
                    })
                    setPlaningContactData(all_stations)
                }
            }).catch(() => {
                console.error('Failed to get contact details.');
            })
        }
    }

    const get_time_based_gs_contact_details = (scenarioId: string, startTime: number, endTime: number, mode: string) => {
        CommandCenterService.getTimeBasedGroundContacts(scenarioId, startTime, endTime, mode).then((res) => {
            if (res?.['data']) {
                setTimelineContactData(res?.['data'])
                const all_stations: any = {
                    stations: {},
                    contacts: {}
                }
                res?.['data'].map(contact => {
                    if (all_stations.contacts[contact?.['groundStationId']]) {
                        all_stations.contacts[contact?.['groundStationId']].push(contact)
                    } else {
                        all_stations.stations[contact?.['groundStationId']] = contact?.['groundStationName']
                        all_stations.contacts[contact?.['groundStationId']] = [contact]
                    }
                })
                setPlaningContactData(all_stations)
            }
        }).catch((error) => {
            console.error('Failed to get contact details.');
        })
    }

    const generate_schedule = (payload: any): Promise<string | any> => {
        return new Promise((resolve, reject) => {
            CommandCenterService.generateOperateSchedule(payload)
                .then((response) => {
                    if (response) {
                        // Assuming these are async functions, we should wait for them to complete
                        return Promise.all([
                            get_all_onboard_schedules(),
                            get_ongoing_schedule()
                        ]).then(() => {
                            resolve('success');
                        });
                    } else {
                        resolve('No response received');
                    }
                })
                .catch((err) => {
                    console.error('Error generating schedule:', err);
                    reject(err?.response?.data || 'An error occurred');
                });
        });
    };

    const import_schedule = (payload: any, name: any): Promise<string | any> => {
        return new Promise((resolve, reject) => {
            CommandCenterService.importOperateSchedule(scenario_id, payload, name)
                .then((response) => {
                    if (response) {
                        // Assuming these are async functions, we should wait for them to complete
                        return Promise.all([
                            get_all_onboard_schedules(),
                            get_ongoing_schedule()
                        ]).then(() => {
                            toast.success('Schedule imported successfully', { toastId: '0' })
                            resolve('success');
                        });
                    } else {
                        resolve('No response received');
                    }
                })
                .catch((err) => {
                    console.error('Error generating schedule:', err);
                    reject(err?.response?.data || 'An error occurred');
                });
        });
    };

    const send_operate_task_command = (task_type: string, execute_now: boolean, payload: any): Promise<string | any> => {
        return new Promise((resolve, reject) => {
            if (task_type === 'adhoc') {
                const contact_id = payload?.contactId
                const task_id = payload?.taskId
                CommandCenterService.postOperateAdhocTaskCommand(contact_id, task_id, scenario_id, truetwin_id, execute_now)
                    .then((response) => {
                        if (response) {
                            // Assuming these are async functions, we should wait for them to complete
                            return Promise.all([
                                get_all_onboard_schedules(),
                                get_ongoing_schedule()
                            ]).then(() => {
                                resolve('success');
                            });
                        } else {
                            resolve('No response received');
                        }
                    })
                    .catch((err) => {
                        console.error('Error generating schedule:', err);
                        reject(err?.response?.data || 'An error occurred');
                    });
            } else if (task_type === 'schedule') {
                CommandCenterService.postOperateScheduleTaskCommand(payload)
                    .then((response) => {
                        if (response) {
                            // Assuming these are async functions, we should wait for them to complete
                            return Promise.all([
                                get_all_onboard_schedules(),
                                get_ongoing_schedule()
                            ]).then(() => {
                                resolve('success');
                            });
                        } else {
                            resolve('No response received');
                        }
                    })
                    .catch((err) => {
                        console.error('Error generating schedule:', err);
                        reject(err?.response?.data || 'An error occurred');
                    });
            } else {
                reject('Invalid task type');
            }
        });
    };

    const send_operate_tc_command = (contact_id: string, execute_now: boolean, payload: any): Promise<string | any> => {
        return new Promise((resolve, reject) => {
            CommandCenterService.postOperateTcCommand(contact_id, scenario_id, execute_now, payload)
                .then((response) => {
                    if (response) {
                        // Assuming these are async functions, we should wait for them to complete
                        return Promise.all([
                            get_all_onboard_schedules(),
                            get_ongoing_schedule()
                        ]).then(() => {
                            resolve('success');
                        });
                    } else {
                        resolve('No response received');
                    }
                })
                .catch((err) => {
                    console.error('Error generating schedule:', err);
                    reject(err?.response?.data || 'An error occurred');
                });
        });
    };

    const delete_onboard_schedule = (scheduleId: string): Promise<string | any> => {
        return new Promise((resolve, reject) => {
            CommandCenterService.deleteOnboardSchedules(scenario_id, scheduleId)
                .then((response) => {
                    if (response) {
                        // Assuming these are async functions, we should wait for them to complete
                        return Promise.all([
                            get_all_onboard_schedules(),
                            get_ongoing_schedule()
                        ]).then(() => {
                            resolve('success');
                        });
                    } else {
                        resolve('No response received');
                    }
                })
                .catch((err) => {
                    console.error('Error generating schedule:', err);
                    reject(err?.response?.data || 'An error occurred');
                });
        });
    };


    useEffect(() => {
        if (scenario_id) {
            get_gs_contact_details()
            get_ongoing_schedule()
            get_all_onboard_schedules()
        }
    }, [scenario_id])


    useEffect(() => {
        if (scenario?.['simulationId']) {
            // get_timeline_onboard_schedule_data()
            get_onboard_schedule_data()
        }
    }, [scenario])


    return (
        <ContactsContext.Provider
            value={{
                contacts,
                setContacts,
                scheduleData,
                scheduleDetails,
                planingContactData,
                timelineContactData,
                timelineScheduleData,
                selectedContact,
                selectedScheduleTask,
                setSelectedScheduleTask,
                setSelectedContact,
                allOnBoardSchedule,
                selectedSchedule,
                select_schedule,
                generate_schedule,
                delete_onboard_schedule,
                send_operate_tc_command,
                send_operate_task_command,
                get_time_based_gs_contact_details,
                getTcCommand,
                import_schedule
            }}>
            {children}
        </ContactsContext.Provider>
    )

}

export default ContactsProvider;