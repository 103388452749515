import { useNavigate } from "react-router-dom";
import { navigateTo } from "../../CommonComponents/CommonFunctions"
import useUrlParams from "./useUrlParams"

const useUrlNavigation = () => {

    const navigate = useNavigate();
    const { mission_name, mission_id, sat_name, sat_id, truetwin_name, truetwin_id, dashboard, scenario_name, scenario_id } = useUrlParams()

    const navigate_to = (tab: string) => {
        const search_params = window.location.search ? `${window.location.search}` : ""
        navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/${truetwin_name}/${truetwin_id}/${dashboard}/${scenario_name}/${scenario_id}/${tab}${search_params}`, navigate)
    }
    const navigate_to_truetwin_details = () => {
        navigateTo(
            `${mission_name}/${mission_id}/${sat_name}/${sat_id}/truetwin_details/${truetwin_name}/${truetwin_id}/${dashboard === 'quick-deploy' ? 'Configuration' : 'Scenarios'}`,
            navigate
        );
    }
    const navigate_to_operate_dashboard = () => {
        navigateTo(
            `${mission_name}/${mission_id}/operate-dashboard`,
            navigate
        );
    }


    const navigate_dashboard = () => {
        if (dashboard === 'operate') {
            navigate_to_operate_dashboard()
        } else {
            navigate_to_truetwin_details()
            sessionStorage.setItem("multiplierValue", "1")
        }
    }

    return { navigate_to, navigate_dashboard }
}
export default useUrlNavigation