
import { useEffect, useState } from 'react';
import CommandCenter from './components/center/CommandCenter'
import Footer from './components/footer/Footer'
import Header from './components/header/Header'
import useUrlParams from './hooks/useUrlParams';
import CesiumProvider from './providers/CesiumProvider';
import ScenarioProvider from './providers/ScenarioProvider';
import ContactsProvider from './providers/ContactsProvider';
import './CommandCenterDashboard.css'
import TimelineProvider from './providers/TimelineProvider';
import ErrorProvider from './providers/ErrorProvider';
import ModalProvider from './providers/ModalProvider';

const CommandCenterDashboard = () => {
  const { scenario_id } = useUrlParams()

  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [messages, setMessages] = useState<any>([]);


  const create_socket = (simulationId, sessionId) => {
    return new WebSocket(`ws://10.11.11.34:21000/ws/test/simulation/websocketstatus?simulationId=${simulationId}&sessionId=${sessionId}`);
  }

  // Websocket connection for simulation status //

  // useEffect(() => {
  //   const token = sessionStorage.getItem("authentication");
  //   if (scenario?.['simulationId'] && token) {
  //     const sessionId = uuidv4();
  //     let ws = create_socket(scenario?.['simulationId'], sessionId)
  //     ws.onopen = () => {
  //       ws.send(JSON.stringify({ type: 'authorization', token: `${token}` }));
  //       console.log('WebSocket Connected');
  //     };
  //     ws.onmessage = (event) => {
  //       const message = JSON.parse(event.data);
  //       // console.log(message);
  //       setMessages((prevMessages) => [...prevMessages, message]);
  //     };

  //     ws.onclose = () => {
  //       console.log('WebSocket Disconnected');
  //     };

  //     ws.onerror = (error) => {
  //       console.error('WebSocket Error:', error);
  //       let ws = create_socket(scenario?.['simulationId'], sessionId)
  //     };

  //     setSocket(ws);

  //     return () => {
  //       ws.close();
  //     };
  //   }
  // }, [scenario]);

  useEffect(() => {
    return () => {
      if (sessionStorage.getItem('modals')) {
        sessionStorage.removeItem('modals')
      }
    };
  }, [])


  return (
    <ErrorProvider>
      <ScenarioProvider>
        <ContactsProvider>
          <TimelineProvider>
            <CesiumProvider>
              <div className='command__center_dashboard'>
                <ModalProvider>
                  <Header />
                  <CommandCenter />
                  <Footer />
                </ModalProvider>
              </div>
            </CesiumProvider>
          </TimelineProvider>
        </ContactsProvider>
      </ScenarioProvider>
    </ErrorProvider>
  )
}

export default CommandCenterDashboard