import { CircularProgress } from '@mui/material'
import { GenerateSvg } from '../../Svgs'
import './GenerateSchedule.scss'
import CustomInput from '../../../../../custom_components/CustomInput'
import CustomSelect from '../../../../../custom_components/CustomSelect'
import { useEffect, useState } from 'react'
import MissionService from '../../../../../../../service/MissionService'
import useUrlParams from '../../../../../hooks/useUrlParams'
import { toast } from 'react-toastify'
import useContacts from '../../../../../hooks/useContacts'

interface GenerateScheduleProps {
    open: boolean;
    close: () => void;
}

type FormData = {
    name: string;
    task_plan: string;
    start_time: string;
    duration: any;
};

const GenerateSchedule = ({ open, close }: GenerateScheduleProps) => {
    const { sat_id, scenario_id } = useUrlParams()
    const { generate_schedule } = useContacts()
    const [taskPlans, setTaskPlans] = useState<any[]>([])
    const [selectedTaskPlan, setSelectedTaskPlan] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState<FormData>({
        name: '',
        task_plan: '',
        start_time: '',
        duration: '',
    })

    const close_form = () => {
        close()
        setFormData({
            name: '',
            task_plan: '',
            start_time: '',
            duration: '',
        })
    }



    const get_all_task_plans = () => {
        MissionService.getAllTaskPlan(sat_id)?.then((response) => {
            if (response?.data && response?.["data"]?.length > 0) {
                let alltaskPlans: any = [];
                response?.["data"]?.forEach(taskPlan => {
                    alltaskPlans.push({
                        label: taskPlan?.["taskPlanName"],
                        value: taskPlan
                    });
                })
                setTaskPlans(alltaskPlans);
            }
        }).catch((err) => {
            console.log("Error in getting taskplans");
        });
    }

    function formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    const calculateMaxTime = (startTime: string): string => {
        const startTimeDate = new Date(startTime);
        startTimeDate.setDate(startTimeDate.getDate() + 7);
        return formatDate(startTimeDate);
    };

    useEffect(() => {
        setFormData({
            ...formData,
            start_time: formatDate(new Date()),
            duration: 360
        })
    }, [])

    useEffect(() => {
        if (sat_id) {
            get_all_task_plans();
        }
    }, [sat_id])


    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                close_form()
            }
        })
        document.addEventListener('click', (e) => { close_form() })

        return () => {
            document.removeEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    close_form()
                }
            })
            document.removeEventListener('click', (e) => { close_form() })
        }
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value !== '' ? e.target.value : '',
        })

    }

    const handleSubmit = () => {
        const { name, task_plan, start_time, duration } = formData;
        if (name === '') {
            toast.error('Schedule Title is required', { toastId: '0' })
        } else if (task_plan === '') {
            toast.error('Task Plan is required', { toastId: '0' })
        } else if (start_time === '') {
            toast.error('Start Time is required', { toastId: '0' })
        } else if (duration === '') {
            toast.error('Duration is required', { toastId: '0' })
        } else if (duration < 10) {
            toast.error('Duration must be greater than 10', { toastId: '0' })
        } else if (duration > 1440) {
            toast.error('Duration must be less than 1440', { toastId: '0' })
        } else {
            const { name, task_plan, start_time, duration } = formData;
            const payload = {
                scenarioId: scenario_id,
                taskPlanId: task_plan,
                startEpoch: new Date(start_time).getTime() / 1000,
                duration: duration,
                scheduleName: name
            }
            setLoading(true)
            generate_schedule(payload).then((result) => {
                toast.success('Schedule generated successfully', { toastId: '0' })
                close_form()
            }).catch((error) => {
                console.error('Schedule generation failed:', error);
                toast.error(error?.response?.data, { toastId: '0' })
            }).finally(() => {
                setLoading(false)
            })
        }
    }



    return (
        <div className={`generate__schedule__container ${open ? 'open' : ''}`}
            onClick={(e) => { e.stopPropagation() }}
        >
            <div className='generate__schedule__container__header'>
                <div className='d-flex gap-2 align-items-center'>
                    <GenerateSvg color='#CCF54E' />
                    <span>Generate Schedule</span>
                </div>
                <div className='d-flex gap-2 align-items-center'>
                    <button className='bg-transparent p-0 border-0' onClick={close_form}>
                        <i className="fe fe-x fs-16" />
                    </button>
                </div>
            </div>
            <div className='generate__schedule__container__body'>
                <div className='badge'><i className="fe fe-info fs-16" /> Satellite details retrieved from TLE.</div>
                <div className='form__container'>
                    <div className='form__details'>
                        <span>Schedule Title</span>
                        <CustomInput
                            placeholder="Enter Schedule Title"
                            name="name"
                            type="text"
                            required={true}
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='form__details'>
                        <span>Task Plan</span>
                        <CustomSelect
                            options={taskPlans}
                            placeholder="Select Task Plan"
                            value={selectedTaskPlan}
                            onChange={(data: any) => {
                                setSelectedTaskPlan(data);
                                setFormData({
                                    ...formData,
                                    task_plan: data?.value?.taskPlanId,
                                })
                            }}
                            placement='bottom'
                            className='task__plan__select'
                        />
                    </div>
                    <div className='form__details'>
                        <span>Start Time & Duration</span>
                        <div className='d-flex align-items-center gap-2 w-100'>
                            <CustomInput
                                className='w-55'
                                name="time"
                                type="datetime-local"
                                required={true}
                                value={formData.start_time}
                                min={formData.start_time}
                                max={calculateMaxTime(formData.start_time)}
                                onChange={((e) => {
                                    setFormData({
                                        ...formData,
                                        start_time: e.target.value,
                                    })
                                })}
                            />
                            <CustomInput
                                className='w-45'
                                placeholder="Enter Duration"
                                name="duration"
                                type="number"
                                required={true}
                                value={formData.duration}
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13 || e.keyCode === 69) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='generate__schedule__container__footer'>
                <button className='close' onClick={close_form}>Close</button>
                <button className='generate' onClick={handleSubmit}>
                    {loading ? 'Generating' : 'Generate'}
                    {loading && <CircularProgress size={12} className=" text-white" />}
                </button>
            </div>
        </div>
    )
}

export default GenerateSchedule