import { useEffect, useState } from "react";
import "./CustomModelReportItemComponent.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ModalDropdown from "../../../../../MissionModelling/commonComponents/ModalDropdown";
import { WhiteCrossIcon, SensorsIcon, CopyIcon } from "../../SatelliteProfileImgAssets/SatelliteProfileImgAssets";
import { panelPublicUrl } from "../../../../../../panelurl";
import MissionService from "../../../../../../service/MissionService";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const CustomModelReportItemComponent = ({ reportProps, isReportBarOpen }) => {

  const params = useParams();
  const { mission_name, sat_name, mission_id, sat_id } = params;

  const [allReports, setAllReports] = useState<any>()

  const formatEpochToDate = (epoch) => {
    const date = new Date(epoch * 1000);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);

    return `${month}.${day}.${year} at ${hours}:${minutes}:${seconds}`;
  };

  const [activeReports, setActiveReports] = useState<number[]>([]);
  const toggleActiveReport = (index: number) => {
    setActiveReports((prevActiveReports) => {
      if (prevActiveReports.includes(index)) {
        return prevActiveReports.filter((id) => id !== index);
      } else {
        return [...prevActiveReports, index];
      }
    });
  };

  const [show_menu, set_show_menu] = useState<string | boolean>(false);

  //   Dropdown Data

  const controlModeOptions = [
    { label: "Sun Pointing", value: "Sun Pointing" },
    { label: "Nadir Pointing", value: "Nadir Pointing" },
  ];
  const [selectedControlMode, setSelectedControlMode] = useState(controlModeOptions[0].label);

  const [controlOptions, setControlOptions] = useState<any>()

  const handleSelectedControlMode = (e) => {
    console.log(e)
  };

  const [isPreviousSelectedControlMode, setIsPreviousSelectedControlMode] = useState("earth ");


  const getAllReports = () => {
    MissionService.getAllReports(sat_id)?.then((res) => {
      if (res) {
        setAllReports(res?.data)
      }
    }).catch(() => {
      toast.error("Something went wrong while fetching the reports", { toastId: 0 })
    })
  }


  useEffect(() => {
    getAllReports()
  }, [isReportBarOpen])


  useEffect(() => {
    if (allReports) {
      let controlOption = {}
      allReports?.map((report) => {
        let graphanaPanelList: any = []
        Object.keys(report.grafanaPanelUrls).map((grafanaPanel) => {
          graphanaPanelList.push({ label: grafanaPanel, value: report.grafanaPanelUrls[grafanaPanel] })
        })
        controlOption[report.name] = graphanaPanelList
      })
      setControlOptions(controlOption)

      console.log("Panel Public Url -> ", panelPublicUrl)
    }
  }, [allReports])

  const deleteReport = (report) => {
    MissionService.deleteReoprt(report?.name, sat_id).then((res) => {
      if (res) {
        getAllReports()
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <>
      <div className="customModelReportItemComponent">
        {/* Header section */}
        <div className="customModelReportHeader">
          <div className="customModelReportHeaderTitle">Reports</div>
          <button className="" onClick={() => { reportProps.setIsReportBarOpen(false) }}>
            <WhiteCrossIcon />
          </button>
        </div>

        {/* Report Items */}
        {allReports && allReports.map((report, index) => (
          <>
            {/* Report Header */}
            <div
              key={index}
              className={activeReports.includes(index) ? "individualReportHeader active" : "individualReportHeader"}
              onClick={() => toggleActiveReport(index)}
              onMouseEnter={() => {
                set_show_menu(true);
              }}
              onMouseLeave={() => {
                set_show_menu(false);
              }}
            >
              <div className="individualReportHeaderItem">
                <div className="reportTitle">{report?.name}</div>
                {/* <div className="reportDate">{report.date}</div> */}
              </div>
              <div onClick={() => {
                deleteReport(report)
              }}>
                <i className="fa fa-trash "></i>
              </div>
            </div >
            {/* End of Report Header */}

            {
              activeReports.includes(index) && (
                <>
                  <div className="individualReportBody">
                    {/* Inputs box of report */}
                    <div className="individualReportBodyItem">
                      <div className="">Inputs</div>
                      <div className="individualReportBodyInputBox">
                        <div><SensorsIcon /></div>
                        <div> {report?.sensorsConfigurations?.length}</div>
                      </div>
                    </div>
                    {/* Outputs box of report */}
                    <div className="individualReportBodyItem">
                      <div className="">Outputs</div>
                      {controlOptions && <ModalDropdown
                        options={controlOptions[report?.name]}
                        isMulti={false}
                        handleSelectedOption={handleSelectedControlMode}
                        previousOption={controlOptions[report?.name][0]['label']}
                      />}
                    </div>
                    <div className="individualReportBodyItem">
                      <div className="reportBodyItemText">Sun Sensor</div>
                      <div className="reportBodyItemText">Sun</div>
                    </div>
                    <div style={{ width: '100%', height: '400px' }}>
                      <iframe src={`${panelPublicUrl}${controlOptions[report?.name][0]['value']}`} className='panel__frame__control__mode' />
                    </div>
                  </div>
                </>
              )
            }
          </>
        ))}
      </div >
    </>
  );
};

export default CustomModelReportItemComponent;
